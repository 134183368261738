export const createFiltersSlice = (set) => ({
  filters: {},
  setFilters: ({ pagePath, filters }) =>
    set((state) => {
      state.filters[pagePath] = filters;
    }),
  resetFilters: ({ pagePath }) =>
    set((state) => {
      state.filters[pagePath] = Object.keys(state.filters[pagePath]).reduce((acc, key) => {
        acc[key] = undefined;
        return acc;
      }, {});
    }),
});
