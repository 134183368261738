import { createConversationPrivateStatusUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function useCreateConversationStatusMutation({ mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ conversationId, requestBody, requestConfig }) =>
      HTTP.post(createConversationPrivateStatusUrl, requestBody, {
        urlParams: { conversationId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    createConversationStatusAsync: mutateAsync,
    createConversationStatus: mutate,
    isCreatingConversationStatusLoading: isLoading,
  };
}
