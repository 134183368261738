import { patchMeUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';
import { queryClient } from '../queryClient';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useMeMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.patch(patchMeUrl, requestBody, requestConfig),
    ...mutationOptions,
    onSuccess: (...args) => {
      mutationOptions?.onSuccess?.(...args);
      queryClient.invalidateQueries([QueryKey.USER_ME]);
    },
  });

  return {
    patchMe: mutate,
    isPatchingMe: isLoading,
  };
}
