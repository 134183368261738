import { getUsersUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useUsersQuery({ queryOptions, params } = {}) {
  const { data, isFetching, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.USERS, params],
    queryFn: () =>
      HTTP.get(getUsersUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    users: data?.results,
    isFetchingUsers: isFetching,
    isUsersLoading: isLoading,
  };
}
