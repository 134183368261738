import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';

const useRemoveProductFromCollection = ({
  deleteProductFromCollectionAsync,
  mappedProductOffers,
  selectedRowKeys,
}) => {
  const onDeleteSelectedItems = ({ mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const selectedRows = mappedProductOffers.filter((row) => selectedRowKeys.includes(row.id));
    const promises = selectedRows.map((row) => {
      return () =>
        deleteProductFromCollectionAsync({
          id: row.collectionItem.id,
        })
          .then(() => {
            onSuccess();
          })
          .catch(() => {
            onError();
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
    });
  };
  return { onDeleteSelectedItems };
};

export default useRemoveProductFromCollection;
