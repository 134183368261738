import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

const CurrentAttributes = ({ attributes, currentAttributes, record }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { images, revisionImages, hasImageChange } = record;
  const shouldShowImages = revisionImages?.length > 0 || images?.length > 0;
  const requestedName = record.name;
  const currentName = record.product_detail.name;

  return (
    <div className="old-offer-attributes">
      {requestedName !== currentName && (
        <div className="offer-attribute">
          <div className="offer-attribute-name">{t('product_name')}:</div>{' '}
          <div className="offer-attribute-value">{currentName ?? '-'}</div>
        </div>
      )}
      {shouldShowImages && hasImageChange && (
        <div className="revision-images-container">
          <div className="offer-attribute-name">
            {t('product_images')}
            {!images && ': -'}
          </div>
          {images && (
            <div className="revision-images-container__all-images">
              <Image.PreviewGroup>
                {images.map((item) => (
                  <Image width="calc(25% - 0.8rem)" key={item.id} src={item.image} />
                ))}
              </Image.PreviewGroup>
            </div>
          )}
        </div>
      )}
      {Object.entries(currentAttributes ?? {}).map(([key, value]) => {
        const attributeName = attributes.find((attribute) => attribute.key === key)?.name ?? key;
        return (
          <div className="offer-attribute" key={key}>
            <div className="offer-attribute-name">{attributeName}:</div>
            <div className="offer-attribute-value">{value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CurrentAttributes;
