import AkinonButton from '@components/AkinonButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import { WidgetType } from '@constants/commontypes';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';

const ProductDataIndex = {
  IMAGE: 'image',
  SKU: 'sku',
  NAME: 'name',
};

const StaticFilterKey = {
  SKU: 'sku__icontains',
  PRODUCT_NAME: 'name__icontains',
};

export const getStaticFilters = ({ t }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.PRODUCT_NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
    ],
  };
};

export const getProductColumns = ({
  t,
  isMobile,
  datasourceId,
  restrictProduct,
  refetchAllProducts,
}) => [
  {
    dataIndex: ProductDataIndex.IMAGE,
    title: <ImageTableHead />,
    render: (image) => <SmallColumnImage src={image} />,
    align: 'center',
    width: 100,
  },
  {
    dataIndex: ProductDataIndex.NAME,
    title: t('name'),
  },
  {
    dataIndex: ProductDataIndex.SKU,
    title: t('sku'),
  },
  {
    dataIndex: 'add_button',
    title: ' ',
    fixed: isMobile ? false : 'right',
    width: 50,
    className: 'operation-column',
    render: (_, record) => {
      return (
        <div className="flex justify-center">
          <AkinonButton
            type="text"
            onClick={() => {
              restrictProduct(
                { product: record.id, datasource: datasourceId },
                {
                  onSuccess: refetchAllProducts,
                }
              );
            }}
            style={{
              height: 'auto',
              padding: 0,
            }}
          >
            <IconCirclePlus className="operation-icon add-icon" />
          </AkinonButton>
        </div>
      );
    },
  },
];

export const getRestrictedProductsColumns = ({
  t,
  isMobile,
  deleteProductRestriction,
  refetchAllProducts,
}) => [
  {
    dataIndex: ProductDataIndex.IMAGE,
    title: <ImageTableHead />,
    render: (image) => <SmallColumnImage src={image} />,
    align: 'center',
    width: 100,
  },
  {
    dataIndex: ProductDataIndex.NAME,
    title: t('name'),
  },
  {
    dataIndex: ProductDataIndex.SKU,
    title: t('sku'),
  },
  {
    dataIndex: 'operation',
    title: ' ',
    fixed: isMobile ? false : 'right',
    className: 'operation-column',
    render: (_, record) => {
      return (
        <div className="flex justify-center">
          <AkinonButton
            type="text"
            onClick={() => {
              deleteProductRestriction(
                { restrictionId: record.id },
                {
                  onSuccess: refetchAllProducts,
                }
              );
            }}
            style={{
              height: 'auto',
              padding: 0,
            }}
          >
            <IconCircleMinus className="operation-icon delete-icon" />
          </AkinonButton>
        </div>
      );
    },
  },
];

export const getProductsTableActions = ({ t, onShowRestrictProductsModal }) => {
  return [
    {
      label: t('add_restriction'),
      onTableAction: () => onShowRestrictProductsModal(),
      isSelectionRequired: true,
    },
  ];
};

export const getRestrictedProductsTableActions = ({ t, onShowDeleteRestrictionModal }) => {
  return [
    {
      label: t('remove_restriction'),
      onTableAction: () => onShowDeleteRestrictionModal(),
      isSelectionRequired: true,
    },
  ];
};

export const ProductPermissionsTableName = {
  AVAILABLE_PRODUCTS: 'availableProducts',
  RESTRICTED_PRODUCTS: 'restrictedProducts',
};
