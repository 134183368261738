import { postPreOfferImageUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostPreOfferImagesMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBodyList }) =>
      Promise.all(
        requestBodyList.map((requestBody) => HTTP.post(postPreOfferImageUrl, requestBody))
      ),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.PRE_OFFER_IMAGES]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    postPreOfferImages: mutate,
    isPostingPreOfferImages: isLoading,
  };
}
