import { postBlacklistTokenUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';
import { Auth } from '@services/client/auth-adapter';

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} mutationOptions
 */
export function useBlacklistRefreshTokenMutation(mutationOptions) {
  const mutationResponse = useAkinonMutation({
    mutationFn: ({ requestConfig }) =>
      HTTP.post(
        postBlacklistTokenUrl,
        {
          refresh: Auth.refreshToken,
        },
        requestConfig
      ),
    ...mutationOptions,
  });

  return {
    ...mutationResponse,
    blacklistRefreshToken: mutationResponse.mutate,
  };
}
