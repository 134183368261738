import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useOptionsQuery({ url, queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: ['options', url],
    queryFn: () => HTTP.options(url),
    ...queryOptions,
  });

  return {
    options: data,
    isFetchingOptions: isFetching,
  };
}
