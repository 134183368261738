import { getProductImageRevisionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

/**
 * @param {Object} [args]
 * @param {Object} [args.params]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function useProductRevisionsImagesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isFetching, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_REVISIONS, params],
    async queryFn() {
      const response = await HTTP.get(getProductImageRevisionsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productRevisionImages: data?.results,
    totalProductRevisionImages: data?.count,
    isFetchingProductRevisionImages: isFetching,
    isLoadingProductRevisionImages: isLoading,
  };
}
