import { getRequestFiltersParams } from '@common/index';
import { postAddAllProductsURL } from '@constants/apiUrls';
import { omit } from 'lodash';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useAddAllProductsToCollectionMutation({
  filters,
  params = {},
  mutationOptions,
} = {}) {
  const { mutate, mutateAsync, isLoading, data, error, isSuccess, reset } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig } = {}) => {
      const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
      const formData = new FormData();
      fileFilter &&
        formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
      formData.append('collection_id', requestBody.collection_id);

      if (fileFilter) {
        return HTTP.post(postAddAllProductsURL, formData, {
          params: {
            ...getRequestFiltersParams(omit(filters, 'filter_file')),
            ...params,
          },
          ...{
            ...requestConfig,
          },
        });
      } else {
        return HTTP.post(postAddAllProductsURL, requestBody, {
          params: {
            ...getRequestFiltersParams(omit(filters, 'filter_file')),
            ...params,
          },
          ...requestConfig,
        });
      }
    },
    ...mutationOptions,
  });

  return {
    addAllProductsToCollection: mutate,
    addAllProductsToCollectionAsync: mutateAsync,
    isAddingAllProductsToCollection: isLoading,
    isAddingAllProductsToCollectionError: error,
    isAddingAllProductsToCollectionSuccess: isSuccess,
    addAllProductsToCollectionCacheKey: data?.key,
    resetAddAllProductsToCollection: reset,
  };
}
