import { getBasketOfferDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useBasketOfferDetailQuery({ id, queryOptions, onSuccess } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.BASKET_OFFER, id],
    async queryFn() {
      const response = await HTTP.get(getBasketOfferDetailUrl, { urlParams: { id } });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    basketOfferDetail: data,
    isBasketOfferDetailLoading: isLoading,
  };
}
