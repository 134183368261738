import { postPreOfferFileUploadURL } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useBulkImportPreOfferMutation({ mutationOptions } = {}) {
  const { isError, isSuccess, isLoading, data, mutate, reset } = useAkinonMutation({
    mutationFn: ({ formData, onProgress }) =>
      HTTP.post(postPreOfferFileUploadURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => onProgress?.(progressEvent),
      }),
    ...mutationOptions,
  });

  return {
    bulkImportPreOffers: mutate,
    resetBulkImportPreOffers: reset,
    isBulkImportingPreOffers: isLoading,
    isBulkImportPreOffersSuccess: isSuccess,
    isBulkImportPreOffersError: isError,
    bulkImportPreOffersData: data,
  };
}
