import { approveBulkRefundUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function useApproveBulkRefundMutation({ mutationOptions = {} }) {
  const { mutate: approveBulkRefund, isLoading: isApproveBulkRefundMutating } = useAkinonMutation({
    mutationFn: ({ requestBody }) => HTTP.post(approveBulkRefundUrl, requestBody),
    ...mutationOptions,
  });

  return {
    approveBulkRefund,
    isApproveBulkRefundMutating,
  };
}
