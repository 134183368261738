import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getPriceListsUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const usePaginatedPriceLists = ({ pagination, filters }) => {
  const queryKey = ['price-lists', filters, pagination];
  const queryFn = () =>
    HTTP.get(getPriceListsUrl, {
      params: {
        ...getRequestPaginationParams(pagination),
        ...getRequestFiltersParams(filters),
        is_master: true,
      },
    });

  const { data, isLoading, error } = useQuery({ queryKey, queryFn });

  return {
    data: data?.results,
    total: data?.count,
    isLoading,
    error,
  };
};
