import { getAgreementDetailUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePatchAgreementMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.patch(getAgreementDetailUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    updateAgreement: mutate,
    isAgreementUpdating: isLoading,
  };
}
