import '../style.scss';

import { getRequestFiltersParams } from '@common/index';
import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { preOffersUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension, limitQuery } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { getExportPreOffers } from '@services/api';
import { useApproveAllPreOffers } from '@services/api/hooks/useApproveAllPreOffers';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useCreatePreOfferBulkRejectMutation } from '@services/api/hooks/useCreatePreOfferBulkRejectMutation';
import { useGetPreOffersBulkStateStatusQuery } from '@services/api/hooks/useGetPreOffersBulkStateStatusQuery';
import { useAllDataSources } from '@services/hooks/datasources/useAllDataSources';
import { useOfferRejectReasonsQuery } from '@services/hooks/offer/useOfferRejectReasonsQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import { useUserRole } from '@utils/hooks/useUserRole';
import usePageData from '@zustand-store/hooks/usePageData';
import { Modal } from 'antd';
import { first, get, omit } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApprovalStatus, getPendingOffersApprovalStatus } from '../common';
import { useSubmitAllPreOffersMutation } from '../hooks/api/useSubmitAllPreOffersMutation';
import { useOffersByStatus } from '../hooks/useOffersByStatus';
import { usePendingOffersBreadcrumbs } from '../hooks/usePendingOffersBreadcrumbs';
import { usePendingOffersPageMeta } from '../hooks/usePendingOffersPageMeta';
import ApproveAndChangeSKUModalContent from '../OfferDetail/components/ApproveAndChangeSKUModalContent';
import { DataIndex, getColumns, getTableActions } from './common';
import { BulkActionProgressModal } from './components/BulkActionProgressModal';
import BulkRejectModal from './components/BulkRejectModal';
import useApproveOffers from './hooks/useApproveOffers';
import useFilterFields from './hooks/useFilterFields';
import useRejectOffers from './hooks/useRejectOffers';
import useSubmitOffers from './hooks/useSubmitOffers';

export default function OfferListPending() {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const userRole = useUserRole();
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData();
  const { attributes, isAttributesLoading } = useAttributesQuery();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const filterFields = useFilterFields({
    t,
    attributes,
    categoryTreeData,
    isCategoriesLoading,
    isSuperUser,
    isAttributesLoading,
  });

  const filters = useFilterParams({
    filterFields,
  });

  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];
  const [pagination, setPagination] = usePagination();

  const { selectedRowKeys, setSelectedRowKeys, getRowSelection } = useSelectedKeys();
  const [isBulkRejectModalVisible, setIsBulkRejectModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const { submitAllPreOffers, isSubmitPreOffersLoading } = useSubmitAllPreOffersMutation({
    mutationOptions: {
      onSuccess: () => {
        const approvalStatus = getPendingOffersApprovalStatus({ isSuperUser, filters });
        queryClient.invalidateQueries([QueryKey.PRE_OFFERS, approvalStatus, pagination, filters]);
        openNotification({
          message: t('transaction_success'),
          description: t('offers_all_submit_success'),
        });
      },
      onError: () => {
        openNotification({
          message: t('transaction_failed'),
          description: t('offers_all_submit_error'),
          type: 'error',
        });
      },
    },
  });
  const { createPreOfferBulkReject } = useCreatePreOfferBulkRejectMutation({
    filters,
    mutationOptions: {
      onSuccess: () => {
        const approvalStatus = getPendingOffersApprovalStatus({ isSuperUser, filters });
        queryClient.invalidateQueries([QueryKey.PRE_OFFERS, approvalStatus, pagination, filters]);
        openNotification({
          message: t('transaction_success'),
          description: t('offers_all_reject_success'),
        });
      },
      onError: () => {
        openNotification({
          message: t('transaction_failed'),
          description: t('offers_all_reject_error'),
          type: 'error',
        });
      },
    },
  });

  const { data: rejectionReasons, isLoading: isRejectReasonsLoading } = useOfferRejectReasonsQuery({
    params: {
      ...limitQuery,
      is_active: true,
    },
  });
  const { data: dataSources } = useAllDataSources({ filters });

  usePendingOffersBreadcrumbs();
  usePendingOffersPageMeta({ submitAllPreOffers, dataSources, userRole });
  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const {
    data: pendingOffers,
    isLoading: isPendingOffersLoading,
    total: totalPendingOffers,
    refetch: refetchPendingOffers,
  } = useOffersByStatus({
    pagination,
    filters,
    params: {
      approvalStatus: getPendingOffersApprovalStatus({ isSuperUser, filters }),
    },
    dynamicFormMetaFields,
  });
  const { rejectSelectedItems } = useRejectOffers({
    selectedRowKeys,
    pendingOffers,
    t,
    refetchOffers: () => {
      refetchPendingOffers();
      setSelectedRowKeys([]);
    },
  });

  const { approveSelectedItems } = useApproveOffers({
    selectedRowKeys,
    pendingOffers,
    t,
    refetchOffers: () => {
      refetchPendingOffers();
      setSelectedRowKeys([]);
    },
  });
  const { submitSelectedItems } = useSubmitOffers({
    selectedRowKeys,
    refetchOffers: () => {
      refetchPendingOffers();
      setSelectedRowKeys([]);
    },
  });

  const {
    approveAllPreOffers,
    resetApproveAllPreOffers,
    approveAllPreOffersCacheKey,
    isApprovingAllPreOffersSuccess,
    isApprovingAllPreOffersError,
  } = useApproveAllPreOffers({
    filters,
    params: {
      id__in: selectedRowKeys.join(','),
    },
  });

  const {
    allStatus: approveAllPreOffersStatus,
    allStatusPercentage: approveAllPreOffersPercentage,
  } = useGetPreOffersBulkStateStatusQuery({
    cacheKey: approveAllPreOffersCacheKey,
  });

  const { onShowModal: onShowApproveOffersModal, ...bulkApproveOffersProps } = useBulkAction({
    bulkActionMutate: approveSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
  });
  const { onShowModal: onShowRejectOffersModal, ...bulkRejectOffersProps } = useBulkAction({
    bulkActionMutate: rejectSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
  });
  const { onShowModal: onShowSubmitOffersModal, ...bulkSubmitOffersProps } = useBulkAction({
    bulkActionMutate: submitSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
  });

  const columns = getColumns({
    t,
    isSuperUser,
    setSelectedRowKeys,
    onShowApproveOffersModal,
    onShowRejectOffersModal,
    isMobile,
  });

  const onShowBulkRejectModal = () => {
    setIsBulkRejectModalVisible(true);
  };

  const onShowApproveBulkAllOffersModal = () => {
    const title = t('approve_all_offer_items');
    Modal.confirm({
      title,
      onOk() {
        approveAllPreOffers();
      },
    });
  };

  const onShowApproveBulkOffersModal = () => {
    const title = t('approve_selected_offer_items');
    Modal.confirm({
      title,
      onOk() {
        approveAllPreOffers();
      },
    });
  };

  const tableActions = getTableActions({
    t,
    selectedRowKeys,
    isSuperUser,
    onShowApproveBulkAllOffersModal,
    onShowApproveBulkOffersModal,
    onShowRejectOffersModal,
    onShowSubmitOffersModal,
    onShowBulkRejectModal,
  });

  const onExport = async ({ exportFormat, fieldsToExport }) => {
    if (exportFormat === ExportFormat.XLSX) {
      const approvalStatusValue = getPendingOffersApprovalStatus({ isSuperUser, filters });
      const approvalStatusKey = approvalStatusValue?.includes?.(',')
        ? 'approval_status__in'
        : 'approval_status';
      const approvalStatusFilter = {
        [approvalStatusKey]: approvalStatusValue,
      };
      let requestConfig = {
        params: {
          ...QueryParamsBuilder.new()
            .set('extension', FileExtension.XLSX)
            .getByFields(fieldsToExport)
            .build(),
          ...approvalStatusFilter,
          is_active: true,
        },
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRE_OFFERS, requestConfig, filters],
        queryFn: async () => {
          const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
          const filterParams = omit(getRequestFiltersParams(filters), [
            'approval_status',
            'approval_status__in',
          ]);
          delete filterParams?.filter_file;
          requestConfig = {
            ...requestConfig,
            params: {
              ...filterParams,
              ...requestConfig?.params,
            },
          };

          const response = await getExportPreOffers({ requestConfig, fileFilter });
          return response;
        },
      });
      openNotification({
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
      });
    }
  };

  const onRowClick = (record) => () => {
    navigate(RouteUrls.productsAndCategories.offerList.detail, {
      offerId: record.id,
    });
  };

  const selectedRows = pendingOffers?.filter?.((offer) => selectedRowKeys.includes(offer.id));

  const hasRelatedProductSKU = get(first(selectedRows), 'product.sku');

  const rowSelection = getRowSelection({
    mode: 'checkbox',
    getCheckboxProps: (record) => ({
      disabled: isSuperUser
        ? record.approval_status === ApprovalStatus.WAITING_FOR_APPROVAL
        : record.approval_status === ApprovalStatus.SUBMITTED,
    }),
  });

  return (
    <section className="offer-list">
      <AkinonFilter
        title={t('filter')}
        filterFields={filterFields}
        total={totalPendingOffers}
        showFileImport
      />
      <div className="offer-list__table">
        <AkinonTable
          title={t('offer.list')}
          columns={columns}
          appendDynamicColumnsAt={-2}
          enableDynamicColumns
          dataSource={pendingOffers}
          optionsUrl={preOffersUrl}
          loading={isPendingOffersLoading || isRejectReasonsLoading || isSubmitPreOffersLoading}
          total={totalPendingOffers}
          rowKey={defaultRowKey}
          pagination={pagination}
          setPagination={setPagination}
          description={`${totalPendingOffers} ${t('results.found')}`}
          rowSelection={rowSelection}
          tableActions={tableActions}
          exportFormats={[ExportFormat.XLSX]}
          onExport={onExport}
          onRow={(record) => ({
            onClick: onRowClick(record),
          })}
          scroll={{ x: 2700 }}
        />
      </div>
      <BulkActionModal
        {...bulkApproveOffersProps}
        modalProps={{
          ...(selectedRowKeys.length <= 1 && !hasRelatedProductSKU && { footer: null }),
          title: t('bulk_approve_offer'),
          description:
            !hasRelatedProductSKU && selectedRowKeys.length <= 1 ? (
              <ApproveAndChangeSKUModalContent
                onApprove={(values) =>
                  bulkApproveOffersProps.onStartBulkAction({ requestBody: values })
                }
                initialSKU={first(selectedRows)?.[DataIndex.SKU]}
                onClose={bulkApproveOffersProps.onCloseModal}
              />
            ) : (
              t('bulk_approve_offer_desc', {
                selectedOffers: selectedRowKeys.length > 0 ? selectedRowKeys.length : 1,
              })
            ),
        }}
        progressModalProps={{ title: t('bulk_approve_offer') }}
      />

      <BulkActionProgressModal
        title={t('approve_all_offer_items')}
        cacheKey={approveAllPreOffersCacheKey}
        open={isApprovingAllPreOffersSuccess && !isApprovingAllPreOffersError}
        onCancel={() => {
          resetApproveAllPreOffers();
        }}
        progressStatus={approveAllPreOffersStatus}
        progressPercentage={approveAllPreOffersPercentage}
        succeededCountTransKey="approve_pre_offers_succeeded_count"
        failedCountTransKey="approve_pre_offers_failed_count"
      />

      <BulkActionProgressModal
        title={t('approve_selected_offer_items')}
        cacheKey={approveAllPreOffersCacheKey}
        open={isApprovingAllPreOffersSuccess && !isApprovingAllPreOffersError}
        onCancel={() => {
          resetApproveAllPreOffers();
        }}
        progressStatus={approveAllPreOffersStatus}
        progressPercentage={approveAllPreOffersPercentage}
        succeededCountTransKey="approve_pre_offers_succeeded_count"
        failedCountTransKey="approve_pre_offers_failed_count"
      />

      <BulkActionModal
        {...bulkRejectOffersProps}
        CustomModal={BulkRejectModal}
        modalProps={{
          title: t('bulk_reject_offer'),
          description: t('bulk_reject_offer_desc', {
            selectedOffers: selectedRowKeys.length > 0 ? selectedRowKeys.length : 1,
          }),
          rejectionReasons,
          rejectionDescription: t('bulk_reject_offer_desc', {
            selectedOffers: selectedRowKeys.length > 0 ? selectedRowKeys.length : null,
          }),
        }}
        progressModalProps={{ title: t('bulk_reject_offer') }}
      />
      <BulkActionModal
        {...bulkSubmitOffersProps}
        modalProps={{
          title: t('bulk_submit_offer'),
          description: t('bulk_submit_offer_desc', { selectedOffers: selectedRowKeys.length }),
        }}
        progressModalProps={{
          title: t('bulk_submit_offer'),
        }}
      />
      <BulkRejectModal
        open={isBulkRejectModalVisible}
        onCancel={() => setIsBulkRejectModalVisible(false)}
        rejectionReasons={rejectionReasons}
        rejectionDescription={t('bulk_reject_offer_desc', { selectedOffers: totalPendingOffers })}
        onStartBulkAction={(values) => {
          createPreOfferBulkReject({
            requestBody: {
              reason: values?.rejection_reason,
              explanation: values?.rejection_explanation,
            },
          });
          setIsBulkRejectModalVisible(false);
        }}
      />
    </section>
  );
}
