import { orderCancelStatuses } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import { cancellationTypeFormatter, dateFormatter } from '@utils/index';
import { Tag, Tooltip } from 'antd';
import { generatePath } from 'react-router-dom';

import { renderAsLink } from '../common';

export const StaticFilterKey = {
  ORDER: 'order_item__order__number',
  DATASOURCE: 'datasource',
  SKU: 'order_item__product_offer__sku',
  ORDER_DATE_PLACED_GTE: 'order_item__order__date_placed__gte',
  ORDER_DATE_PLACED_LTE: 'order_item__order__date_placed__lte',
  ORDER_CANCELLATION_PLAN_STATUS: 'order_item__order__cancellationplan__status',
  BILLING_ADDRESS_FIRST_NAME: 'order_item__order__billing_address__first_name',
  BILLING_ADDRESS_LAST_NAME: 'order_item__order__billing_address__last_name',
  BILLING_ADDRESS_EMAIL: 'order_item__order__billing_address__email',
};

export const DataIndex = {
  ORDER: 'order',
  PRODUCT_OFFER_SKU: ['productOffer', 'sku'],
  PRODUCT_BASE_CODE: ['product', 'base_code'],
  DATASOURCE_OBJ_NAME: ['datasource', 'name'],
  CREATED_AT: 'created_at',
  STATUS: 'status',
  CANCELLATION_TYPE: 'cancellation_type',
  ORDER_REFUND_AMOUNT: ['order', 'refund_amount'],
  ORDER_SHIPPING_REFUND_AMOUNT: ['order', 'shipping_refund_amount'],
  ORDER_INVOICE_NUMBER: ['order', 'invoice_number'],
  RETURN_CODE: 'return_code',
  REASON: 'reason',
};

export const getDataTableColumns = ({ t, isSuperUser }) => {
  return [
    isSuperUser && {
      dataIndex: DataIndex.DATASOURCE_OBJ_NAME,
      title: t('seller'),
      className: 'col-type-product-name',
      render: (seller) => <Tooltip title={seller}>{seller}</Tooltip>,
    },
    {
      dataIndex: DataIndex.ORDER,
      title: t('order.number'),
      render: (_, record) => {
        if (record?.order) {
          return renderAsLink(
            record.order?.number,
            generatePath(RouteUrls.order.orderDetail, {
              orderId: record.order?.id,
            })
          );
        }
        return null;
      },
    },
    {
      dataIndex: DataIndex.REASON,
      title: t('cancellation_reason'),
      render: (reason) => <Tooltip title={reason?.subject}>{reason?.subject}</Tooltip>,
    },
    {
      dataIndex: DataIndex.STATUS,
      title: t('status'),
      render: (cellData) => {
        const statuses = orderCancelStatuses({ t });
        const status = statuses.find((item) => item.value.toString() === cellData.toString());
        const statusLabel = status?.label ?? cellData;
        const statusColor = status?.color ?? 'black';

        return (
          <Tooltip title={statusLabel}>
            <Tag color={statusColor}>{statusLabel}</Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.CANCELLATION_TYPE,
      title: t('cancellation_type'),
      render: (cancellation_type) => cancellationTypeFormatter({ t, cancellation_type }),
    },
    {
      dataIndex: DataIndex.ORDER_REFUND_AMOUNT,
      title: t('refund_amount'),
    },
    {
      dataIndex: DataIndex.ORDER_SHIPPING_REFUND_AMOUNT,
      title: t('shipping_refund_amount'),
    },
    {
      dataIndex: DataIndex.PRODUCT_OFFER_SKU,
      title: t('sku'),
    },
    {
      dataIndex: DataIndex.ORDER_INVOICE_NUMBER,
      title: t('invoice_number'),
    },
    {
      dataIndex: DataIndex.RETURN_CODE,
      title: t('return_code'),
    },
    {
      dataIndex: DataIndex.CREATED_AT,
      title: t('creation.date'),
      render: dateFormatter,
    },
  ]
    .filter(Boolean)
    .map((item) => ({ ...item, key: item.dataIndex }));
};
