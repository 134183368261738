import { getDistrictUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useDistrictQuery({ queryOptions, districtId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.DISTRICT, districtId],
    queryFn: () => HTTP.get(getDistrictUrl, { urlParams: { districtId } }),
    ...queryOptions,
  });

  return {
    district: data,
    isDistrictLoading: isLoading,
  };
}
