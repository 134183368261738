import { postDataSourceLandingPageUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

export function usePostDataSourceLandingPageMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => HTTP.post(postDataSourceLandingPageUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postDataSourceLandingPage: mutate,
    isPostingDataSourceLandingPage: isLoading,
  };
}
