import { postTicketMessageUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostTicketMessageMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig = {} }) =>
      HTTP.post(postTicketMessageUrl, requestBody, requestConfig),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.TICKET_MESSAGES]);
    },
    ...mutationOptions,
  });

  return {
    createTicketMessage: mutate,
    isCreatingTicketMessage: isLoading,
  };
}
