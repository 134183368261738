import If from '@components/If';
import { oAuthProviderAuthorizeUrl } from '@constants/apiUrls';
import { getApiUrl } from '@services/client/constants';
import { Col, Row, Skeleton, Tooltip } from 'antd';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React from 'react';
import { generatePath } from 'react-router-dom';

function SsoProviders({ isFetchingProviders, providers }) {
  return (
    <If
      condition={!isFetchingProviders}
      otherwise={
        <Row gutter={3}>
          <Col span={12}>
            <Skeleton.Button
              loading
              shape="square"
              active
              block
              style={{
                height: '40px',
              }}
            />
          </Col>
          <Col span={12}>
            <Skeleton.Button
              loading
              shape="square"
              active
              block
              style={{
                height: '40px',
              }}
            />
          </Col>
        </Row>
      }
      then={
        <If
          condition={providers?.length > 0}
          otherwise={null}
          then={
            <Row gutter={[3, 3]} wrap className="mt-5" justify="space-between">
              {providers?.map((provider) => (
                <Col
                  span={11}
                  key={provider.name}
                  className="flex items-center w-full bg-zircon pt-2 pb-2 rounded-sm"
                >
                  <img src={provider.icon} alt={provider.name} className="w-4 h-4 mr-2 ml-1" />
                  <a
                    className="text-blue-whale"
                    href={`${getApiUrl(
                      generatePath(oAuthProviderAuthorizeUrl, { providerSlug: provider.slug })
                    )}?v=${Date.now()}`}
                  >
                    <Tooltip title={provider.name}>
                      {truncate(provider.name, { length: 20 })}
                    </Tooltip>
                  </a>
                </Col>
              ))}
            </Row>
          }
        />
      }
    />
  );
}

SsoProviders.propTypes = {
  isFetchingProviders: PropTypes.bool,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
      slug: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default SsoProviders;
