import { postDataSourceUpdateUserUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export const useDataSourceUpdateUserMutation = ({ mutationOptions } = {}) => {
  const { mutate, isLoading, error } = useAkinonMutation({
    mutationFn: ({ dataSourceId, userId, params, requestConfig }) =>
      HTTP.patch(postDataSourceUpdateUserUrl, params, {
        urlParams: { dataSourceId, userId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    updateUser: mutate,
    isUpdatingUser: isLoading,
    updateUserError: error,
  };
};
