import { dateFormatter } from '@utils/index';
import { Tag, Tooltip } from 'antd';
import React from 'react';

const CampaignStatus = {
  ACTIVE: 'active',
  PASSIVE: 'passive',
};

export const StaticFilterKey = {
  NAME: 'label',
  STATUS: 'status',
  FIRST_CREATED_DATE: 'start_datetime__gte',
  LAST_CREATED_DATE: 'start_datetime__lte',
  FIRST_EXPIRATION_DATE: 'end_datetime__gte',
  LAST_EXPIRATION_DATE: 'end_datetime__lte',
};

export const DataIndex = {
  NAME: 'promotion',
  BEGINNING_DATE: 'beginning_date',
  EXPIRATION_DATE: 'expiration_date',
  CREATED_DATE: 'created_at',
  STATUS: 'status',
};

const getStatusRenderMap = ({ t }) => {
  return {
    [CampaignStatus.ACTIVE]: {
      color: 'green',
      label: t('active'),
    },
    [CampaignStatus.PASSIVE]: {
      color: 'red',
      label: t('passive'),
    },
  };
};

export const getColumns = ({ t }) => [
  {
    dataIndex: DataIndex.NAME,
    visibleOnFilter: [StaticFilterKey.NAME],
    title: t('name'),
    render: (promotion) => {
      return <Tooltip title={promotion.name}>{promotion.name}</Tooltip>;
    },
  },
  {
    dataIndex: DataIndex.BEGINNING_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_CREATED_DATE, StaticFilterKey.LAST_CREATED_DATE],
    title: t('start_date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.EXPIRATION_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_EXPIRATION_DATE, StaticFilterKey.LAST_EXPIRATION_DATE],
    title: t('end_date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.CREATED_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_CREATED_DATE, StaticFilterKey.LAST_CREATED_DATE],
    title: t('created_at'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.STATUS,
    visibleOnFilter: [StaticFilterKey.STATUS],
    title: t('status'),
    render: (cellData) => {
      const { color, label } = getStatusRenderMap({ t })[cellData] ?? {};
      return <Tag color={color}>{label}</Tag>;
    },
  },
];
