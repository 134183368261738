import { Modal } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DeliveryFailedOnShippingModal = ({ onConfirm, isVisible, setIsVisible }) => {
  const { t } = useTranslation('Orders');

  return (
    <Modal
      destroyOnClose
      className="package-detail__action-form box-primary form-box"
      open={isVisible}
      onCancel={() => {
        setIsVisible(false);
      }}
      onOk={onConfirm}
      centered
      title={t('delivery_failed')}
    >
      <Trans i18nKey={t('delivery_failed_on_shipping_desc')} />
    </Modal>
  );
};

export default DeliveryFailedOnShippingModal;
