import { getDeleteProductCollectionURL } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useDeleteProductFromCollectionMutation({ mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ id, requestConfig }) =>
      HTTP.delete(getDeleteProductCollectionURL, {
        ...requestConfig,
        urlParams: {
          id,
        },
      }),
    ...mutationOptions,
  });

  return {
    deleteProductFromCollection: mutate,
    deleteProductFromCollectionAsync: mutateAsync,
    isDeletingProductFromCollection: isLoading,
  };
}
