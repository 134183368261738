import { getProductRevisionsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useProductRevisionsQuery({ params, queryOptions, onSuccess } = {}) {
  const { data, isLoading, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_REVISIONS, params],
    async queryFn() {
      const response = await HTTP.get(getProductRevisionsUrl, {
        params,
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productRevisions: data?.results,
    isProductRevisionsLoading: isLoading,
    isFetchingProductRevisions: isFetching,
    refetchProductRevisions: refetch,
  };
}
