import './style.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonDividerBox from '@components/AkinonDividerBox';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@root/contexts/hooks/useUser';
import { useDataSourceDetailQuery } from '@services/api/hooks';
import { useDeleteReconciliationRulesMutation } from '@services/api/hooks/useDeleteReconciliationRulesMutation';
import { useGetReconciliationRulesQuery } from '@services/api/hooks/useGetReconciliationRulesQuery';
import { usePostReconciliationRulesMutation } from '@services/api/hooks/usePostReconciliationRulesMutation';
import { queryClient } from '@services/api/queryClient';
import { IconCheckbox, IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Col, Typography } from 'antd';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';

import { getCategoryBasedCommissionFormSchema } from './common';
import BulkUpdateCommission from './components/BulkUpdateCommission';

const { Text } = Typography;

const CategoryBasedCommissionForm = () => {
  const { t, i18n } = useTranslation('Finance');
  const schema = getCategoryBasedCommissionFormSchema({ t });
  const { postReconciliationRules } = usePostReconciliationRulesMutation();
  const { deleteReconciliationRule } = useDeleteReconciliationRulesMutation();
  const { id } = useParams();
  const user = useUser();
  const userRole = useUserRole();
  const dataSourceId = id ?? user?.datasource;
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const isDisabled = !isSuperUser;

  const [isBulkUpdateCommissionActive, toggleIsBulkUpdateCommissionActive] = useBoolean(false);

  const { dataSourceDetail } = useDataSourceDetailQuery({
    dataSourceId,
    queryOptions: {
      enabled: Boolean(dataSourceId),
    },
  });

  const { reconciliationRules, isFetchingReconciliationRules } = useGetReconciliationRulesQuery({
    params: {
      datasource: dataSourceDetail?.id,
      sort: 'created_at',
    },
    queryOptions: {
      enabled: Boolean(dataSourceDetail?.id),
    },
  });

  const { control, reset, trigger, getValues } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const formData = useWatch({
    control,
    name: 'formData',
  });

  useEffect(() => {
    const parsedInitialValues = schema.safeParse({
      formData: reconciliationRules?.map((rule) => ({ ...rule, ruleId: rule.id })) ?? [],
    });
    if (parsedInitialValues.success) {
      reset({ ...parsedInitialValues.data });
    }
  }, [reconciliationRules]);

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData();

  const invalidateReconciliationRules = () => {
    queryClient.invalidateQueries({
      queryKey: [
        QueryKey.RECONCILIATION_RULES,
        { datasource: dataSourceDetail?.id, sort: 'created_at' },
      ],
    });
  };

  const disableTreeNodes = useCallback(
    (data) => {
      return data?.map((item) => {
        if (item.children) {
          return {
            title: item.title,
            value: item.value,
            disabled: formData?.some?.((formItem) => formItem.category_node === item.value),
            key: item.value,
            children: disableTreeNodes(item.children),
          };
        }
        return {
          title: item.title,
          value: item.value,
          key: item.value,
        };
      });
    },
    [formData]
  );

  const _categoryTreeData = disableTreeNodes(categoryTreeData);

  return (
    <>
      <AkinonBox
        title={t('category_based_commission')}
        bannerColor={Color.WILD_WATERMELON}
        className="box-primary form-box w-full shadow-md shadow-gray-900"
        shadow
        actions={
          <Show when={isSuperUser}>
            <AkinonButton type="primary" onClick={() => toggleIsBulkUpdateCommissionActive()}>
              {t('bulk_commission_update')}
            </AkinonButton>
          </Show>
        }
      >
        <AkinonDividerBox header={<Text style={{ color: 'white' }}>{t('categories')}</Text>}>
          <AkinonSpin spinning={isFetchingReconciliationRules}>
            <div
              style={{
                marginTop: 48,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              {fields.map((field, index) => {
                const _field =
                  schema.shape.formData.element.safeParse(getValues(`formData.${index}`)).data ??
                  {};
                const reconciliationRule = reconciliationRules?.find(
                  ({ id }) => id === _field.ruleId
                );
                const _reconciliationRule = schema.shape.formData.element.safeParse({
                  ...pick(reconciliationRule, keys(_field)),
                  ruleId: _field.ruleId,
                }).data;

                const isFieldChanged = !isEqual(_reconciliationRule, _field);
                return (
                  <AkinonForm layout="vertical" isFlex key={field.id}>
                    <AkinonFormItem
                      label={t('category')}
                      name={`formData.${index}.category_node`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                      wrapperCol={{ span: 24 }}
                    >
                      <AkinonTreeSelect
                        loading={isCategoriesLoading}
                        treeData={_categoryTreeData}
                        treeNodeFilterProp={'title'}
                        showSearch
                        treeDefaultExpandAll
                        virtual={false}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        disabled={isDisabled}
                      />
                    </AkinonFormItem>
                    <AkinonFormItem
                      label={t('commission')}
                      name={`formData.${index}.commission`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                    >
                      <AkinonInput disabled={isDisabled} />
                    </AkinonFormItem>
                    <AkinonFormItem
                      label={t('maturity')}
                      name={`formData.${index}.maturity`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                    >
                      <AkinonInput disabled={isDisabled} />
                    </AkinonFormItem>

                    {!isDisabled && (
                      <Col style={{ alignSelf: 'center', display: 'inline-flex', gap: 2 }}>
                        <IconCheckbox
                          alignmentBaseline="middle"
                          size={24}
                          className={clsx('color-lima block', {
                            hidden: !isFieldChanged,
                          })}
                          cursor="pointer"
                          onClick={() => {
                            trigger(`formData.${index}`).then((isValid) => {
                              if (!isValid) return;
                              if (_field.ruleId) {
                                deleteReconciliationRule(
                                  {
                                    ruleId: _field.ruleId,
                                    requestConfig: {
                                      suppressErrorNotifications: true,
                                      suppressSuccessNotifications: true,
                                    },
                                  },
                                  {
                                    onSuccess() {
                                      postReconciliationRules(
                                        {
                                          requestConfig: {
                                            successMessage: t('transaction_success'),
                                            successDescription: t('changes_saved'),
                                            errorMessage: t('transaction_failed'),
                                            errorDescription: t('changes_error'),
                                          },
                                          requestBody: {
                                            datasource: dataSourceDetail?.id,
                                            ...omit(_field, 'ruleId'),
                                          },
                                        },
                                        {
                                          onSettled() {
                                            invalidateReconciliationRules();
                                          },
                                        }
                                      );
                                    },
                                  }
                                );
                              } else {
                                postReconciliationRules(
                                  {
                                    requestBody: {
                                      datasource: dataSourceDetail?.id,
                                      ...omit(_field, 'ruleId'),
                                    },
                                    requestConfig: {
                                      successMessage: t('transaction_success'),
                                      successDescription: t('changes_saved'),
                                      errorMessage: t('transaction_failed'),
                                      errorDescription: t('changes_error'),
                                    },
                                  },
                                  {
                                    onSuccess() {
                                      invalidateReconciliationRules();
                                    },
                                  }
                                );
                              }
                            });
                          }}
                        />
                        <IconCircleMinus
                          alignmentBaseline="middle"
                          size={24}
                          className="color-wild-watermelon"
                          cursor="pointer"
                          style={{ alignSelf: 'center' }}
                          onClick={() => {
                            if (field.ruleId) {
                              deleteReconciliationRule(
                                {
                                  ruleId: field.ruleId,
                                  requestConfig: {
                                    successMessage: t('transaction_success'),
                                    successDescription: t('changes_saved'),
                                    errorMessage: t('transaction_failed'),
                                    errorDescription: t('changes_error'),
                                  },
                                },
                                {
                                  onSuccess() {
                                    invalidateReconciliationRules();
                                  },
                                }
                              );
                            } else {
                              remove(index);
                            }
                          }}
                        />
                      </Col>
                    )}
                  </AkinonForm>
                );
              })}

              <Col span={6}>
                <AkinonButton
                  icon={<IconCirclePlus alignmentBaseline="middle" size={20} className="white" />}
                  htmlType="button"
                  onClick={() =>
                    append({ ruleId: null, category_node: null, commission: null, maturity: null })
                  }
                  type="primary"
                  disabled={isDisabled}
                >
                  {t('add').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </Col>
            </div>
          </AkinonSpin>
        </AkinonDividerBox>
      </AkinonBox>
      <Show when={isBulkUpdateCommissionActive} animateProps={Animations.fade}>
        <BulkUpdateCommission />
      </Show>
    </>
  );
};

export default CategoryBasedCommissionForm;
