import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';

const useAddProductToCollection = ({
  collectionId,
  selectedRowKeys,
  productOffersWithProductDetails,
  addProductToCollectionAsync,
}) => {
  const onAddSelectedItems = ({ mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const selectedRows = productOffersWithProductDetails.filter((row) =>
      selectedRowKeys.includes(row.id)
    );

    const promises = selectedRows.map((row) => {
      return () =>
        addProductToCollectionAsync({
          requestBody: {
            offer: row.id,
            collection: collectionId,
          },
        })
          .then(() => {
            onSuccess();
          })
          .catch(() => {
            onError();
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.COLLECTION_ITEMS, { collection: collectionId }]);
      queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
    });
  };

  return { onAddSelectedItems };
};

export default useAddProductToCollection;
