import { getBillingAddressUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useBillingAddressQuery({ billingAddressId, queryOptions, onSuccess }) {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.BILLING_ADDRESS, billingAddressId],
    async queryFn() {
      const response = await HTTP.get(getBillingAddressUrl, {
        urlParams: { billingAddressId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    billingAddress: data,
    isBillingAddressLoading: isLoading,
    billingAddressError: error,
  };
}
