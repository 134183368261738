import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { listPackagesUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';
import omit from 'lodash/omit';

export const usePaginatedPackagesQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _params = {
    ...getRequestPaginationParams(pagination),
    ...getRequestFiltersParams(filters),
    ...params,
  };
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PACKAGES, _params],
    async queryFn() {
      const response = await HTTP.get(listPackagesUrl, {
        params: _params,
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    packagesResponse: data,
    packages: data?.results,
    isFetchingPackages: isFetching,
    totalPackages: data?.count,
    packagesError: error,
  };
};

export const usePaginatedPackagesWithFileQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _filters = getRequestFiltersParams(omit(filters, 'filter_file'));
  const _pagination = getRequestPaginationParams(pagination);
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PACKAGES, _pagination, _filters, params, filters?.filter_file],
    async queryFn() {
      const formData = new FormData();
      const fileFilter = filters?.filter_file;
      formData.append('filter_file', new Blob([fileFilter?.originFileObj]), fileFilter?.name);

      const response = await HTTP.post(listPackagesUrl, formData, {
        params: {
          ..._pagination,
          ..._filters,
          ...params,
        },
        suppressErrorNotifications: true,
        suppressSuccessNotifications: true,
        headers: {
          'Origin-Method': HttpMethod.GET,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    packagesResponse: data,
    packages: data?.results,
    isFetchingPackages: isFetching,
    totalPackages: data?.count,
    packagesError: error,
  };
};
