import { getProductLowestPriceUrl } from '@constants/apiUrls';
import { ErrorCodes } from '@constants/errorCodes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useProductLowestPriceQuery({ productId, queryOptions, params, onSuccess } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_LOWEST_PRICE, productId, params],
    async queryFn() {
      const response = await HTTP.get(getProductLowestPriceUrl, {
        params,
        urlParams: { productId },
        shouldSuppressErrorNotification: (response) => {
          return response?.data?.code === ErrorCodes.NO_MASTER_PRICE;
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productLowestPrice: data,
    isProductLowestPriceLoading: isLoading,
    isFetchingProductLowestPrice: isFetching,
    productLowestPriceError: error,
  };
}
