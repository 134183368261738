import { getCategoryNodePermissionsUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostCategoryNodePermissionsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => HTTP.post(getCategoryNodePermissionsUrl, requestBody),
    ...mutationOptions,
  });

  return {
    createCategoryNodePermission: mutateAsync,
    isCreatingNewCategoryNodePermission: isLoading,
  };
}
