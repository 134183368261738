import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useDataSourcesQuery({ queryOptions, params } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCES, params],
    queryFn: () =>
      HTTP.get(getDataSourcesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    dataSources: data?.results,
    isFetchingDataSources: isFetching,
  };
}

export function usePaginatedDataSourcesQuery({ queryOptions, params }) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCES, params],
    queryFn: () =>
      HTTP.get(getDataSourcesUrl, {
        params: {
          ...getRequestPaginationParams(params),
          ...getRequestFiltersParams(params),
        },
      }),
    ...queryOptions,
  });

  return {
    dataSourcesResponse: data,
    dataSources: data?.results,
    isFetchingDataSources: isFetching,
    isLoadingDataSources: isLoading,
    dataSourcesError: error,
    totalDataSources: data?.count,
  };
}
