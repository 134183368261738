import { createProductUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useCreateProductMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ preOfferId, requestBody }) =>
      HTTP.post(createProductUrl, requestBody, {
        urlParams: { preOfferId },
      }),
    ...mutationOptions,
  });

  return {
    createProduct: mutate,
    isCreatingProduct: isLoading,
  };
}
