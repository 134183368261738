import { isFileObject } from '@components/AkinonFilter/components/ActiveFilterItem/common';
import merge from 'lodash/merge';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { mutative } from 'zustand-mutative';

import { sync } from './middleware/sync';
import { createAppSlice } from './slices/app';
import { createFiltersSlice } from './slices/filters';
import { createLanguageSlice } from './slices/language';
import { createPageDataSlice } from './slices/pageData';
import { createPreOfferSlice } from './slices/preOffer';

const isDev = import.meta.env.DEV;

const storeInitializer = (...storeArgs) => ({
  ...createPreOfferSlice(...storeArgs),
  ...createFiltersSlice(...storeArgs),
  ...createPageDataSlice(...storeArgs),
  ...createAppSlice(...storeArgs),
  ...createLanguageSlice(...storeArgs),
});

const persistOptions = {
  name: 'persistedStore',
  storage: createJSONStorage(() => localStorage, {
    replacer: (key, value) => {
      // Do not persist non-serializable file objects
      if (key === 'filter_file' || isFileObject(value)) {
        return;
      } else {
        return value;
      }
    },
  }),
  partialize: (state) => ({
    logCenterRecords: state.logCenterRecords,
    filters: state.filters,
    pageData: state.pageData,
    tokens: state.tokens,
  }),
  merge,
};

const mutativeOptions = {
  strict: isDev,
  enableAutoFreeze: isDev,
};

const devtoolsOptions = {
  anonymousActionType: 'setAkinonStore',
};

const syncOptions = {
  name: persistOptions.name,
  enabled: true,
  partialize: (state) => {
    return state?.tokens ? { tokens: state.tokens } : {};
  },
};

export const useStore = create(
  devtools(
    persist(sync(mutative(storeInitializer, mutativeOptions), syncOptions), persistOptions),
    devtoolsOptions
  )
);

export default useStore;
