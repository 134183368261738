import { getUploadStatusUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useImportStatusQuery({ key, queryOptions } = {}) {
  const { data, isFetching } = useQuery({
    queryFn: () =>
      HTTP.get(getUploadStatusUrl, {
        urlParams: {
          key,
        },
      }),
    enabled: Boolean(key),
    queryKey: [QueryKey.IMPORT_STATUS, key],
    ...queryOptions,
  });

  return {
    importStatus: data,
    isFetchingImportStatus: isFetching,
  };
}
