import { widgetsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';
import first from 'lodash/first';

export function useWidgetQuery({ queryOptions, params } = {}) {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QueryKey.WIDGET, params],
    queryFn: () => HTTP.get(widgetsUrl, { params }),
    ...queryOptions,
  });
  return {
    widget: first(data?.results),
    isWidgetLoading: isLoading,
    isWidgetFetching: isFetching,
  };
}
