import { changePasswordUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function useChangePasswordMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) =>
      HTTP.put(changePasswordUrl, requestBody, {
        suppressErrorNotifications: true,
        suppressSuccessNotifications: true,
      }),
    ...mutationOptions,
  });
  return {
    changePassword: mutate,
    isChangingPassword: isLoading,
  };
}
