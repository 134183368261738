import { getAgreementConsentsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

/**
 * @param {Object} [args]
 * @param {string} [args.agreementId]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function useAgreementConsentQuery({ params, agreementId, queryOptions, onSuccess } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.AGREEMENT_CONSENTS, params, agreementId],
    async queryFn() {
      const response = await HTTP.get(getAgreementConsentsUrl, {
        params: { agreement: agreementId, ...params },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    agreementConsents: data?.results,
    totalAgreementConsents: data?.count || 0,
    isAgreementConsentsFetching: isFetching,
  };
}
