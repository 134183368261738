import * as z from 'zod';

export const FormKey = {
  TITLE: 'title',
  CONTENT: 'content',
};

export const getFormSchema = ({ t }) => {
  return z.object({
    [FormKey.TITLE]: z.string().min(1),
    [FormKey.CONTENT]: z
      .string()
      .min(1)
      .transform((value, ctx) => {
        if (value === '<p><br></p>') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('formRule.required'),
          });
          return z.NEVER;
        } else return value;
      }),
  });
};
