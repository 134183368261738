import {
  getPackageCargoLabelUrl,
  getPackageItemsUrl,
  getPackagesIdUrl,
  listPackagesUrl,
  postPackageGenerateShipmentUrl,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getPackages = ({ requestConfig }) => {
  return HTTP.get(listPackagesUrl, requestConfig);
};

export const getPackageDetail = (packageId) => {
  return HTTP.get(getPackagesIdUrl, { urlParams: { packageId } });
};

export const patchPackage = (url, { params = {} }, requestConfig) => {
  return HTTP.patch(url, params, requestConfig);
};

export const getPackageStatuses = async (url) => {
  const response = await HTTP.options(url);
  return response?.actions;
};

export const postPackageReCreate = (url, params = {}, requestConfig) => {
  return HTTP.post(url, { ...params }, requestConfig);
};

export const getPackageItems = (params = {}) => {
  return HTTP.get(getPackageItemsUrl, { params });
};

export const getPackageCargoLabel = (params = {}) => {
  const { packageId } = params;
  return HTTP.get(getPackageCargoLabelUrl, { urlParams: { packageId } });
};

export const postPackageGenerateShipment = (packageId, requestBody = {}) => {
  return HTTP.post(postPackageGenerateShipmentUrl, requestBody, {
    urlParams: { packageId },
  });
};
