import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getQuickRepliesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const usePaginatedQuickRepliesQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
} = {}) => {
  const { data, isLoading, refetch, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.QUICK_REPLIES, pagination, filters, params],
    queryFn: () =>
      HTTP.get(getQuickRepliesUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    quickReplies: data?.results,
    isQuickRepliesLoading: isLoading,
    isFetchingQuickReplies: isFetching,
    total: data?.count,
    refetchQuickReplies: refetch,
  };
};
