import { deleteReconciliationsRebateRuleUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function useDeleteReconciliationRebateRuleMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ ruleId, requestConfig }) => {
      HTTP.delete(deleteReconciliationsRebateRuleUrl, {
        ...requestConfig,
        urlParams: { ruleId },
      });
    },
    ...mutationOptions,
  });

  return {
    deleteReconciliationRebateRule: mutate,
    isDeletingReconciliationRule: isLoading,
  };
}
