import { getProductLowestPriceUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueries } from '@tanstack/react-query';

import { HTTP } from '../../client';

export function useProductLowestPriceQueries({ productIds, queryOptions, params } = {}) {
  const productLowestPrices = useQueries({
    queries:
      productIds?.map((productId) => ({
        queryKey: [QueryKey.PRODUCT_LOWEST_PRICE, productId, params],
        queryFn: () =>
          HTTP.get(getProductLowestPriceUrl, {
            params,
            urlParams: { productId },
          }),
        ...queryOptions,
      })) ?? [],
  });

  return {
    productLowestPrices: productLowestPrices.map((query) => query.data),
    isLoadingProductLowestPrices: productLowestPrices.some((query) => query.isLoading),
    isFetchingProductLowestPrices: productLowestPrices.some(
      (query) => query.fetchStatus === 'fetching'
    ),
    productLowestPricesError: productLowestPrices.find((query) => query.error),
  };
}
