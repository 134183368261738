import { getTotalItemsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useTotalItemsQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.TOTAL_ITEMS, params],
    queryFn: () => HTTP.get(getTotalItemsUrl, { params }),
  });

  return {
    totalItems: data?.total_items,
    isFetchingTotalItems: isFetching,
  };
}
