import { openNotification } from '@components/AkinonNotification';

const useRemoveProductsCommission = ({
  t,
  refetchProductOffers,
  refetchReconciliationRules,
  deleteReconciliationRulesAsync,
}) => {
  const onRemoveProductsCommission = ({ selectedRows }) => {
    const promises = selectedRows.map((row) => {
      return async () => {
        try {
          await deleteReconciliationRulesAsync({
            ruleId: row,
            requestConfig: {
              suppressErrorNotifications: true,
              suppressSuccessNotifications: true,
            },
          });
          openNotification({
            message: t('transaction_success'),
            description: t('transactions.body.delete_commission_success'),
          });
        } catch (error) {
          openNotification({
            message: t('transaction_failed'),
            description: t('transactions.body.delete_commission_failed'),
            type: 'error',
          });
        }
      };
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      refetchProductOffers();
      refetchReconciliationRules();
    });
  };

  return { onRemoveProductsCommission };
};

export default useRemoveProductsCommission;
