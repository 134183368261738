import { getCountryUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useCountryQuery({ queryOptions, countryId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.COUNTRY, countryId],
    queryFn: () => HTTP.get(getCountryUrl, { urlParams: { countryId } }),
    ...queryOptions,
  });

  return {
    country: data,
    isCountryLoading: isLoading,
  };
}
