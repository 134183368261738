import { getOrdersUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useOrdersQuery({ params, queryOptions, onSuccess, onError }) {
  const { data, isLoading, isFetching, ...rest } = useAkinonQuery({
    queryKey: ['orders', params],
    queryFn: async () => {
      try {
        const result = await HTTP.get(getOrdersUrl, {
          params,
        });
        onSuccess?.(result);
        return result;
      } catch (error) {
        onError?.(error);
      }
    },
    ...queryOptions,
  });

  return {
    ...rest,
    orders: data?.results || [],
    isOrdersLoading: isLoading,
    isFetchingOrders: isFetching,
  };
}
