import { sleep } from '@utils/index';
import isNumber from 'lodash/isNumber';
import { useEffect, useState } from 'react';
import { useCounter } from 'react-use';

const useBulkAction = ({
  bulkActionMutate,
  isSingleRequest = false,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState(false);
  const [failedRequestCount, { inc: incFailedRequestCount, reset: resetFailedRequestCount }] =
    useCounter(0);
  const [
    successfulRequestCount,
    { inc: incSuccessfulRequestCount, reset: resetSuccessfulRequestCount },
  ] = useCounter(0);

  const onShowModal = () => {
    setIsModalVisible(true);
  };
  const onCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRowKeys([]);
  };
  const onCloseProgressModal = () => {
    setIsProgressModalVisible(false);
    resetFailedRequestCount();
    resetSuccessfulRequestCount();
    setSelectedRowKeys([]);
  };
  const onStartBulkAction = (additionalParams = {}) => {
    setIsModalVisible(false);
    setIsProgressModalVisible(true);
    bulkActionMutate({
      ...additionalParams,
      mutationOptions: {
        onSuccess: (successCount = 1) =>
          incSuccessfulRequestCount(isNumber(successCount) ? successCount : 1),
        onError: (errorCount = 1) => incFailedRequestCount(isNumber(errorCount) ? errorCount : 1),
      },
    });
  };

  const isSingleRow = selectedRowKeys?.length === 1;
  const totalRequestCount = isSingleRequest ? 1 : selectedRowKeys?.length;

  useEffect(() => {
    const isFinished = failedRequestCount + successfulRequestCount === totalRequestCount;
    const isFailed = failedRequestCount > 0;
    if (isFinished) {
      setSelectedRowKeys([]);
    }
    if (isFinished && !isFailed) {
      const waitTime = 5000;
      sleep(waitTime).then(onCloseProgressModal);
    }
  }, [failedRequestCount, successfulRequestCount]);

  return {
    onShowModal,
    isModalVisible,
    isProgressModalVisible: isSingleRow ? false : !isModalVisible && isProgressModalVisible,
    onCloseModal,
    onCloseProgressModal,
    failedRequestCount,
    successfulRequestCount,
    totalRequestCount,
    onStartBulkAction,
  };
};

export default useBulkAction;
