import { getShippingCompaniesByIdUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const useShippingCompanyQuery = ({ shippingCompanyId, queryOptions, params, onSuccess }) => {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_COMPANY, params, shippingCompanyId],
    async queryFn() {
      const response = await HTTP.get(getShippingCompaniesByIdUrl, {
        urlParams: { shippingCompanyId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    shippingCompany: data,
    isShippingCompanyLoading: isLoading,
    shippingCompanyError: error,
  };
};
