import './style.scss';

import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { getConversationsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import useSelectedKeys from '@hooks/useSelectedKeys';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { useCreateConversationStatusMutation } from '@services/api/hooks/useCreateConversationStatusMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConversationType } from '../common';
import { getColumns, getTableActions } from './common';
import { useOrderMessagesQuery } from './hooks/api/useOrderMessagesQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useChangeMessagePrivacyStatus from './hooks/useChangeMessagePrivacyStatus';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

function OrderMessages() {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();
  const { orderMessages, isOrderMessagesFetching, orderMessagesTotal } = useOrderMessagesQuery({
    requestConfig: {
      params: {
        ...getRequestPaginationParams(pagination),
        ...getRequestFiltersParams(filters),
        item_type: ConversationType.ORDER,
      },
    },
  });
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { selectedRowKeys, setSelectedRowKeys } = useSelectedKeys({
    enabled: isSuperUser,
  });

  const { createConversationStatusAsync } = useCreateConversationStatusMutation();
  const { onMakePublic, onMakePrivate } = useChangeMessagePrivacyStatus({
    t,
    createConversationStatusAsync,
    selectedRowKeys,
  });

  const { onShowModal: onShowMakePublicModal, ...makePublicBulkActionProps } = useBulkAction({
    bulkActionMutate: onMakePublic,
    selectedRowKeys,
    setSelectedRowKeys,
  });
  const { onShowModal: onShowMakePrivateModal, ...makePrivateBulkActionProps } = useBulkAction({
    bulkActionMutate: onMakePrivate,
    selectedRowKeys,
    setSelectedRowKeys,
  });

  const filterFields = useFilterFields({ t });
  const columns = getColumns({ t, isSuperUser });
  const tableActions = getTableActions({
    t,
    isSuperUser,
    onShowMakePublicModal,
    onShowMakePrivateModal,
  });

  useBreadcrumbs();
  usePageMeta();

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.messages.orderMessagesForm, { id: record.id });
    },
  });

  return (
    <section className="order-messages">
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={orderMessagesTotal} />
      <Divider />
      <AkinonTable
        title={t('order.messages')}
        rowKey={'id'}
        dataSource={orderMessages}
        loading={isOrderMessagesFetching}
        optionsUrl={getConversationsUrl}
        exportable={false}
        columns={columns}
        total={orderMessagesTotal}
        onRow={onRow}
        tableActions={tableActions}
        description={`${orderMessagesTotal} ${t('results.found')}`}
        pagination={pagination}
        setPagination={setPagination}
        rowSelection={isSuperUser}
      />

      <BulkActionModal
        {...makePublicBulkActionProps}
        modalProps={{
          title: t('bulk.make.messages.public'),
          description: t('bulk.make.messages.public.desc'),
        }}
        progressModalProps={{ title: t('bulk.make.messages.public') }}
      />
      <BulkActionModal
        {...makePrivateBulkActionProps}
        modalProps={{
          title: t('bulk.make.messages.private'),
          description: t('bulk.make.messages.private.desc'),
        }}
        progressModalProps={{ title: t('bulk.make.messages.private') }}
      />
    </section>
  );
}

export default OrderMessages;
