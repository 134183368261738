import { postTicketUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostTicketMutation({ mutationOptions } = {}) {
  const { mutate, isLoading, isSuccess } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig = {} }) =>
      HTTP.post(postTicketUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createTicket: mutate,
    isCreatingTicket: isLoading,
    didCreateTicket: isSuccess,
  };
}
