import { oAuthProviderBySlugUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePatchOauthProviderMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ slug, requestBody, requestConfig }) =>
      HTTP.patch(oAuthProviderBySlugUrl, requestBody, { urlParams: { slug }, ...requestConfig }),
    ...mutationOptions,
  });

  return {
    updateProvider: mutate,
    isProviderUpdating: isLoading,
  };
}
