import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductsUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useLocation } from 'react-router-dom';

export const useProductsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, params],
    async queryFn() {
      const response = await HTTP.get(getProductsUrl, { params });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    isProductsLoading: isLoading,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};

export const usePaginatedProductsQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _pagination = getRequestPaginationParams(pagination);
  const _filters = getRequestFiltersParams(filters);
  const location = useLocation();
  const searchQuery = get(location, ['state', 'searchQuery']);

  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, _pagination, _filters, params],
    async queryFn() {
      const response = await HTTP.get(getProductsUrl, {
        params: {
          ...(searchQuery ? { q: searchQuery } : {}),
          ..._pagination,
          ..._filters,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    totalProducts: data?.count,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};

export const usePaginatedProductsWithFileQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const _pagination = getRequestPaginationParams(pagination);
  const fileFilter = get(filters, 'filter_file');
  const _filters = getRequestFiltersParams(omit(filters, 'filter_file'));
  const location = useLocation();
  const searchQuery = get(location, ['state', 'searchQuery']);

  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, _pagination, _filters, params, fileFilter],
    async queryFn() {
      const formData = new FormData();
      formData.append('filter_file', new Blob([fileFilter?.originFileObj]), fileFilter?.name);

      const response = await HTTP.post(getProductsUrl, formData, {
        params: {
          ...(searchQuery ? { q: searchQuery } : {}),
          ..._pagination,
          ..._filters,
          ...params,
        },
        suppressErrorNotifications: true,
        suppressSuccessNotifications: true,
        headers: {
          'Origin-Method': HttpMethod.GET,
        },
      });

      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    refetchProducts: refetch,
    totalProducts: data?.count,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};
