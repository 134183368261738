import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import Box from '@components/utility/box';
import { formBuilderVerticalLayout } from '@constants/layoutTypes';
import { useCreateOfferRejectReasonMutation } from '@services/api/hooks/useCreateOfferRejectReasonMutation';
import { Form, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, rejectionReasonFormResolver } from './common';

const RejectionReasonForm = ({ modalProps }) => {
  const { t } = useTranslation('Settings');
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: rejectionReasonFormResolver,
  });

  const { createOfferRejectReason, isCreatingOfferRejectReason } =
    useCreateOfferRejectReasonMutation({
      mutationOptions: {
        onSuccess: () => {
          handleClose();
        },
      },
    });

  const onSubmit = (values) => {
    createOfferRejectReason({
      requestBody: values,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('offer_reject_reason_message.save.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('offer_reject_reason_message.save.error'),
      },
    });
  };

  const handleClose = () => {
    modalProps?.onCancel();
    reset();
  };

  return (
    <Modal
      {...modalProps}
      onCancel={handleClose}
      footer={null}
      className="reject-reason-form__modal"
    >
      <Box className="box-primary form-box">
        <AkinonForm
          className="akn-form akn-form-v2"
          layout="vertical"
          wrapperCol={formBuilderVerticalLayout.wrapperCol}
        >
          <AkinonFormItem name={FormKey.REASON} label={t('reason')} control={control} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('reason') })} />
          </AkinonFormItem>
          <Form.Item className="ant-col-md-24 ant-col-sm-24 ant-col-xs-24">
            <AkinonButton
              htmlType="button"
              onClick={handleSubmit(onSubmit)}
              type="primary"
              loading={isCreatingOfferRejectReason}
              className="akn-submit-button"
            >
              {t('save')}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>
      </Box>
    </Modal>
  );
};

export default RejectionReasonForm;
