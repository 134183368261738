import { oAuthProviderAuthenticateUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';

import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useOAuthAuthenticateMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ slug, params }) =>
      HTTP.post(oAuthProviderAuthenticateUrl, undefined, {
        params,
        urlParams: { providerSlug: slug },
      }),
    ...mutationOptions,
  });

  return {
    authenticate: mutate,
    isAuthenticating: isLoading,
  };
}
