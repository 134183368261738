import { getPathFragments } from '@common/routing';
import useStore from '@zustand-store/index';
import { useLocation } from 'react-router-dom';

export default function usePageData() {
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const storePageData = useStore((state) => state.pageData);
  const setStorePageData = useStore((state) => state.setPageData);
  const pageData = storePageData?.[`${mainPath}${search}`] ?? {};

  return {
    pageData,
    setPageData: (newPageData) =>
      setStorePageData({
        pagePath: `${mainPath}${search}`,
        pageData: newPageData,
      }),
  };
}
