import {
  cacheKeydownloadURL,
  getAttributeSetsUrl,
  getCollectionsUrl,
  getLanguagesUrl,
  getMeUrl,
  getProductCollectionWidgetsUrl,
  getRejectionReasonsUrl,
  getWidgetSchemaUrl,
  patchWidgetUrl,
  resetPasswordUrl,
  resetPasswordWithTokenUrl,
  widgetsUrl,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getLanguages = () => {
  return HTTP.get(getLanguagesUrl);
};

export const getUser = () => {
  return HTTP.get(getMeUrl);
};

export const getDownloadUrl = (cacheKey) => {
  return HTTP.get(cacheKeydownloadURL, { urlParams: { cacheKey } });
};

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getRejectionReasons = ({ requestConfig }) => {
  return HTTP.get(getRejectionReasonsUrl, requestConfig);
};

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getAttributeSets = ({ requestConfig }) => {
  return HTTP.get(getAttributeSetsUrl, requestConfig);
};

export const getDownloadableContent = ({ downloadUrl }) => {
  delete HTTP.defaults.headers.Authorization;

  return HTTP.get(downloadUrl, {
    responseType: 'blob',
  });
};

export const getWidgetSchema = ({ requestConfig, schemaId }) => {
  return HTTP.get(getWidgetSchemaUrl, { ...requestConfig, urlParams: { schemaId } });
};

export const postWidget = ({ requestConfig, requestBody }) => {
  return HTTP.post(widgetsUrl, requestBody, requestConfig);
};

export const patchWidget = ({ requestConfig, requestBody, widgetId }) => {
  return HTTP.patch(patchWidgetUrl, requestBody, {
    ...requestConfig,
    urlParams: { widgetId },
  });
};

export const getCollections = ({ requestConfig }) => {
  return HTTP.get(getCollectionsUrl, requestConfig);
};

export const getProductCollectionWidgets = ({ requestConfig }) => {
  return HTTP.get(getProductCollectionWidgetsUrl, requestConfig);
};

export const postResetPassword = ({ requestConfig, requestBody }) => {
  return HTTP.post(resetPasswordUrl, requestBody, requestConfig);
};

export const putResetPassword = ({ requestConfig, requestBody, resetToken }) => {
  return HTTP.put(resetPasswordWithTokenUrl, requestBody, {
    ...requestConfig,
    urlParams: { resetToken },
  });
};
