import {
  addAuthObject,
  getActiveAuthObject,
  removeAuthObject,
  resetAuthObjects,
  updateAuthObject,
} from '@common/auth';

class BaseAuthAdapter {
  get accessToken() {
    return getActiveAuthObject()?.access;
  }
  get refreshToken() {
    return getActiveAuthObject()?.refresh;
  }
  addAuth(authData) {
    addAuthObject(authData);
  }
  updateAuth(access, authData) {
    updateAuthObject(access, authData);
  }
  clear() {
    removeAuthObject(getActiveAuthObject()?.access);
  }
  reset() {
    resetAuthObjects();
  }
}

export const Auth = new BaseAuthAdapter();
