import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductOffersUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

const fetchProductOffers = async (queryParams, fileFilter) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await HTTP.post(getProductOffersUrl, formData, {
      params: queryParams,
      headers: {
        'Origin-Method': HttpMethod.GET,
      },
    });
  }
  return await HTTP.get(getProductOffersUrl, { params: queryParams });
};

export const usePaginatedProductOffersQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
  onError,
}) => {
  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_OFFERS, pagination, filters, params],
    async queryFn() {
      try {
        const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
        const filterParams = getRequestFiltersParams(filters);
        delete filterParams?.filter_file;
        const queryParams = {
          ...getRequestPaginationParams(pagination),
          ...filterParams,
          ...params,
        };

        const response = await fetchProductOffers(queryParams, fileFilter);
        onSuccess?.(response);
        return response;
      } catch (error) {
        onError?.(error);
      }
    },
    ...queryOptions,
  });

  return {
    productOffersResponse: data,
    productOffers: data?.results,
    isFetchingProductOffers: isFetching,
    totalProductOffers: data?.count,
    productOffersError: error,
    refetchProductOffers: refetch,
  };
};
