import { getDataSourceShipmentCompanyOptionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const useSellerShipmentCompanyOptionsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.SHIPMENT_COMPANY_CONFIG, params],
    async queryFn() {
      const response = await HTTP.get(getDataSourceShipmentCompanyOptionsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });
  return {
    sellerShipmentCompanyOptionsConfig: data?.results,
    isSellerShipmentCompanyOptionsLoading: isLoading,
    sellerShipmentCompanyOptionsError: error,
    refetchSellerShipmentCompanyOptions: refetch,
  };
};
