import { openNotification } from '@components/AkinonNotification';
import { QueryKey } from '@constants/query';
import { useWithdrawMutation } from '@services/api/hooks/useWithdrawMutation';
import { queryClient } from '@services/api/queryClient';
import { Modal } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const WithdrawModal = ({ preOffer, isWithdrawModalVisible, onClose }) => {
  const { withdrawPreOffer } = useWithdrawMutation();
  const { t } = useTranslation('ProductsAndCategories');

  return (
    <div>
      <Modal
        visible={isWithdrawModalVisible}
        title={t('recall')}
        centered
        onCancel={() => onClose()}
        onOk={() => {
          const preOfferId = preOffer.id;
          withdrawPreOffer(
            {
              preOfferId,
              requestConfig: {
                suppressErrorNotifications: true,
                suppressSuccessNotifications: true,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([QueryKey.PRE_OFFER, preOfferId]);
                openNotification({
                  message: t('recall.success.title'),
                  description: t('recall.success.message'),
                });
              },
              onError: () => {
                openNotification({
                  message: t('recall.error.title'),
                  description: t('recall.error.message'),
                  type: 'error',
                });
              },
              onSettled: () => {
                onClose();
              },
            }
          );
        }}
      >
        <div>{t('recall.are.you.sure')}</div>
      </Modal>
    </div>
  );
};

export default memo(WithdrawModal);
