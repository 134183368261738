import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { preOfferImagesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQuery } from '@tanstack/react-query';

import { HTTP } from '../../client';

export function usePreOfferImagesQuery({ queryOptions, params }) {
  const { data, isFetching, error } = useQuery({
    queryKey: [QueryKey.PRE_OFFER_IMAGES, params],
    queryFn: () =>
      HTTP.get(preOfferImagesUrl, {
        params: {
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    preOfferImages: data?.results,
    isFetchingPreOfferImages: isFetching,
    preOfferImagesError: error,
  };
}

export function usePaginatedPreOfferImagesQuery({ pagination, filters, queryOptions }) {
  const { data, isFetching, error } = useQuery({
    queryKey: [QueryKey.PRE_OFFER_IMAGES, pagination, filters],
    queryFn: () =>
      HTTP.get(preOfferImagesUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      }),
    ...queryOptions,
  });

  return {
    preOfferImagesResponse: data,
    preOfferImages: data?.results,
    isFetchingPreOfferImages: isFetching,
    totalPreOfferImages: data?.count,
    preOfferImagesError: error,
  };
}
