import { resolveIntegrationErrorUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useResolveIntegrationErrorMutation() {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ integrationErrorId, requestConfig }) => {
      return HTTP.post(resolveIntegrationErrorUrl, undefined, {
        urlParams: { integrationErrorId },
        params: { integrationErrorId },
        ...requestConfig,
      });
    },
    onSuccess() {
      queryClient.invalidateQueries(QueryKey.INTEGRATION_ERRORS);
    },
  });

  return {
    resolveIntegrationError: mutate,
    isResolvingIntegrationError: isLoading,
  };
}
