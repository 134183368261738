import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  breadcrumbs: [],
  openMenuKeys: [],
  selectedMenuKeys: [],
  isLogCenterOpen: false,
  logCenterRecords: [],
  pageMeta: {},
  tokens: [],
};

export const createAppSlice = (set) => ({
  ...initialState,
  // at the 0 index token will be the active token
  addToken: (token) =>
    set((state) => {
      state.tokens = uniqBy([token, ...state.tokens], 'access');
    }),
  updateToken: (access, token) =>
    set((state) => {
      state.tokens = state.tokens.map((_token) =>
        _token.access === access ? merge(_token, token) : _token
      );
    }),
  removeToken: (access) =>
    set((state) => {
      state.tokens = state.tokens.filter((_token) => _token.access !== access);
    }),
  resetTokens: () =>
    set((state) => {
      state.tokens = [];
    }),
  setBreadcrumbs: (breadcrumbs) =>
    set((state) => {
      state.breadcrumbs = breadcrumbs;
    }),
  setOpenMenuKeys: (openMenuKeys) =>
    set((state) => {
      state.openMenuKeys = openMenuKeys;
    }),
  setSelectedMenuKeys: (selectedMenuKeys) =>
    set((state) => {
      state.selectedMenuKeys = selectedMenuKeys;
    }),
  setIsLogCenterOpen: (isLogCenterOpen) =>
    set((state) => {
      state.isLogCenterOpen = isLogCenterOpen;
    }),
  addLogCenterRecord: (newLogCenterRecord) =>
    set((state) => {
      state.logCenterRecords.unshift(newLogCenterRecord);
    }),
  addLogCenterRecords: (newRecords) =>
    set((state) => {
      state.logCenterRecords.unshift(...newRecords);
    }),
  setLogCenterRecords: (newLogCenterRecords) =>
    set((state) => {
      state.logCenterRecords = newLogCenterRecords;
    }),
  setPageMeta: (newPageMeta) =>
    set((state) => {
      state.pageMeta = newPageMeta;
    }),
  resetApp: () =>
    set((state) => {
      state = initialState;
    }),
});
