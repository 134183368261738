import { patchPackageUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePatchPackageMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig, packageId }) =>
      HTTP.patch(patchPackageUrl, requestBody, { ...requestConfig, urlParams: { packageId } }),
  });

  return {
    updatePackagePartially: mutate,
    isUpdatingPackagePartially: isLoading,
  };
}
