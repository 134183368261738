import { postWidgetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

export function usePostWidgetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postWidgetUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    postWidget: mutate,
    isPostingWidget: isLoading,
  };
}
