import { getOrderDetailUrl, getOrderItemsUrl, getOrdersUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getOrderDetail = ({ orderId }) => {
  return HTTP.get(getOrderDetailUrl, { urlParams: { orderId } });
};

export const getOrderItems = ({ params }) => {
  return HTTP.get(getOrderItemsUrl, { params });
};

export const getOrders = ({ requestConfig }) => {
  return HTTP.get(getOrdersUrl, requestConfig);
};
