import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import Modal from '@components/Modal/Modal';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { OfferStatus } from '@pages/ProductsAndCategories/OfferList/common';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { useAddAllProductsToCollectionMutation } from '@services/api/hooks/useAddAllProductsToCollectionMutation';
import { useAddProductToCollectionMutation } from '@services/api/hooks/useAddProductToCollectionMutation';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useCollectionItemsQuery } from '@services/api/hooks/useCollectionItemsQuery';
import { useDeleteProductFromCollectionMutation } from '@services/api/hooks/useDeleteProductFromCollectionMutation';
import { useGetProductsToCollectionStatusQuery } from '@services/api/hooks/useGetAddAllProductsToCollectionStatusQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useRemoveAllProductsToCollectionMutation } from '@services/api/hooks/useRemoveAllProductsToCollectionMutation';
import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BasketOfferDetailTableName,
  getCollectionProductsColumns,
  getCollectionProductsTableActions,
  getMappedProductOffers,
  getProductListColumns,
  getTableActions,
} from '../common';
import useAddAllProductsToCollection from '../hooks/useAddAllProductsToCollection';
import useAddRemoveProductFromCollection from '../hooks/useAddRemoveProductFromCollection';
import useFilterFields from '../hooks/useFilterFields';
import useRemoveAllProductsToCollection from '../hooks/useRemoveAllProductsToCollection';
import { BulkActionProgressModal } from './BulkActionProgressModal';

const ProductCollections = ({ collectionId, collectionDetail }) => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const [productPoolPagination, setProductPoolPagination] = usePagination();
  const [collectionItemsPagination, setCollectionItemsPagination] = usePagination();
  const { attributes, isAttributesLoading } = useAttributesQuery();
  const { selectedRowKeys, setSelectedRowKeys } = useSelectedKeys({
    tableName: BasketOfferDetailTableName.PRODUCT_OFFERS,
  });
  const {
    selectedRowKeys: collectionsSelectedRowKeys,
    setSelectedRowKeys: setCollectionsSelectedRowKeys,
  } = useSelectedKeys({
    tableName: BasketOfferDetailTableName.COLLECTION_ITEMS,
  });

  const filterFields = useFilterFields({ t, attributes, isAttributesLoading });
  const filters = useFilterParams({
    filterFields,
  });

  const {
    productOffers: collectionProductOffers,
    isFetchingProductOffers: isFetchingCollectionProductOffers,
    totalProductOffers: totalCollectionProductOffers,
  } = usePaginatedProductOffersQuery({
    params: {
      in_collection: collectionId,
      ...getRequestPaginationParams(collectionItemsPagination),
      ...getRequestFiltersParams(omit(filters, 'filter_file')),
    },
    queryOptions: {
      enabled: !isNil(collectionId),
    },
  });

  const collectionProductOfferIds = uniq(reject(map(collectionProductOffers, 'id'), isNil));
  const { collectionItems, isFetchingCollectionItems } = useCollectionItemsQuery({
    params: {
      offer__in: collectionProductOfferIds.join(','),
      collection: collectionId,
      limit: collectionProductOfferIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(collectionProductOfferIds) && !isNil(collectionId),
    },
  });

  const collectionProductIds = uniq(reject(map(collectionProductOffers, 'product'), isNil));
  const { products: collectionProducts, isFetchingProducts: isCollectionProductsFetching } =
    useProductsQuery({
      queryOptions: {
        enabled: !isEmpty(collectionProductIds),
      },
      params: {
        id__in: collectionProductIds?.join(','),
        limit: collectionProductIds?.length,
      },
    });

  const mappedProductOffers = getMappedProductOffers({
    collectionItems,
    collectionProducts,
    productOffers: collectionProductOffers,
  });

  const productOffersDefaultParams = {
    not_in_collection: collectionId,
    catalog_item_id__isnull: false,
    status: OfferStatus.approved,
  };

  const { productOffers, isFetchingProductOffers, totalProductOffers } =
    usePaginatedProductOffersQuery({
      pagination: productPoolPagination,
      filters,
      params: productOffersDefaultParams,
      queryOptions: {
        enabled: !isNil(collectionId),
      },
    });
  const productIdsForOffers = uniq(reject(map(productOffers, 'product'), isNil));
  const { products, isFetchingProducts } = useProductsQuery({
    queryOptions: {
      enabled: !isEmpty(productIdsForOffers),
    },
    params: {
      id__in: productIdsForOffers?.join(','),
      limit: productIdsForOffers?.length,
    },
  });
  const productOffersWithProductDetails = productOffers?.map((productOffer) => ({
    ...productOffer,
    product: products?.find((product) => product.id === productOffer.product),
  }));

  const { deleteProductFromCollectionAsync, isDeletingProductFromCollection } =
    useDeleteProductFromCollectionMutation();

  const { addProductToCollectionAsync, isAddingProductToCollection } =
    useAddProductToCollectionMutation();

  const {
    addAllProductsToCollection,
    isAddingAllProductsToCollectionSuccess,
    isAddingAllProductsToCollectionError,
    addAllProductsToCollectionCacheKey,
    resetAddAllProductsToCollection,
  } = useAddAllProductsToCollectionMutation({ filters, params: productOffersDefaultParams });

  const {
    removeAllProductsToCollection,
    isRemovingAllProductsToCollectionSuccess,
    isRemovingAllProductsToCollectionError,
    removeAllProductsToCollectionCacheKey,
    resetRemoveAllProductsToCollection,
  } = useRemoveAllProductsToCollectionMutation({
    filters,
  });

  const { onAddSelectedItems, onDeleteSelectedItems } = useAddRemoveProductFromCollection({
    productOffersWithProductDetails,
    selectedRowKeys,
    deleteProductFromCollectionAsync,
    addProductToCollectionAsync,
    collectionId,
  });

  const { onAddAllProducts } = useAddAllProductsToCollection({
    addAllProductsToCollection,
    collectionId,
  });

  const { onRemoveAllProducts } = useRemoveAllProductsToCollection({
    removeAllProductsToCollection,
    collectionId,
  });

  const { allStatus: addingAllStatus, allStatusPercentage: addingAllStatusPercentage } =
    useGetProductsToCollectionStatusQuery({
      cacheKey: addAllProductsToCollectionCacheKey,
      collectionId,
    });

  const { allStatus: removingAllStatus, allStatusPercentage: removingAllStatusPercentage } =
    useGetProductsToCollectionStatusQuery({
      cacheKey: removeAllProductsToCollectionCacheKey,
      collectionId,
    });

  const { onShowModal: onShowAddSelectedItemsModal, ...addSelectedItemsBulkActionProps } =
    useBulkAction({
      selectedRowKeys,
      setSelectedRowKeys,
      bulkActionMutate: (params) => {
        onAddSelectedItems(params);
        setSelectedRowKeys([]);
      },
    });

  const { onShowModal: onShowDeleteItemsModal, ...deleteItemsBulkActionProps } = useBulkAction({
    selectedRowKeys: collectionsSelectedRowKeys,
    setSelectedRowKeys: setCollectionsSelectedRowKeys,
    bulkActionMutate: (params) => {
      onDeleteSelectedItems(params);
      setCollectionsSelectedRowKeys([]);
    },
  });

  const productsTableColumns = getProductListColumns({
    t,
    addProductToCollectionAsync,
    collectionId,
    isMobile,
  });
  const collectionProductsTableColumns = getCollectionProductsColumns({
    t,
    deleteProductFromCollectionAsync,
    collectionId,
    isMobile,
  });

  const onShowAddAllProductsModal = () => {
    const title = t('add_all_products_desc');
    Modal.confirm({
      title,
      onOk() {
        onAddAllProducts();
      },
      okText: t('modal_ok'),
      cancelText: t('modal_cancel'),
    });
  };

  const onShowRemoveAllProductsModal = () => {
    const title = t('remove_all_products_desc');
    Modal.confirm({
      title,
      onOk() {
        onRemoveAllProducts();
      },
    });
  };

  const tableActions = getTableActions({
    t,
    onShowAddSelectedItemsModal,
    onShowAddAllProductsModal,
    selectedRowKeys,
    totalProductOffers,
  });
  const collectionProductsTableActions = getCollectionProductsTableActions({
    t,
    onShowDeleteItemsModal,
    onShowRemoveAllProductsModal,
    selectedRowKeys: collectionsSelectedRowKeys,
    totalCollectionProductOffers,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.offerList.approvedDetail, {
        offerId: record.id,
      });
    },
  });

  return (
    <div className="collection-detail">
      <BulkActionModal
        {...addSelectedItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_add_selected_items'),
          description: t('Marketing:bulk_add_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_add_selected_items') }}
      />
      <BulkActionModal
        {...deleteItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_delete_selected_items'),
          description: t('Marketing:bulk_delete_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_delete_selected_items') }}
      />
      <BulkActionProgressModal
        title={t('adding_products')}
        cacheKey={addAllProductsToCollectionCacheKey}
        open={isAddingAllProductsToCollectionSuccess && !isAddingAllProductsToCollectionError}
        onCancel={() => {
          resetAddAllProductsToCollection();
        }}
        progressStatus={addingAllStatus}
        progressPercentage={addingAllStatusPercentage}
        succeededCountTransKey="add_all_products_to_collection_succeeded_count"
        failedCountTransKey="add_all_products_to_collection_failed_count"
      />
      <BulkActionProgressModal
        title={t('removing_products')}
        cacheKey={removeAllProductsToCollectionCacheKey}
        open={isRemovingAllProductsToCollectionSuccess && !isRemovingAllProductsToCollectionError}
        onCancel={() => {
          resetRemoveAllProductsToCollection();
        }}
        progressStatus={removingAllStatus}
        progressPercentage={removingAllStatusPercentage}
        succeededCountTransKey="remove_all_products_from_collection_succeeded_count"
        failedCountTransKey="remove_all_products_from_collection_failed_count"
      />
      <div className="flex gap-4 flex-col">
        <Row>
          <Col span={24}>
            <AkinonFilter
              title={t('filters')}
              filterFields={filterFields}
              total={totalProductOffers}
              showFileImport
            />
          </Col>
        </Row>
        <Row span={24} gutter={16}>
          <Col span={12}>
            <AkinonTable
              tableName={BasketOfferDetailTableName.PRODUCT_OFFERS}
              title={t('ProductsAndCategories:offer.list')}
              columns={productsTableColumns}
              dataSource={productOffersWithProductDetails}
              optionsUrl={getProductOffersUrl}
              loading={
                isFetchingProductOffers ||
                isFetchingProducts ||
                isDeletingProductFromCollection ||
                isAddingProductToCollection
              }
              total={totalProductOffers}
              rowKey={defaultRowKey}
              pagination={productPoolPagination}
              setPagination={setProductPoolPagination}
              tableActions={tableActions}
              rowSelection={true}
              description={`${totalProductOffers || 0} ${t('results.found')}`}
              onRow={onRow}
            />
          </Col>
          <Col span={12}>
            <AkinonTable
              tableName={BasketOfferDetailTableName.COLLECTION_ITEMS}
              title={collectionDetail?.name || t('collection_items')}
              columns={collectionProductsTableColumns}
              dataSource={mappedProductOffers}
              optionsUrl={getProductOffersUrl}
              loading={
                isCollectionProductsFetching ||
                isFetchingCollectionItems ||
                isFetchingCollectionProductOffers ||
                isDeletingProductFromCollection ||
                isAddingProductToCollection
              }
              total={totalCollectionProductOffers}
              rowKey={defaultRowKey}
              pagination={collectionItemsPagination}
              setPagination={setCollectionItemsPagination}
              tableActions={collectionProductsTableActions}
              rowSelection={true}
              description={`${totalCollectionProductOffers || 0} ${t('results.found')}`}
              onRow={onRow}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductCollections;
