import { getCancelReasonUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useCancellationReasonOptionsQuery({ queryOptions, cancellationReasonId }) {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.CANCELLATION_REASON_OPTIONS, cancellationReasonId],
    queryFn: () => HTTP.options(getCancelReasonUrl, { urlParams: { cancellationReasonId } }),
    ...queryOptions,
  });

  return {
    cancellationReasonOptions: data,
    isFetchingCancellationReasonOptions: isFetching,
  };
}
