import {
  getReconciliationDetailURL,
  getReconciliationItemDetailURL,
  getReconciliationsURL,
  postReconciliationStatusURL,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getReconciliations = (params) => {
  return HTTP.get(getReconciliationsURL, { params });
};

export const getReconciliationDetail = (reconciliationId) => {
  return HTTP.get(getReconciliationDetailURL, { urlParams: { reconciliationId } });
};

export const getReconciliationItems = (params, reconciliationId) => {
  return HTTP.get(getReconciliationItemDetailURL, {
    params,
    urlParams: { reconciliationId },
  });
};

export const postReconciliationStatus = (reconciliationId, status) => {
  return HTTP.post(
    postReconciliationStatusURL,
    {
      status,
    },
    {
      urlParams: { reconciliationId },
    }
  );
};
