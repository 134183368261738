import { getDataSourcesUrl } from '@constants/apiUrls';
import { fetcher } from '@services/client';
import useSWR from 'swr';

export const useDataSources = ({ collections }) => {
  const dataSourceIds = collections?.map(({ datasource }) => datasource);

  const swrResponse = useSWR(
    [
      getDataSourcesUrl,
      {
        params: {
          limit: dataSourceIds?.length,
          id__in: dataSourceIds?.join(','),
        },
      },
    ],
    fetcher
  );

  return {
    ...swrResponse,
    dataSources: swrResponse.data?.results,
    isDataSourcesLoading: swrResponse.isLoading,
  };
};
