import { postProductCollectionWidgetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

export function usePostProductCollectionWidgetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => HTTP.post(postProductCollectionWidgetUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postProductCollectionWidget: mutate,
    isPostingProductCollectionWidget: isLoading,
  };
}
