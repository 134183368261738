import { getUserDetailURL } from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getUserDetail = (userId, requestConfig) => {
  return HTTP.get(getUserDetailURL, {
    ...requestConfig,
    urlParams: {
      userId,
    },
  });
};
