import { formatDateTime } from '@common/dateTime';
import { Typography } from 'antd';
import last from 'lodash/last';
import React from 'react';

import { stripHtmlTags } from '../common';

const { Title, Text } = Typography;

export const DataIndex = {
  LAST_MESSAGE_DATE: 'last_message_date',
  MESSAGE_SET: 'message_set',
  SELLER_NAME: ['datasource', 'name'],
  CUSTOMER_NAME: ['user', 'first_name'],
  CUSTOMER_SURNAME: ['user', 'last_name'],
  IS_PUBLIC: 'is_public',
};

export const StaticFilterKey = {
  BASE_CODE: 'product_base_code',
  FIRST_CREATE_MESSAGE_DATE: 'created_date__gte',
  LAST_CREATE_MESSAGE_DATE: 'created_date__lte',
  FIRST_UPDATE_MESSAGE_DATE: 'updated_date__gte',
  LAST_UPDATE_MESSAGE_DATE: 'updated_date__lte',
  IS_PUBLIC: 'is_public',
};

export const getColumns = ({ t, isSuperUser }) => {
  return [
    isSuperUser && {
      dataIndex: DataIndex.SELLER_NAME,
      title: t('seller.name'),
    },
    {
      dataIndex: DataIndex.CUSTOMER_NAME,
      title: t('customer.name'),
    },
    {
      dataIndex: DataIndex.CUSTOMER_SURNAME,
      title: t('customer.last.name'),
    },
    {
      dataIndex: DataIndex.MESSAGE_SET,
      title: t('customer.message'),
      width: 300,
      render: (messageSet) => stripHtmlTags(last(messageSet)?.message_content),
    },
    {
      dataIndex: DataIndex.IS_PUBLIC,
      visibleOnFilter: [StaticFilterKey.IS_PUBLIC],
      title: t('messagesConfig.messageState'),
      width: 100,
      render: (isPublic) => (isPublic ? t('messagesConfig.public') : t('messagesConfig.private')),
    },
    {
      dataIndex: DataIndex.LAST_MESSAGE_DATE,
      visibleOnFilter: [
        StaticFilterKey.FIRST_CREATE_MESSAGE_DATE,
        StaticFilterKey.LAST_CREATE_MESSAGE_DATE,
        StaticFilterKey.FIRST_UPDATE_MESSAGE_DATE,
        StaticFilterKey.LAST_UPDATE_MESSAGE_DATE,
      ],
      title: t('date'),
      render: (last_message_date) => formatDateTime(last_message_date),
      width: 100,
    },
  ];
};

export const getTableActions = ({
  t,
  isSuperUser,
  onShowMakePublicModal,
  onShowMakePrivateModal,
}) => {
  if (!isSuperUser) {
    return [];
  }

  return [
    {
      label: t('messagesConfig.makeSelectedMessagesPublic'),
      onTableAction: () => onShowMakePublicModal(),
      isSelectionRequired: true,
    },
    {
      label: t('messagesConfig.makeSelectedMessagesPrivate'),
      onTableAction: () => onShowMakePrivateModal(),
      isSelectionRequired: true,
    },
  ];
};

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header',
      content: (
        <>
          <Title level={4}>{t('product_messages_tutorial.table_title')}</Title>
          <Text>{t('product_messages_tutorial.table')}</Text>
        </>
      ),
    },
    {
      selector: '.filter-options__filter-modal-open-button',
      content: (
        <>
          <Title level={4}>{t('product_messages_tutorial.dynamic_filters_title')}</Title>
          <Text>{t('product_messages_tutorial.dynamic_filters')}</Text>
        </>
      ),
    },
  ];
};
