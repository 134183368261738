import '../style.scss';

import { getRequestFiltersParams } from '@common/index';
import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { exportOffersUrl, getProductOffersUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension, HttpMethod } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { TableName } from '@constants/table';
import { useIsMobile } from '@hooks/useIsMobile';
import useModalAction from '@hooks/useModalAction';
import useSelectedKeys from '@hooks/useSelectedKeys';
import BulkUpdateOfferStatus from '@pages/ProductsAndCategories/BulkActions/components/BulkUpdateOfferStatus';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useExportOffersMutation } from '@services/api/hooks/useExportOffersMutation';
import { queryClient } from '@services/api/queryClient';
import { HTTP } from '@services/client';
import { useIsFetching } from '@tanstack/react-query';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import usePageData from '@zustand-store/hooks/usePageData';
import { Modal } from 'antd';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import { usePostBulkStatusUpdateMutation } from '../hooks/api/usePostBulkStatusUpdateMutation';
import { useApprovedOffersBreadcrumbs } from '../hooks/useApprovedOffersBreadcrumbs';
import { useApprovedOffersPageMeta } from '../hooks/useApprovedOffersPageMeta';
import { getColumns, getTableActions, OfferStatus } from './common';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

export default function OfferListApproved() {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const user = useUser();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { selectedRowKeys, clearSelectedRowKeys } = useSelectedKeys({
    tableName: TableName.APPROVED_OFFERS,
  });
  const [isBulkUpdateOfferStatusActive, toggleIsBulkUpdateOfferStatusActive] = useBoolean(false);
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];

  const { postBulkStatusUpdate } = usePostBulkStatusUpdateMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
        clearSelectedRowKeys();
      },
    },
  });
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });

  const { attributes, isAttributesLoading } = useAttributesQuery();
  const filterFields = useFilterFields({
    t,
    attributes,
    isAttributesLoading,
    isSuperUser,
    categoryTreeData,
    isCategoriesLoading,
  });

  const filters = useFilterParams({
    filterFields,
  });

  const hasAppliedFilters = Object.values(filters).filter((val) => !isUndefined(val)).length > 0;

  const onExportSuccess = () => {
    openNotification({
      message: t('file.preparing'),
      description: t('file.download.modal.description'),
    });
  };

  const { exportOffers, isExportingOffers } = useExportOffersMutation({
    mutationOptions: {
      onSuccess: onExportSuccess,
    },
  });

  useApprovedOffersBreadcrumbs();
  useApprovedOffersPageMeta({ toggleIsBulkUpdateOfferStatusActive });

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const { data, isLoading, total } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });
  const columns = getColumns({ t, isMobile });

  const isExportOffersFetching = useIsFetching({ queryKey: [QueryKey.EXPORT_PRODUCT_OFFERS] });

  const onXlsxExport = async ({ fieldsToExport }) => {
    if (filters.filter_file) {
      exportOffers({ requestBody: { ...filters, extension: FileExtension.XLSX } });
    } else {
      const params = {
        ...filters,
        ...(fieldsToExport &&
          QueryParamsBuilder.new()
            .set('extension', FileExtension.XLSX)
            .getByFields(fieldsToExport)
            .build()),
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRODUCT_OFFERS, params],
        queryFn: async () => {
          await HTTP.get(exportOffersUrl, {
            params,
          });
          onExportSuccess();
        },
      });
    }
  };

  const onExport = async ({ exportFormat, fieldsToExport }) => {
    if (exportFormat === ExportFormat.XLSX) {
      let requestConfig = {
        params: QueryParamsBuilder.new()
          .set('extension', FileExtension.XLSX)
          .getByFields(fieldsToExport)
          .build(),
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRE_OFFERS, requestConfig, filters],
        queryFn: async () => {
          const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
          const filterParams = getRequestFiltersParams(filters);
          delete filterParams?.filter_file;
          requestConfig = {
            ...requestConfig,
            params: {
              ...requestConfig?.params,
              ...filterParams,
            },
          };

          let response;
          if (fileFilter) {
            const formData = new FormData();
            formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
            response = await HTTP.post(exportOffersUrl, formData, {
              ...requestConfig,
              headers: {
                'Origin-Method': HttpMethod.GET,
                ...requestConfig?.headers,
              },
            });
          } else {
            response = await HTTP.get(exportOffersUrl, requestConfig);
          }

          return response;
        },
      });
      openNotification({
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
      });
    }
  };

  const { onShowModal: onShowMakeOfferActiveModal, ...bulkMakeOfferActiveProps } = useModalAction({
    mutateFn: () =>
      postBulkStatusUpdate({
        datasource: user.datasource,
        requestBody: {
          status: OfferStatus.ACTIVE,
        },
        filters: {
          id__in: selectedRowKeys.join(','),
        },
      }),
    enforceRowSelection: true,
    selectedRowKeys,
  });
  const { onShowModal: onShowMakeOfferPassiveModal, ...bulkMakeOfferPassiveProps } = useModalAction(
    {
      mutateFn: () =>
        postBulkStatusUpdate({
          datasource: user.datasource,
          requestBody: {
            status: OfferStatus.PASSIVE,
          },
          filters: {
            id__in: selectedRowKeys.join(','),
          },
        }),
      enforceRowSelection: true,
      selectedRowKeys,
    }
  );
  const {
    onShowModal: onShowMakeFilteredOffersActiveModal,
    ...makeFilteredOffersActiveModalProps
  } = useModalAction({
    mutateFn: () =>
      postBulkStatusUpdate({
        datasource: user.datasource,
        requestBody: {
          status: OfferStatus.ACTIVE,
        },
        filters: {
          ...omitBy(filters, isUndefined),
          ...(selectedRowKeys?.length > 1 && { id__in: selectedRowKeys.join(',') }),
        },
      }),
  });

  const { onShowModal: onShowMakeFilteredOffersPassiveModal, ...makeFilteredOffersPassiveProps } =
    useModalAction({
      mutateFn: () =>
        postBulkStatusUpdate({
          datasource: user.datasource,
          requestBody: {
            status: OfferStatus.PASSIVE,
          },
          filters: {
            ...omitBy(filters, isUndefined),
            ...(selectedRowKeys?.length > 1 && {
              id__in: selectedRowKeys.join(','),
            }),
          },
        }),
    });

  const tableActions = getTableActions({
    t,
    onShowMakeOfferActiveModal,
    onShowMakeOfferPassiveModal,
    onShowMakeFilteredOffersActiveModal,
    onShowMakeFilteredOffersPassiveModal,
    hasAppliedFilters,
    selectedRowKeys,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.offerList.approvedDetail, {
        offerId: record.id,
      });
    },
  });

  return (
    <section className="offer-list">
      <Show when={isBulkUpdateOfferStatusActive} animateProps={Animations.fade}>
        <BulkUpdateOfferStatus
          downloadBulkProductOffersTemplate={() => onXlsxExport({ fieldsToExport: null })}
          isDownloadBulkProductOffersTemplateFetching={isExportOffersFetching || isExportingOffers}
        />
      </Show>
      <AkinonFilter title={t('filter')} filterFields={filterFields} showFileImport total={total} />
      <div className="offer-list__table">
        <AkinonTable
          tableName={TableName.APPROVED_OFFERS}
          title={t('offer.list')}
          columns={columns}
          dataSource={data}
          optionsUrl={getProductOffersUrl}
          loading={isLoading}
          total={total}
          rowKey={defaultRowKey}
          exportFormats={[ExportFormat.XLSX]}
          tableActions={tableActions}
          pagination={pagination}
          setPagination={setPagination}
          description={`${total} ${t('results.found')}`}
          onRow={onRow}
          enableDynamicColumns
          onExport={onExport}
        />
      </div>

      <Modal width={800} title={t('bulk.make.offers.active')} {...bulkMakeOfferActiveProps}>
        <div>
          {t('bulk.make.offers.active.desc', {
            count: selectedRowKeys?.length,
          })}
        </div>
      </Modal>
      <Modal width={800} title={t('bulk.make.offers.passive')} {...bulkMakeOfferPassiveProps}>
        <div>
          {t('bulk.make.offers.passive.desc', {
            count: selectedRowKeys?.length,
          })}
        </div>
      </Modal>
      <Modal
        width={800}
        title={
          selectedRowKeys?.length > 0
            ? t('make_filtered_offers_active')
            : t('make_all_offers_active')
        }
        {...makeFilteredOffersActiveModalProps}
      >
        <div>
          {selectedRowKeys?.length > 0
            ? t('make_filtered_offers_active_desc')
            : t('make_all_offers_active_desc')}
        </div>
      </Modal>
      <Modal
        width={800}
        title={
          selectedRowKeys?.length > 0
            ? t('make_filtered_offers_passive')
            : t('make_all_offers_passive')
        }
        {...makeFilteredOffersPassiveProps}
      >
        <div>
          {selectedRowKeys?.length > 0
            ? t('make_filtered_offers_passive_desc')
            : t('make_all_offers_passive_desc')}
        </div>
      </Modal>
    </section>
  );
}
