import { postDataSourceShippingCompanyOptionConfigUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostDataSourceShippingCompanyOptionConfigMutation() {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postDataSourceShippingCompanyOptionConfigUrl, requestBody, requestConfig),
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIGS]);
    },
  });

  return {
    createDataSourceShippingCompanyOptionConfig: mutate,
    isCreatingDataSourceShippingCompanyOptionConfig: isLoading,
  };
}
