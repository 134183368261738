import { exportProductPricesUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useDownloadBulkProductPricesTemplateQuery({
  queryOptions,
  params,
  onSuccess,
} = {}) {
  const query = useAkinonQuery({
    queryKey: ['downloadBulkProductPricesTemplate', params],
    queryFn: async () => {
      const result = await HTTP.get(exportProductPricesUrl, {
        params,
      });
      onSuccess?.(result);
      return result;
    },
    enabled: false,
    ...queryOptions,
  });

  return {
    ...query,
    downloadBulkProductPricesTemplate: query.refetch,
    isDownloadBulkProductPricesTemplateFetching: query.isFetching,
  };
}
