import { getOrderDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useOrderDetailQuery({ orderId, queryOptions, onSuccess }) {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.ORDER_DETAIL, orderId],
    async queryFn() {
      const response = await HTTP.get(getOrderDetailUrl, {
        urlParams: { orderId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    orderDetail: data,
    isOrderDetailLoading: isLoading,
    orderDetailError: error,
  };
}
