import { getPackagesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const usePackageStatusesQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PACKAGE_STATUSES, params],
    async queryFn() {
      const response = await HTTP.options(getPackagesUrl, { params });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    packageStatuses: data,
    isPackageStatusesLoading: isLoading,
    packageStatusesError: error,
  };
};
