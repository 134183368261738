import { deletePreOfferImageUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { queryClient } from '@services/api/queryClient';

import { HTTP } from '../../client';

export function useDeletePreOfferImageMutation(mutationOptions = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ imageId }) => HTTP.delete(deletePreOfferImageUrl, { urlParams: { imageId } }),
    onSuccess() {
      mutationOptions.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.PRE_OFFER_IMAGES]);
    },
    ...mutationOptions,
  });

  return {
    deletePreOfferImage: mutate,
    isDeletingPreOfferImage: isLoading,
  };
}
