import { dataSourceLandingPagesUrl, dataSourceLandingPageUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useDataSourceLandingPagesQuery({ queryOptions, params } = {}) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCE_LANDING_PAGES, params],
    queryFn: () => HTTP.get(dataSourceLandingPagesUrl, { params }),
    ...queryOptions,
  });

  return {
    dataSourceLandingPages: data?.results,
    dataSourceLandingPagesTotal: data?.count,
    isDataSourceLandingPagesLoading: isLoading,
    isDataSourceLandingPagesFetching: isFetching,
  };
}
export function useDataSourceLandingPageQuery({
  queryOptions,
  params,
  dataSourceLandingPageId,
} = {}) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCE_LANDING_PAGE, dataSourceLandingPageId, params],
    queryFn: () =>
      HTTP.get(dataSourceLandingPageUrl, {
        params,
        urlParams: { dataSourceLandingPageId },
      }),
    ...queryOptions,
  });

  return {
    dataSourceLandingPage: data,
    isDataSourceLandingPageLoading: isLoading,
    isDataSourceLandingPageFetching: isFetching,
  };
}
