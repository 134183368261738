import { getRequestFiltersParams } from '@common/index';
import { postBulkStatusUpdateUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';
import omit from 'lodash/omit';

export function usePostBulkStatusUpdateMutation({ mutationOptions } = {}) {
  const { mutate: postBulkStatusUpdate, isLoading: isPostingBulkStatusUpdate } = useAkinonMutation({
    mutationFn: ({ requestBody, datasource, filters }) => {
      const { filter_file: filterFile } = filters ?? {};
      if (filterFile) {
        const formData = new FormData();
        formData.append('filter_file', new Blob([filterFile.originFileObj]), filterFile.name);
        formData.append('status', requestBody.status);

        return HTTP.post(postBulkStatusUpdateUrl, formData, {
          params: { datasource, ...getRequestFiltersParams(omit(filters, 'filter_file')) },
        });
      } else {
        return HTTP.post(postBulkStatusUpdateUrl, requestBody, {
          params: { datasource, ...getRequestFiltersParams(filters) },
        });
      }
    },
    ...mutationOptions,
  });

  return {
    postBulkStatusUpdate,
    isPostingBulkStatusUpdate,
  };
}
