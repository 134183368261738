import { QueryKey } from '@constants/query';
import { getAttributeSet } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useAttributeSetQuery({ attributeSetId, queryOptions }) {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.ATTRIBUTE_SET, { attributeSetId }],
    queryFn: () => getAttributeSet({ attributeSetId }),
    ...queryOptions,
  });

  return {
    attributeSet: data,
    isAttributeSetFetching: isFetching,
  };
}
