import { postAgreementConsentsUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePostAgreementConsentsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postAgreementConsentsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    addAgreementConsent: mutateAsync,
    isAgreementConsentAdding: isLoading,
  };
}
