import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import useBulkAction from '@hooks/useBulkAction';
import { useIsMobile } from '@hooks/useIsMobile';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { TranslationKey } from '@root/i18n';
import { usePaginatedProductOffersQuery } from '@services/api/hooks';
import { useCollectionItemsQuery } from '@services/api/hooks/useCollectionItemsQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

import {
  CampaignDetailTableName,
  getCollectionProductsColumns,
  getCollectionProductsTableActions,
  getMappedProductOffers,
} from '../common';
import useRemoveProductFromCollection from '../hooks/useRemoveProductFromCollection';

const CampaignProducts = ({
  collectionId,
  collectionDetail,
  filters,
  deleteProductFromCollectionAsync,
  onShowRemoveAllProductsModal,
  onRow,
  isAddingProductToCollection,
  isDeletingProductFromCollection,
}) => {
  const { t } = useTranslation([TranslationKey.PRODUCTS_AND_CATEGORIES, TranslationKey.MARKETING]);
  const isMobile = useIsMobile();

  const [pagination, setPagination] = usePagination();
  const { selectedRowKeys, setSelectedRowKeys, getRowSelection } = useSelectedKeys({
    tableName: CampaignDetailTableName.COLLECTION_PRODUCTS,
  });

  const { productOffers, totalProductOffers } = usePaginatedProductOffersQuery({
    pagination,
    filters,
    params: {
      in_collection: collectionId,
    },
    queryOptions: {
      enabled: !isNil(collectionId),
    },
  });

  const productIds = map(productOffers, 'product') ?? [];

  const { products: collectionProducts, isCollectionProductsFetching } = useProductsQuery({
    params: {
      limit: productIds?.length,
      id__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: productIds?.length > 0,
    },
  });

  const collectionProductOfferIds = uniq(reject(map(productOffers, 'id'), isNil));
  const { collectionItems, isFetchingCollectionItems } = useCollectionItemsQuery({
    queryOptions: {
      enabled: !isEmpty(collectionProductOfferIds) && !isNil(collectionId),
    },
    params: {
      offer__in: collectionProductOfferIds.join(','),
      limit: collectionProductOfferIds.length,
      collection: collectionId,
    },
  });

  const mappedProductOffers = getMappedProductOffers({
    collectionItems,
    collectionProducts,
    productOffers,
  });

  const { onDeleteSelectedItems } = useRemoveProductFromCollection({
    deleteProductFromCollectionAsync,
    mappedProductOffers,
    selectedRowKeys,
  });

  const { onShowModal: onShowDeleteItemsModal, ...deleteItemsBulkActionProps } = useBulkAction({
    selectedRowKeys,
    setSelectedRowKeys,
    bulkActionMutate: onDeleteSelectedItems,
  });

  const collectionProductsTableColumns = getCollectionProductsColumns({
    t,
    deleteProductFromCollectionAsync,
    isMobile,
  });

  const collectionProductsTableActions = getCollectionProductsTableActions({
    t,
    onShowDeleteItemsModal,
    onShowRemoveAllProductsModal,
    selectedRowKeys,
    totalProductOffers,
  });

  return (
    <>
      <BulkActionModal
        {...deleteItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_delete_selected_items'),
          description: t('Marketing:bulk_delete_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_delete_selected_items') }}
      />
      <AkinonTable
        tableName={CampaignDetailTableName.COLLECTION_PRODUCTS}
        title={collectionDetail?.name || t('collection_items')}
        columns={collectionProductsTableColumns}
        dataSource={mappedProductOffers}
        optionsUrl={getProductOffersUrl}
        loading={
          isCollectionProductsFetching ||
          isFetchingCollectionItems ||
          isDeletingProductFromCollection ||
          isAddingProductToCollection
        }
        total={totalProductOffers}
        rowKey={defaultRowKey}
        pagination={pagination}
        setPagination={setPagination}
        tableActions={collectionProductsTableActions}
        rowSelection={getRowSelection()}
        description={`${totalProductOffers || 0} ${t('results.found')}`}
        onRow={onRow}
      />
    </>
  );
};

export default CampaignProducts;
