import { rejectPreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { HTTP } from '@services/client';
import filter from 'lodash/filter';

import { PreOfferStatus } from '../../common';
import { FormKey } from '../../OfferDetail/components/RejectModal/common';

const useRejectOffers = ({
  selectedRowKeys,
  pendingOffers,
  refetchOffers,
  limitPerBulkRequest = 1,
}) => {
  const rejectSelectedItems = async ({
    [FormKey.REJECTION_REASON]: rejectionReason,
    [FormKey.REJECTION_EXPLANATION]: rejectionExplanation,
    mutationOptions,
  }) => {
    const { onSuccess, onError } = mutationOptions;

    const approvalRequiredSelectedRows = filter(pendingOffers, (offer) => {
      return (
        selectedRowKeys.includes(offer.id) &&
        offer.approval_status === PreOfferStatus.APPROVAL_REQUIRED
      );
    });

    const approvalRequiredCount = approvalRequiredSelectedRows.length;

    try {
      if (approvalRequiredCount > 0) {
        const bulkRequestSize = Math.ceil(approvalRequiredCount / limitPerBulkRequest);

        for (let i = 0; i < bulkRequestSize; i++) {
          const start = i * limitPerBulkRequest;
          const end = (i + 1) * limitPerBulkRequest;
          const bulkApprovalRequiredSelectedRows = approvalRequiredSelectedRows.slice(start, end);

          const approvePreOfferPromises = bulkApprovalRequiredSelectedRows.map((row) => {
            return HTTP.post(submitPreOfferURL, {}, { urlParams: { id: row.id } });
          });

          await Promise.allSettled(approvePreOfferPromises);
        }
      }

      const approvalCount = selectedRowKeys.length;

      if (approvalCount > 0) {
        let result = [];
        const bulkRequestSize = Math.ceil(approvalCount / limitPerBulkRequest);
        for (let i = 0; i < bulkRequestSize; i++) {
          const start = i * limitPerBulkRequest;
          const end = (i + 1) * limitPerBulkRequest;
          const bulkSelectedRowKeys = selectedRowKeys.slice(start, end);

          const rejectionPromises = bulkSelectedRowKeys.map((key) => {
            return HTTP.post(
              rejectPreOfferURL,
              {
                reason: rejectionReason,
                explanation: rejectionExplanation,
              },
              { urlParams: { id: key } }
            );
          });

          const bulkResult = await Promise.allSettled(rejectionPromises);
          const errorCount = filter(bulkResult, { status: 'rejected' }).length;
          const successCount = filter(bulkResult, { status: 'fulfilled' }).length;
          onError(errorCount);
          onSuccess(successCount);
          result = [...result, ...bulkResult];
        }
        const isAllFulfilled = result.every((item) => item.status === 'fulfilled');
        const isSomeRejected = result.some((item) => item.status === 'rejected');
        const isSomeFulfilled = result.some((item) => item.status === 'fulfilled');
        if (isAllFulfilled || (isSomeFulfilled && isSomeRejected)) {
          refetchOffers();
        }
      }
    } catch (error) {}
  };

  return {
    rejectSelectedItems,
  };
};

export default useRejectOffers;
