import { postDataSourceCreateUserUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export const useDataSourceCreateUser = ({ mutationOptions }) => {
  const { data, mutate, isLoading, isError, ...rest } = useAkinonMutation({
    mutationFn: ({ params, requestConfig }) =>
      HTTP.post(postDataSourceCreateUserUrl, params, requestConfig),
    ...mutationOptions,
  });

  return {
    ...rest,
    data,
    mutate,
    isError,
    isLoading,
  };
};
