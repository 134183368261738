import { useRejectCancellationMutation } from '@services/hooks/orders/useRejectCancellationMutation';

const useBulkRejectRefundRequests = ({ onSettled, dataSource, selectedRowKeys }) => {
  const { mutateAsync: rejectCancellation } = useRejectCancellationMutation();
  const onRejectRefundRequests = async ({ mutationOptions }) => {
    const selectedRows = dataSource.filter((row) => selectedRowKeys.includes(row?.id));
    for (const row of selectedRows) {
      try {
        await rejectCancellation(
          {
            requestId: row.cancellation_id,
          },
          mutationOptions
        );
      } catch (error) {
        continue;
      }

      onSettled();
    }
  };

  return { onRejectRefundRequests };
};

export default useBulkRejectRefundRequests;
