import { getAttributesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useProductAttributesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_ATTRIBUTES, params],
    async queryFn() {
      const response = await HTTP.get(getAttributesUrl, {
        params: {
          is_active: true,
          ...params,
        },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    productAttributes: data?.results,
  };
}
