import { attributeSetUrl, getAttributeConfigsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { HTTP } from '@services/client';

export const getAttributeConfigs = async ({ attributeSetId }) => {
  return HTTP.get(getAttributeConfigsUrl, {
    params: {
      attribute_set: attributeSetId,
      ...limitQuery,
    },
  });
};

export const getAttributeSet = async ({ attributeSetId }) => {
  return HTTP.get(attributeSetUrl, {
    urlParams: { attributeSetId },
  });
};
