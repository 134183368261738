import { getReconciliationsRebateRulesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useReconciliationRebateRulesQuery({ params, queryOptions } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.RECONCILIATION_REBATE_RULES, params],
    queryFn: () => HTTP.get(getReconciliationsRebateRulesUrl, { params: { ...params } }),
    ...queryOptions,
  });

  return {
    reconciliationRebateRules: data?.results,
    totalReconciliationRebateRules: data?.count,
    isFetchingReconciliationRebateRules: isFetching,
    isReconciliationRebateRulesLoading: isLoading,
    reconciliationRebateRulesError: error,
  };
}
