import { getCountriesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCountriesQuery({ queryOptions, params } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.COUNTRIES, params],
    queryFn: () =>
      HTTP.get(getCountriesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    countries: data?.results,
    isCountriesFetching: isFetching,
  };
}
