import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getRestrictedProductsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const usePaginatedRestrictedProductsQuery = ({
  queryOptions,
  pagination,
  filters,
  onSuccess,
  params,
  onError,
}) => {
  const { data, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.RESTRICTED_PRODUCTS, pagination, filters, params],
    async queryFn() {
      try {
        const queryParams = {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        };

        const response = await HTTP.get(getRestrictedProductsUrl, { params: queryParams });
        onSuccess?.(response);
        return response;
      } catch (error) {
        onError?.(error);
      }
    },
    ...queryOptions,
  });

  return {
    restrictedProductsResponse: data,
    restrictedProductDetails: data?.results,
    isFetchingRestrictedProductDetails: isFetching,
    totalRestrictedProducts: data?.count,
    refetchRestrictedProductDetails: refetch,
  };
};
