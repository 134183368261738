import './styles.scss';

import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { getProductStockListUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import BulkUpdateStocks from '@pages/ProductsAndCategories/BulkActions/components/BulkUpdateStocks';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import { useTour } from '@reactour/tour';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useDownloadBulkProductStocksTemplateQuery } from '@services/api/hooks/useDownloadBulkProductStocksTemplateQuery';
import { usePatchProductStockMutation } from '@services/api/hooks/usePatchProductStockMutation';
import { useExportProductStocksQuery } from '@services/hooks/products/useExportProductStocksQuery';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import { useUserRole } from '@utils/hooks/useUserRole';
import usePageData from '@zustand-store/hooks/usePageData';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';

import { ExportOption, getColumns, getTourSteps } from './common';
import EditableCell from './components/EditableCell';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const StockDetail = () => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const [form] = Form.useForm();
  const [pagination, setPagination] = usePagination();
  const { attributes, isAttributesLoading } = useAttributesQuery();
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];

  const filterFields = useFilterFields({ t, attributes, isAttributesLoading });

  const filters = useFilterParams({
    filterFields,
  });

  const { stockId } = useParams();
  const [editingKey, setEditingKey] = useState('');
  const [isBulkUpdateProductStocksActive, toggleIsBulkUpdateProductStocksActive] =
    useBoolean(false);
  const { setSteps } = useTour();

  useEffect(() => {
    setSteps(getTourSteps({ t }));
  }, [t]);

  const { refetch: exportProductStocksAsXlsx, isFetching: isFetchingExportProductStocks } =
    useExportProductStocksQuery({
      requestConfig: { params: { stock_list: stockId, extension: FileExtension.XLSX } },
      filters,
    });
  const { updateProductStockPartially } = usePatchProductStockMutation({
    mutationOptions: {
      onSuccess: () => {
        handleCancelEditing();
      },
    },
  });

  useBreadcrumbs();
  usePageMeta({ toggleIsBulkUpdateProductStocksActive });
  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const handleEditRow = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleCancelEditing = () => {
    setEditingKey('');
  };

  const handleSaveRow = async (record) => {
    try {
      const formValues = await form.validateFields();
      updateProductStockPartially({
        stockId: record.id,
        requestBody: {
          ...formValues,
        },
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('stock_detail_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('stock_detail_message.error'),
        },
      });
    } catch {}
  };

  const isEditing = (record) => record.id === editingKey;
  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });
  const { data, total, isLoading } = useDataSource({
    pagination,
    filters,
    stockId,
    dynamicFormMetaFields,
  });

  const columns = getColumns({
    t,
    i18n,
    updateProductStockPartially,
    isEditing,
    editingKey,
    handleEditRow,
    handleCancelEditing,
    handleSaveRow,
    isSuperUser,
    isMobile,
  });

  const {
    downloadBulkProductStocksTemplate: onExportBulkProductStocksTemplate,
    isDownloadBulkProductStocksTemplateFetching,
  } = useDownloadBulkProductStocksTemplateQuery({
    params: {
      fields: 'sku,price,retail_price,tax_rate',
      limit: 10,
      stock_list: stockId,
      extension: FileExtension.XLSX,
    },
    onSuccess: () => {
      openNotification({
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
        t,
      });
    },
  });

  const onExport = ({ exportFormat, exportOption }) => {
    if (exportFormat === ExportFormat.XLSX) {
      switch (exportOption) {
        case ExportOption.EXPORT_STOCK_LIST_TEMPLATE:
          onExportBulkProductStocksTemplate();
          break;
        case ExportOption.EXPORT_STOCK_LIST:
          exportProductStocksAsXlsx();
          break;
      }
    }
  };

  return (
    <section className="stock-detail">
      <Show when={isBulkUpdateProductStocksActive} animateProps={Animations.fade}>
        <BulkUpdateStocks />
      </Show>
      <AkinonFilter
        title={t('filter')}
        filterFields={filterFields}
        showFileImport
        total={total}
        className="mb-8"
      />
      <Form form={form} component={false}>
        <AkinonTable
          title={t('product.pool')}
          rowKey={defaultRowKey}
          loading={isLoading}
          columns={columns}
          appendDynamicColumnsAt={-2}
          enableDynamicColumns
          dataSource={data}
          optionsUrl={getProductStockListUrl}
          pagination={pagination}
          setPagination={setPagination}
          tableActions={[]}
          exportFormats={[ExportFormat.XLSX]}
          exportOptions={[
            {
              label: t('export_stock_list_template'),
              value: ExportOption.EXPORT_STOCK_LIST_TEMPLATE,
              loading: isDownloadBulkProductStocksTemplateFetching,
            },
            {
              label: t('export_stock_list'),
              value: ExportOption.EXPORT_STOCK_LIST,
              loading: isFetchingExportProductStocks,
            },
          ]}
          onExport={onExport}
          total={total}
          description={`${total} ${t('results.found')}`}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </section>
  );
};

export default StockDetail;
