import {
  downloadBulkProductStocksTemplateUrl,
  exportProductStocksUrl,
  patchProduckStockUrl,
} from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { HTTP } from '@services/client';

export const getBulkTemplateStocks = () => {
  return HTTP.get(downloadBulkProductStocksTemplateUrl, {
    responseType: 'blob',
  });
};

export const getExportProductStocks = async ({ requestConfig, fileFilter }) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await HTTP.post(exportProductStocksUrl, formData, {
      ...requestConfig,
      headers: {
        'Origin-Method': HttpMethod.GET,
        ...requestConfig?.headers,
      },
    });
  } else {
    return await HTTP.get(exportProductStocksUrl, requestConfig);
  }
};

/**
 * PATCH "/product-stocks/{stockId}"
 * @param {*} { stockId, requestConfig }
 * @returns
 */

export const patchProductStock = ({ stockId, requestBody }) => {
  return HTTP.patch(patchProduckStockUrl, requestBody, {
    urlParams: {
      stockId,
    },
  });
};
