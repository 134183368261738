import { dateAndTimeFormat } from '@common/date';
import { formatDateTime } from '@common/dateTime';
import { UserRole } from '@constants/auth';
import { Switch } from 'antd';
import moment from 'moment';
import React from 'react';

export const DataIndex = {
  SHIPPING_COMPANY: ['shipping_company_obj', 'name'],
  SHIPPING_OPTION: ['shipping_option_obj', 'name'],
  IS_REAL_ACTIVE: 'is_real_active',
  UPDATED_AT_SELLER: ['shipping_company_option_config_obj', 'updated_at'],
  UPDATED_AT_SUPER_USER: 'updated_at',
};

export const getColumns = ({ t, handleSwitchChange, userRole }) => {
  return [
    {
      dataIndex: DataIndex.SHIPPING_COMPANY,
      title: t('firm_name'),
    },
    {
      dataIndex: DataIndex.SHIPPING_OPTION,
      title: t('delivery_option'),
    },
    {
      dataIndex: DataIndex.IS_REAL_ACTIVE,
      title: t('operating_status'),
      render: (value, record) => (
        <Switch
          checked={value}
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
          onClick={(_, e) => e.stopPropagation()}
          onChange={() => handleSwitchChange(record.id)}
        />
      ),
    },
    userRole === UserRole.SELLER && {
      dataIndex: DataIndex.UPDATED_AT_SELLER,
      title: t('last_updated_date'),
      render(sellerUpdatedAt) {
        return moment(sellerUpdatedAt).format(dateAndTimeFormat);
      },
    },
    userRole === UserRole.SUPER_USER && {
      dataIndex: DataIndex.UPDATED_AT_SUPER_USER,
      title: t('last_updated_date'),
      render(superUserUpdatedAt) {
        return formatDateTime(superUserUpdatedAt);
      },
    },
  ];
};

export const createTableActions = ({ t, selectedRowKeys, updateShippingCompanyOptionForUser }) => {
  return [
    {
      label: t('activate_deactivate'),
      onTableAction: () => {
        selectedRowKeys.forEach((rowKey) => updateShippingCompanyOptionForUser(rowKey));
      },
      isSelectionRequired: true,
    },
  ];
};
