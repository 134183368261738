import { getIntegrationAccessTokensUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import omit from 'lodash/omit';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { queryClient } from '../queryClient';

export function usePostIntegrationAccessTokenMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(getIntegrationAccessTokensUrl, requestBody, requestConfig),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.INTEGRATION_ACCESS_TOKENS]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    createIntegrationAccessToken: mutate,
    isCreatingNewIntegrationAccessToken: isLoading,
  };
}
