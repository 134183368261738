import { openNotification } from '@components/AkinonNotification';

const useAddProductsCommission = ({
  t,
  refetchProductOffers,
  refetchReconciliationRules,
  productPoolDataSource,
  postReconciliationRulesAsync,
}) => {
  const onAddProductsCommission = ({ formValues, selectedRows }) => {
    const promises = selectedRows.map((row) => {
      const selectedProductOffer =
        productPoolDataSource?.find((productOffer) => productOffer.id === row) || {};
      return async () => {
        try {
          await postReconciliationRulesAsync({
            requestBody: {
              ...formValues,
              datasource: selectedProductOffer?.datasource,
              product: selectedProductOffer?.id,
            },
            requestConfig: {
              suppressErrorNotifications: true,
              suppressSuccessNotifications: true,
            },
          });

          openNotification({
            message: t('transaction_success'),
            description: t('transactions.body.create_commission_success'),
          });
        } catch (error) {
          openNotification({
            message: t('transaction_failed'),
            description: t('transactions.body.create_commission_failed'),
            type: 'error',
          });
        }
      };
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      refetchProductOffers();
      refetchReconciliationRules();
    });
  };

  return { onAddProductsCommission };
};

export default useAddProductsCommission;
