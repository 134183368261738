import { getAnnouncementsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

/**
 * @param {{params: Object, queryOptions: import('@tanstack/react-query').UseQueryOptions, onSuccess: Function}} args
 */

export function useGetAnnouncementsQuery({ params, queryOptions, onSuccess } = {}) {
  const { data, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.ANNOUNCEMENTS, params],
    async queryFn() {
      const response = await HTTP.get(getAnnouncementsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    announcements: data?.results,
    isFetchingAnnouncements: isFetching,
    refetchAnnouncements: refetch,
  };
}
