import './styles.scss';

import { useIsMobile } from '@hooks/useIsMobile';
import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';

import MobileRangePicker from './components/MobileRangePicker';

/**
 * @param {import('antd').DatePickerProps} props
 */
const AkinonRangePicker = (props) => {
  const [value, setValue] = useState(props.value);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props?.onChange?.(false);
    setTimeout(() => {
      props?.onChange?.(undefined);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props?.onCalendarChange?.(value[0], value[1]);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 500);
  };

  const modifiedProps = isMobile && {
    onClick: (e) => {
      if (e.target.tagName === 'INPUT') {
        setIsOpen(true);
      }
    },
    onCalendarChange: (dates) => {
      setValue([dates]);
    },
    open: isOpen,
  };

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile]);

  return (
    <DatePicker.RangePicker
      panelRender={(originPanel) => {
        if (!isMobile) {
          return originPanel;
        }

        return (
          <MobileRangePicker
            onClear={onClear}
            title={props?.placeholder}
            onApply={onApply}
            isFadingOut={isFadingOut}
            isOpen={isOpen}
            menu={originPanel}
          />
        );
      }}
      {...props}
      {...modifiedProps}
    />
  );
};

export default React.memo(AkinonRangePicker);
