import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import If from '@components/If';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { getProductOffersUrl, getReconciliationRules } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { TranslationKey } from '@root/i18n';
import { useDeleteReconciliationRulesMutation } from '@services/api/hooks/useDeleteReconciliationRulesMutation';
import { usePostReconciliationRulesMutation } from '@services/api/hooks/usePostReconciliationRulesMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Col, Row, Typography } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import {
  getProductPoolColumns,
  getProductPoolTableActions,
  getSpecialCommissionProductsColumns,
  getSpecialCommissionProductsTableActions,
  ProductBasedCommissionTableName,
} from './common';
import AddProductCommissionFormModal from './components/AddProductCommissionFormModal';
import AddSpecialCommissionProductFormModal from './components/AddSpecialCommissionProductFormModal';
import BulkUpdateCommission from './components/BulkUpdateCommission';
import DeleteProductCommissionModal from './components/DeleteProductCommissionModal';
import EditableCell from './components/EditableCell';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

const { Text } = Typography;

const ProductBasedCommission = () => {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const isMobile = useIsMobile();
  const filters = usePageFilters();
  const [productPoolPagination, setProductsPagination] = usePagination();
  const [specialCommissionProductPagination, setProductsWithSpecialCommissionPagination] =
    usePagination();

  const { reset: resetProductOfferForm, getValues: getProductOfferFormValues } = useForm();
  const {
    reset: resetSpecialCommissionProductForm,
    getValues: getSpecialCommissionProductFormValues,
  } = useForm();

  const [isBulkUpdateCommissionActive, toggleIsBulkUpdateCommissionActive] = useBoolean(false);

  const [editingKey, setEditingKey] = useState(null);
  const isEditing = (record) => record.id === editingKey;

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const isSeller = userRole === UserRole.SELLER;

  const {
    selectedRowKeys: productPoolSelectedRowKeys,
    setSelectedRowKeys: setProductPoolSelectedRowKeys,
  } = useSelectedKeys({
    tableName: ProductBasedCommissionTableName.PRODUCT_POOL,
  });

  const {
    selectedRowKeys: specialCommissionProductSelectedRowKeys,
    setSelectedRowKeys: setSpecialCommissionProductSelectedRowKeys,
  } = useSelectedKeys({
    tableName: ProductBasedCommissionTableName.SPECIAL_COMMISSION_PRODUCTS,
  });

  const [isAddProductCommissionModalVisible, setIsAddProductCommissionModalVisible] =
    useState(false);
  const [
    isAddProductsSpecialCommissionModalVisible,
    setIsAddProductsSpecialCommissionModalVisible,
  ] = useState(false);
  const [isRemoveProductCommissionModalVisible, setIsRemoveProductCommissionModalVisible] =
    useState(false);

  const {
    productPoolDataSource,
    isProductPoolDataSourceLoading,
    totalProductPoolDataSource,
    specialCommissionProductDataSource,
    isSpecialCommissionProductDataSourceLoading,
    totalSpecialCommissionProductDataSource,
    refetchProductOffers,
    refetchReconciliationRules,
  } = useDataSource({
    filters,
    productPoolPagination,
    specialCommissionProductPagination,
  });

  const { postReconciliationRules, postReconciliationRulesAsync, isPostingReconciliationRules } =
    usePostReconciliationRulesMutation({
      mutationOptions: {
        onSettled: () => {
          resetProductOfferForm();
        },
      },
    });

  const {
    deleteReconciliationRules,
    deleteReconciliationRulesAsync,
    isDeletingReconciliationRules,
  } = useDeleteReconciliationRulesMutation({
    mutationOptions: {
      onSettled: () => {
        setSpecialCommissionProductSelectedRowKeys([]);
      },
    },
  });

  const openAddCollectionProductModal = (record) => {
    if (record) {
      resetProductOfferForm({
        product: record?.id,
        datasource: record?.datasource,
      });
      setProductPoolSelectedRowKeys([record.id]);
    }

    setIsAddProductCommissionModalVisible(true);
  };

  const openRemoveProductCommissionModal = (record) => {
    if (record) {
      setSpecialCommissionProductSelectedRowKeys([record.id]);
    }
    setIsRemoveProductCommissionModalVisible(true);
  };

  const openAddSpecialCommissionProductModal = () => {
    setIsAddProductsSpecialCommissionModalVisible(true);
  };

  const handleEditRow = (record) => {
    resetSpecialCommissionProductForm({
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleCancelEditing = () => {
    setEditingKey('');
  };

  const handleSaveRow = async () => {
    const specialCommissionProductFormValues = getSpecialCommissionProductFormValues();

    const { commission, maturity, productOfferId, datasource } = specialCommissionProductFormValues;
    deleteReconciliationRules(
      {
        ruleId: specialCommissionProductFormValues.id,
        requestConfig: {
          suppressErrorNotifications: true,
          suppressSuccessNotifications: true,
        },
      },
      {
        onSuccess: () => {
          postReconciliationRules(
            {
              requestBody: {
                commission,
                maturity,
                product: productOfferId,
                datasource,
              },
              requestConfig: {
                suppressErrorNotifications: true,
                suppressSuccessNotifications: true,
              },
            },
            {
              onSuccess: () => {
                handleCancelEditing();
                refetchReconciliationRules();
                openNotification({
                  message: t('transaction_success'),
                  description: t('transactions.body.create_commission_success'),
                });
              },
              onError: () => {
                openNotification({
                  message: t('transaction.title.update_error'),
                  description: t('transactions.body.update_commission_error'),
                  type: 'error',
                });
              },
            }
          );
        },
        onError: () => {
          openNotification({
            message: t('transaction_failed'),
            description: t('transactions.body.create_commission_failed'),
            type: 'error',
          });
        },
      }
    );
  };

  const filterFields = useFilterFields({ t });
  const productPoolTableColumns = getProductPoolColumns({
    t,
    isMobile,
    isSuperUser,
    openAddCollectionProductModal,
  });

  const specialCommissionProductTableColumns = getSpecialCommissionProductsColumns({
    t,
    isMobile,
    isEditing,
    editingKey,
    handleEditRow,
    handleCancelEditing,
    handleSaveRow,
    isSuperUser,
    openRemoveProductCommissionModal,
    specialCommissionProductFormValues: getSpecialCommissionProductFormValues(),
    reset: resetSpecialCommissionProductForm,
  });

  const productPoolTableActions = getProductPoolTableActions({
    t,
    isSuperUser,
    openAddCollectionProductModal,
  });

  const specialCommissionProductTableActions = getSpecialCommissionProductsTableActions({
    t,
    isSuperUser,
    openAddSpecialCommissionProductModal,
    openRemoveProductCommissionModal,
  });

  return (
    <section className="product-based-commission">
      <Box
        title={t('product_based_commission')}
        actions={
          <AkinonButton type="primary" onClick={() => toggleIsBulkUpdateCommissionActive()}>
            {t('bulk_commission_update')}
          </AkinonButton>
        }
        className="box-primary product-based-commission__box"
      >
        <Text className="product-based-commission__description">
          {t('product_based_commission_desc')}
        </Text>
      </Box>

      <Show when={isBulkUpdateCommissionActive} animateProps={Animations.fade}>
        <BulkUpdateCommission />
      </Show>

      <div>
        <Row>
          <Col span={24}>
            <AkinonFilter title={t('filters')} filterFields={filterFields} />
          </Col>
        </Row>
        <Row span={24} gutter={16} className="campaign-detail__products">
          <If
            condition={!isSeller}
            then={
              <Col span={12}>
                <AkinonTable
                  tableName={ProductBasedCommissionTableName.PRODUCT_POOL}
                  title={t('product.pool')}
                  columns={productPoolTableColumns}
                  dataSource={productPoolDataSource}
                  optionsUrl={getProductOffersUrl}
                  loading={isProductPoolDataSourceLoading || isPostingReconciliationRules}
                  total={totalProductPoolDataSource}
                  rowKey={defaultRowKey}
                  pagination={productPoolPagination}
                  setPagination={setProductsPagination}
                  rowSelection={Boolean(isSuperUser)}
                  description={`${totalProductPoolDataSource || 0} ${t('results.found')}`}
                  tableActions={productPoolTableActions}
                />
              </Col>
            }
            otherwise={null}
          />
          <Col span={isSeller ? 24 : 12}>
            <AkinonTable
              tableName={ProductBasedCommissionTableName.SPECIAL_COMMISSION_PRODUCTS}
              title={t('special_commission_products')}
              columns={specialCommissionProductTableColumns}
              dataSource={specialCommissionProductDataSource}
              optionsUrl={getReconciliationRules}
              loading={isSpecialCommissionProductDataSourceLoading || isDeletingReconciliationRules}
              total={totalSpecialCommissionProductDataSource}
              rowKey={defaultRowKey}
              pagination={specialCommissionProductPagination}
              setPagination={setProductsWithSpecialCommissionPagination}
              rowSelection={Boolean(isSuperUser)}
              tableActions={specialCommissionProductTableActions}
              description={`${totalSpecialCommissionProductDataSource || 0} ${t('results.found')}`}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Col>
        </Row>
      </div>
      <AddProductCommissionFormModal
        productOfferFormValues={getProductOfferFormValues()}
        productPoolSelectedRowKeys={productPoolSelectedRowKeys}
        productPoolDataSource={productPoolDataSource}
        refetchProductOffers={refetchProductOffers}
        refetchReconciliationRules={refetchReconciliationRules}
        postReconciliationRules={postReconciliationRules}
        postReconciliationRulesAsync={postReconciliationRulesAsync}
        modalProps={{
          title: t('define_commission_and_maturity'),
          open: isAddProductCommissionModalVisible,
          onCancel: () => setIsAddProductCommissionModalVisible(false),
        }}
      />
      <DeleteProductCommissionModal
        specialCommissionProductSelectedRowKeys={specialCommissionProductSelectedRowKeys}
        refetchProductOffers={refetchProductOffers}
        refetchReconciliationRules={refetchReconciliationRules}
        deleteReconciliationRules={deleteReconciliationRules}
        deleteReconciliationRulesAsync={deleteReconciliationRulesAsync}
        modalProps={{
          title: t('delete_commission'),
          open: isRemoveProductCommissionModalVisible,
          onCancel: () => setIsRemoveProductCommissionModalVisible(false),
        }}
      />
      <AddSpecialCommissionProductFormModal
        specialCommissionProductSelectedRowKeys={specialCommissionProductSelectedRowKeys}
        specialCommissionProductDataSource={specialCommissionProductDataSource}
        refetchProductOffers={refetchProductOffers}
        refetchReconciliationRules={refetchReconciliationRules}
        postReconciliationRulesAsync={postReconciliationRulesAsync}
        deleteReconciliationRulesAsync={deleteReconciliationRulesAsync}
        modalProps={{
          title: t('update_commission_and_term_settings'),
          open: isAddProductsSpecialCommissionModalVisible,
          onCancel: () => setIsAddProductsSpecialCommissionModalVisible(false),
        }}
      />
    </section>
  );
};

export default ProductBasedCommission;
