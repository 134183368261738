import { openNotification } from '@components/AkinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import pick from 'lodash/pick';
import { useState } from 'react';

import { FormKey } from '../common';

export function useOnSubmit({ createTicket, didCreateTicket, createTicketMessage, t }) {
  const [createdTicketId, setCreatedTicketId] = useState(null);
  const navigate = useAppNavigate();

  const onCreateMessage = ({ formData, id }) => {
    createTicketMessage(
      {
        requestBody: formData,
        requestConfig: {
          suppressErrorNotifications: true,
          suppressSuccessNotifications: true,
          urlParams: { ticketId: id },
        },
      },
      {
        onSuccess() {
          openNotification({
            message: t('transactions.title.success'),
            description: t('transactions.body.create_ticket_success'),
          });
          navigate(RouteUrls.support.landing);
        },
        onError() {
          openNotification({
            message: t('transactions.title.unsuccess'),
            description: t('transactions.body.create_ticket_unsuccess'),
            type: 'error',
          });
        },
      }
    );
  };

  return (formValues) => {
    if (didCreateTicket) {
      const formData = new FormData();
      formData.append(FormKey.CONTENT, formValues[FormKey.CONTENT]);
      onCreateMessage({ formData, id: createdTicketId });
      return;
    }

    createTicket(
      {
        requestBody: pick(formValues, [FormKey.SUBJECT, FormKey.PRIORITY]),
        requestConfig: {
          suppressErrorNotifications: true,
          suppressSuccessNotifications: true,
        },
      },
      {
        onSuccess({ id }) {
          setCreatedTicketId(id);
          const isMessageFieldFilled = negate(isEmpty)(formValues[FormKey.CONTENT]?.trim());
          const formData = new FormData();
          formData.append(FormKey.CONTENT, formValues[FormKey.CONTENT]);
          formValues[FormKey.ATTACHMENT]?.fileList.forEach((file) => {
            formData.append(FormKey.ATTACHMENT, file.originFileObj);
          });
          if (isMessageFieldFilled) {
            onCreateMessage({ formData, id });
          } else {
            openNotification({
              message: t('transactions.title.success'),
              description: t('transactions.body.create_ticket_success'),
            });
            navigate(RouteUrls.support.landing);
          }
        },
        onError() {
          openNotification({
            message: t('transactions.title.unsuccess'),
            description: t('transactions.body.create_ticket_unsuccess'),
            type: 'error',
          });
        },
      }
    );
  };
}
