import { getTownshipsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useTownshipsQuery({ queryOptions, params } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.TOWNSHIPS, params],
    queryFn: () =>
      HTTP.get(getTownshipsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    townships: data?.results,
    isTownshipsFetching: isFetching,
  };
}
