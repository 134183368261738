import { postReconciliationsRebateRulesUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function usePostReconciliationRebateRuleMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postReconciliationsRebateRulesUrl, requestBody, {
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    postReconciliationRebateRule: mutate,
    isPostingReconciliationRebateRule: isLoading,
  };
}
