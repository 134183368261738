import { withdrawPreOfferUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useWithdrawMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ preOfferId, requestConfig } = {}) {
      return HTTP.post(withdrawPreOfferUrl, null, {
        ...requestConfig,
        urlParams: { preOfferId },
      });
    },
  });

  return {
    withdrawPreOffer: mutate,
    isWithdrawingPreOffer: isLoading,
  };
}
