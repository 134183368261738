import { postSellerPerformanceMetricsUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export const usePostSellerPerformanceMetricsMutation = ({ mutationOptions } = {}) => {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postSellerPerformanceMetricsUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    postSellerPerformanceMetrics: mutate,
    isPostingSellerPerformanceMetrics: isLoading,
  };
};
