import { postRejectOrderItemsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export const useRejectOrderItemsMutation = ({ mutationOptions = {} }) => {
  const { mutate, error, isLoading } = useAkinonMutation({
    mutationKey: ['reject-order-item'],
    mutationFn: ({ orderId, orderItems, isRefund = false, cancellationReason, requestConfig }) =>
      HTTP.post(
        postRejectOrderItemsUrl,
        { order_items: orderItems, is_refund: isRefund, reason: cancellationReason },
        { urlParams: { orderId }, ...requestConfig }
      ),
    ...mutationOptions,
  });

  return {
    rejectOrderItems: mutate,
    rejectOrderItemsError: error,
    isRejecting: isLoading,
  };
};
