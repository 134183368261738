import Link from '@components/Link';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { TranslationKey } from '@root/i18n';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);
  const userRole = useUserRole();
  const isSeller = userRole === UserRole.SELLER;

  useEffect(() => {
    setBreadcrumbs([
      <Link
        key={RouteUrls.productsAndCategories.landing}
        to={RouteUrls.productsAndCategories.landing}
      >
        {t('products')}
      </Link>,
      isSeller ? (
        <Link
          key={RouteUrls.productsAndCategories.bulkActions.landing}
          to={RouteUrls.productsAndCategories.bulkActions.landing}
        >
          {t('bulk.actions')}
        </Link>
      ) : (
        <Link
          key={RouteUrls.productsAndCategories.addProductsFromCatalog}
          to={RouteUrls.productsAndCategories.addProductsFromCatalog}
        >
          {t('add.from.marketplace.catalog')}
        </Link>
      ),
      t('bulk.actions.offer'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
