import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { openNotification } from '@components/AkinonNotification';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Modal } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAddProductsCommission from '../../hooks/useAddProductsCommission';
import { FormKey, getFormSchema } from './common';

const AddProductCommissionFormModal = ({
  productOfferFormValues,
  productPoolSelectedRowKeys,
  productPoolDataSource,
  refetchProductOffers,
  refetchReconciliationRules,
  postReconciliationRules,
  postReconciliationRulesAsync,
  modalProps,
}) => {
  const { t } = useTranslation('Finance');

  const { onAddProductsCommission } = useAddProductsCommission({
    t,
    refetchProductOffers,
    refetchReconciliationRules,
    productPoolDataSource,
    postReconciliationRulesAsync,
  });

  const schema = getFormSchema();
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const handleClose = () => {
    modalProps?.onCancel();
    reset();
  };

  const onSubmit = (formValues) => {
    const isMultiple = productPoolSelectedRowKeys?.length > 1;

    if (isMultiple) {
      onAddProductsCommission({
        formValues,
        selectedRows: productPoolSelectedRowKeys,
      });
    } else {
      postReconciliationRules(
        {
          requestBody: {
            ...formValues,
            ...productOfferFormValues,
          },
          requestConfig: {
            suppressErrorNotifications: true,
            suppressSuccessNotifications: true,
          },
        },
        {
          onSuccess: () => {
            refetchProductOffers();
            refetchReconciliationRules();
            openNotification({
              message: t('transaction_success'),
              description: t('transactions.body.create_commission_success'),
            });
          },
          onError: (error) => {
            openNotification({
              message: t('transaction_failed'),
              description:
                error.response?.data?.detail ??
                error.response?.data?.non_field_errors ??
                error.message,
              type: 'error',
            });
          },
        }
      );
    }

    handleClose();
  };

  return (
    <Modal {...modalProps} onCancel={handleClose} footer={null}>
      <AkinonForm
        control={control}
        schema={schema}
        onFinish={handleSubmit(onSubmit)}
        layout="vertical"
      >
        <AkinonInput
          label={t('commission')}
          name={FormKey.COMMISSION}
          placeholder={t('commission')}
        />
        <AkinonInput label={t('maturity')} name={FormKey.MATURITY} placeholder={t('maturity')} />
        <Form.Item wrapperCol={{ span: 24 }}>
          <AkinonButton htmlType="submit" type="primary">
            {t('submit')}
          </AkinonButton>
        </Form.Item>
      </AkinonForm>
    </Modal>
  );
};

export default AddProductCommissionFormModal;
