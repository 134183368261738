import { updateCategoryAttributeSetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function usePutCategoryAttributeSetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ categoryId, requestBody, requestConfig }) =>
      HTTP.put(updateCategoryAttributeSetUrl, requestBody, {
        urlParams: { categoryId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    putCategoryAttributeSet: mutate,
    isPuttingCategoryAttributeSet: isLoading,
  };
}
