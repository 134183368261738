import { getAttributesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useMemo } from 'react';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useAttributesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.ATTRIBUTES, params],
    async queryFn() {
      const response = await HTTP.get(getAttributesUrl, {
        params: {
          is_active: true,
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  const memoAttributes = useMemo(() => data?.results ?? [], [data]);

  return {
    attributes: memoAttributes,
    isAttributesLoading: isLoading,
    isFetchingAttributes: isFetching,
    attributesError: error,
  };
}
