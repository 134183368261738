export const createLanguageSlice = (set) => ({
  language: null,
  setLanguage: (language) =>
    set((state) => {
      state.language = language;
    }),
  resetLanguage: () =>
    set((state) => {
      state.language = null;
    }),
});
