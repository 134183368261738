import '../style.scss';

import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import AkinonFormItem from '@components/AkinonFormItem';
import { openNotification } from '@components/AkinonNotification';
import Box from '@components/utility/box/index';
import { QueryKey } from '@constants/query';
import { useBulkImportStocksMutation } from '@services/api/hooks/useBulkImportStocksMutation';
import { queryClient } from '@services/api/queryClient';
import { Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField } from '../common';
import { BulkActionsProgressModal } from './BulkActionsProgressModal';
import { FormKey, getFormResolver } from './common';

const BulkUpdateStocks = () => {
  const { t } = useTranslation('ProductsAndCategories');

  const resolver = getFormResolver({ t });
  const { control, reset, handleSubmit } = useForm({
    resolver,
    defaultValues: {
      file: {
        fileList: [],
      },
    },
  });

  const {
    bulkImportStocks,
    resetBulkImportStocks,
    isBulkImportingStocks,
    isBulkImportStocksSuccess,
    isBulkImportStocksError,
    bulkImportStocksData,
  } = useBulkImportStocksMutation({
    mutationOptions: {
      onSuccess: async ({ key }) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.IMPORT_STATUS, key],
        });
      },
      onError: (error) => {
        openNotification({
          message: t('transaction_failed'),
          description:
            error.response?.data?.detail ?? error?.response?.data?.invalid_headers ?? error.message,
          type: 'error',
        });
      },
    },
  });

  const onFileUpload = (values) => {
    const formData = new FormData();
    values[FormKey.FILE].fileList.forEach((fileItem) => {
      formData.append(FormField.FILE, fileItem.originFileObj);
    });
    bulkImportStocks({ formData });
  };

  return (
    <>
      <div className="bulk-actions mb-8">
        <Box when className="box-primary form-box bulk" title={t('bulk_actions_stocks')}>
          <Form className="ant-form" layout="vertical">
            <AkinonFormItem control={control} name="file" label={t('bulk_actions_upload_stocks')}>
              <DraggerInputWithLabel
                accept=".csv,.xlsx,.xls"
                singleAction
                multiple={false}
                beforeUpload={() => false}
              />
            </AkinonFormItem>
            <div className="actions">
              <AkinonButton
                loading={isBulkImportingStocks}
                style={{
                  marginTop: '24px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
                type="primary"
                onClick={handleSubmit(onFileUpload)}
              >
                {t('save')}
              </AkinonButton>
            </div>
          </Form>
        </Box>
      </div>
      <BulkActionsProgressModal
        bulkImportKey={bulkImportStocksData?.key}
        open={isBulkImportStocksSuccess && !isBulkImportStocksError}
        onCancel={() => {
          resetBulkImportStocks();
          reset();
        }}
        messages={{
          successCount: ({ succeededCount }) =>
            t('stock_import_progress_succeeded_count', { count: succeededCount }),
          failCount: ({ failedCount }) =>
            t('stock_import_progress_failed_count', { count: failedCount }),
          finishButtonText: t('go_to_created_items', {
            to: t('to_stock'),
          }),
        }}
      />
    </>
  );
};

export default BulkUpdateStocks;
