import {
  dynamicSettingsDetailURL,
  dynamicSettingsRegistryURL,
  dynamicSettingsURL,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getDynamicSettings = (params) => {
  return HTTP.get(dynamicSettingsURL, { params });
};

export const getDynamicSettingsRegistry = (params) => {
  return HTTP.get(dynamicSettingsRegistryURL, { params });
};

export const getDynamicSetting = ({ dynamicSettingId }) => {
  return HTTP.get(dynamicSettingsDetailURL, { urlParams: { dynamicSettingId } });
};

export const createDynamicSetting = ({ data, requestConfig }) => {
  return HTTP.post(dynamicSettingsURL, data, requestConfig);
};

export const deleteDynamicSetting = ({ dynamicSettingId }) => {
  return HTTP.delete(dynamicSettingsDetailURL, { urlParams: { dynamicSettingId } });
};

export const updateDynamicSetting = ({ dynamicSettingId, data, requestConfig }) => {
  return HTTP.patch(dynamicSettingsDetailURL, data, {
    ...requestConfig,
    urlParams: { dynamicSettingId },
  });
};
