import { oAuthProvidersUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostOauthProviderMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(oAuthProvidersUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createProvider: mutate,
    isProviderCreating: isLoading,
  };
}
