import { getProductCollectionDetailURL } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const useCollectionItemsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.COLLECTION_ITEMS, params],
    async queryFn() {
      const response = await HTTP.get(getProductCollectionDetailURL, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });
  return {
    collectionItems: data?.results,
    collectionItemsTotal: data?.count,
    isFetchingCollectionItems: isFetching,
  };
};
