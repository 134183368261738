import AkinonButton from '@components/AkinonButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import {
  IconCircleCheck,
  IconCircleMinus,
  IconCirclePlus,
  IconCircleX,
  IconEditCircle,
} from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';

const ProductOfferDataIndex = {
  IMAGE: 'image',
  SKU: 'sku',
};

const CollectionProductDataIndex = {
  IMAGE: 'image',
  SKU: ['product', 'sku'],
  SPECIAL_COMMISSION_RATE: 'commission',
  MATURITY: 'maturity',
};

export const StaticFilterKey = {
  BASE_CODE: 'product__base_code__iexact',
  SKU: 'product__sku__iexact',
  PRODUCT_NAME: 'product__name__icontains',
};

export const getProductPoolColumns = ({
  t,
  isMobile,
  isSuperUser,
  openAddCollectionProductModal,
}) => [
  {
    dataIndex: ProductOfferDataIndex.IMAGE,
    title: <ImageTableHead />,
    render: (image) => <SmallColumnImage src={image} />,
    align: 'center',
    width: 100,
  },
  {
    dataIndex: ProductOfferDataIndex.SKU,
    title: t('sku'),
  },
  {
    dataIndex: 'add_button',
    title: ' ',
    fixed: isMobile ? false : 'right',
    width: 50,
    className: 'operation-column',
    render: (_, record) => {
      return (
        <div className="flex justify-center">
          <AkinonButton
            type="text"
            onClick={() => openAddCollectionProductModal(record)}
            style={{
              height: 'auto',
              padding: 0,
            }}
            disabled={!isSuperUser}
          >
            <IconCirclePlus className="operation-icon add-icon" />
          </AkinonButton>
        </div>
      );
    },
  },
];

export const getSpecialCommissionProductsColumns = ({
  t,
  isMobile,
  isSuperUser,
  isEditing,
  editingKey,
  handleEditRow,
  handleCancelEditing,
  handleSaveRow,
  openRemoveProductCommissionModal,
  specialCommissionProductFormValues,
  reset,
}) =>
  [
    {
      dataIndex: CollectionProductDataIndex.IMAGE,
      title: <ImageTableHead />,
      render: (image) => <SmallColumnImage src={image} />,
      align: 'center',
      width: 100,
    },
    {
      dataIndex: CollectionProductDataIndex.SKU,
      title: t('sku'),
    },
    {
      dataIndex: CollectionProductDataIndex.SPECIAL_COMMISSION_RATE,
      title: t('special_commission_rate'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        max: 100,
        precision: 5,
        defaultValue: specialCommissionProductFormValues?.commission,
      },
    },
    {
      dataIndex: CollectionProductDataIndex.MATURITY,
      title: t('maturity'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        max: 100,
        defaultValue: specialCommissionProductFormValues?.maturity,
      },
    },
    {
      dataIndex: 'operation',
      title: ' ',
      fixed: isMobile ? false : 'right',
      className: 'operation-column',
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <div className="flex justify-center">
            <IconCircleCheck
              className="operation-icon save-icon"
              onClick={() => handleSaveRow(record)}
            />
            <IconCircleX className="operation-icon cancel-icon" onClick={handleCancelEditing} />
          </div>
        ) : (
          <div className="flex justify-center">
            <AkinonButton
              type="text"
              onClick={() => handleEditRow(record)}
              style={{
                height: 'auto',
                padding: 0,
              }}
              disabled={!isEmpty(editingKey) || !isSuperUser}
            >
              <IconEditCircle className="operation-icon edit-icon" />
            </AkinonButton>
            <AkinonButton
              type="text"
              onClick={() => openRemoveProductCommissionModal(record)}
              disabled={!isEmpty(editingKey) || !isSuperUser}
              style={{
                height: 'auto',
                padding: 0,
              }}
            >
              <IconCircleMinus className="operation-icon delete-icon" />
            </AkinonButton>
          </div>
        );
      },
    },
  ].map((col) => {
    if (!col?.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputProps: col.inputProps,
        editing: isEditing(record),
        reset,
        rules: [
          {
            required: true,
            message: t('validation.message'),
          },
        ],
      }),
    };
  });

export const getProductPoolTableActions = ({ t, isSuperUser, openAddCollectionProductModal }) => {
  return isSuperUser
    ? [
        {
          label: t('add_commission'),
          onTableAction: () => {
            openAddCollectionProductModal();
          },
          isSelectionRequired: true,
        },
      ]
    : [];
};

export const getSpecialCommissionProductsTableActions = ({
  t,
  isSuperUser,
  openAddSpecialCommissionProductModal,
  openRemoveProductCommissionModal,
}) => {
  return isSuperUser
    ? [
        {
          label: t('update_commission_and_term_settings'),
          onTableAction: () => {
            openAddSpecialCommissionProductModal();
          },
          isSelectionRequired: true,
        },
        {
          label: t('delete_commission'),
          onTableAction: () => {
            openRemoveProductCommissionModal();
          },
          isSelectionRequired: true,
        },
      ]
    : [];
};

export const ProductBasedCommissionTableName = {
  PRODUCT_POOL: 'productPool',
  SPECIAL_COMMISSION_PRODUCTS: 'specialCommissionProducts',
};
