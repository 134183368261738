import { postDataSourcesUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostDataSourceMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postDataSourcesUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createDataSource: mutate,
    isCreatingDataSource: isLoading,
  };
}
