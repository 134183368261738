import { openNotification } from '@components/AkinonNotification';
import { QueryKey } from '@constants/query';
import { getCancellationRequests } from '@services/api';
import { useQuery } from '@tanstack/react-query';
import { T } from '@utils/languageProvider';

export function useCancellationRequestsQuery({ params, enabled = true }) {
  const queryFn = () => getCancellationRequests({ ...params });

  const { data, isLoading, error, ...rest } = useQuery({
    queryKey: [QueryKey.CANCELLATION, { ...params }],
    queryFn,
    enabled,
  });

  const onError = () => {
    const message = error?.response?.data?.detail || error?.message;

    openNotification({
      message: T('error'),
      description: message,
      type: 'error',
    });
  };

  if (error) {
    onError();
  }

  return {
    ...rest,
    data,
    isLoading,
    error,
    total: data?.count,
  };
}
