import { deleteCategoryNodePermissionsUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useDeleteCategoryNodePermissionsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ categoryNodeId }) => {
      HTTP.delete(deleteCategoryNodePermissionsUrl, { urlParams: { categoryNodeId } });
    },
    ...mutationOptions,
  });

  return {
    deleteCategoryNodePermission: mutateAsync,
    isDeletingNewCategoryNodePermission: isLoading,
  };
}
