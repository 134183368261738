import { transformApiErrorToErrorMessages } from '@common/network';
import { getMeUrl } from '@constants/apiUrls';
import {
  AUTHORIZATION_HEADER,
  HttpMethodsToShowSuccessNotifications,
  HttpStatus,
} from '@constants/auth';
import { Language } from '@constants/locale';
import { RouteUrls } from '@constants/routeUrls';
import i18n from '@root/i18n';
import useStore from '@zustand-store/index';
import axios from 'axios';
import { mapValues } from 'lodash';
import QuerySerializer from 'qs';
import { generatePath, matchPath } from 'react-router-dom';

import { Auth } from './auth-adapter';
import { API_BASE_URL } from './constants';
import {
  attemptTokenRefresh,
  getControllerSignal,
  handleNoInternetMessage,
  handleSuppressErrorNotification,
  logErrorsToLogCenter,
  openErrorNotification,
  openSuccessNotification,
  signOut,
} from './utils';

export const HTTP = axios.create({
  baseURL: API_BASE_URL,
  paramsSerializer: (params) => QuerySerializer.stringify(params, { indices: false }),
  timeout: 60 * 1000,
});

HTTP.interceptors.request.use((config) => {
  const language = useStore.getState().language;
  const accessToken = Auth.accessToken;

  if (!config.url.includes(getMeUrl)) {
    config.signal = getControllerSignal();
  }

  if (config?.urlParams) {
    config.urlParams = mapValues(config.urlParams, (value) => value ?? ''); // <- mapping here does what?
    config.url = `${generatePath(config.url, config.urlParams)}/`;
  }

  config.headers = {
    Accept: 'application/json',
    Authorization: accessToken ? `${AUTHORIZATION_HEADER} ${accessToken}` : null,
    'Accept-Language': language ?? null,
    ...config.headers,
  };

  return config;
});

const apiResponseSuccessInterceptor = (response) => {
  if (
    response?.config?.suppressSuccessNotifications ||
    !HttpMethodsToShowSuccessNotifications.includes(response?.config?.method?.toLocaleUpperCase())
  )
    return response?.data;

  openSuccessNotification({ response });
  return response?.data;
};

const apiResponseErrorInterceptor = async (responseError) => {
  if (axios.isCancel(responseError)) return;
  const currentLocation = window.location.pathname;
  const refreshToken = Auth.refreshToken;

  const isUnauthorized = responseError?.response?.status === HttpStatus.UNAUTHORIZED;
  const shouldRedirectToSignIn =
    !matchPath({ path: RouteUrls.public.signIn }, currentLocation) &&
    !matchPath({ path: RouteUrls.public.signInCallback }, currentLocation);

  if (isUnauthorized) {
    if (refreshToken) {
      const expiredAccessToken = Auth.accessToken;
      let modifiedRequest;
      await navigator.locks.request('token_refresh', async () => {
        modifiedRequest = await attemptTokenRefresh({
          responseError,
          shouldRedirectToSignIn,
          expiredAccessToken,
        });
      });
      if (modifiedRequest) return modifiedRequest;
    } else {
      signOut();
    }
  }

  const { apiErrorMessages } = transformApiErrorToErrorMessages(responseError);
  i18n.loadLanguages(Object.values(Language), () => {
    logErrorsToLogCenter({ apiErrorMessages });
    handleNoInternetMessage({ responseError });
    handleSuppressErrorNotification({ responseError });
    openErrorNotification({ responseError, apiErrorMessages });
  });

  throw responseError;
};

HTTP.interceptors.response.use(apiResponseSuccessInterceptor, apiResponseErrorInterceptor);

/**
 * @param {string} url
 * @param {import('axios').AxiosRequestConfig} config
 */
export const fetcher = ([url, config]) => HTTP.get(url, config);
