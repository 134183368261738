import { rejectDataSourceLandingPageUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useRejectDataSourceLandingPageMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ dataSourceLandingPageId, requestBody, requestConfig }) => {
      return HTTP.post(rejectDataSourceLandingPageUrl, requestBody, {
        urlParams: { dataSourceLandingPageId },
        ...requestConfig,
      });
    },
    ...mutationOptions,
  });

  return {
    rejectDataSourceLandingPage: mutate,
    isRejectingDataSourceLandingPage: isLoading,
  };
}
