import { getCollectionsByIdURL } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const useCollectionDetailQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.COLLECTION_DETAIL, params],
    async queryFn() {
      const response = await HTTP.get(
        getCollectionsByIdURL,
        { urlParams: { collectionId: params.collectionId } },
        {
          params: {
            ...limitQuery,
            ...params,
          },
        }
      );
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });
  return {
    collectionDetail: data,
    isFetchingCollectionDetail: isFetching,
  };
};
