import { getAgreementsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

/**
 * @param {Object} [args]
 * @param {Object} [args.params]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function useAgreementsQuery({ params, queryOptions, onSuccess } = {}) {
  const { data, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.AGREEMENTS, params],
    async queryFn() {
      const response = await HTTP.get(getAgreementsUrl, {
        params,
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    agreements: data?.results || [],
    totalAgreements: data?.count || 0,
    isFetchingAgreements: isFetching,
    refetchAgreements: refetch,
  };
}
