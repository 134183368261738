const useApproveOrderItems = ({ approveBulkRefund, selectedRowKeys, dataSource }) => {
  const onApproveOrderItems = ({ mutationOptions }) => {
    const selectedRows = dataSource.filter((row) => selectedRowKeys.includes(row?.id));
    const cancellationRequests = selectedRows.map((row) => row?.cancellation_id);

    approveBulkRefund(
      {
        requestBody: {
          cancellation_requests: cancellationRequests,
        },
      },
      mutationOptions
    );
  };

  return { onApproveOrderItems };
};

export default useApproveOrderItems;
