import {
  approveCancellationRequestUrl,
  getCancellationRequestsUrl,
  rejectCancellationRequestUrl,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getCancellationRequests = (params) => {
  return HTTP.get(getCancellationRequestsUrl, { params });
};

export const approveCancellationRequest = ({ options = {} }) => {
  const { requestId, params } = options;
  return HTTP.post(
    approveCancellationRequestUrl,
    {
      params,
    },
    {
      urlParams: { requestId },
    }
  );
};

export const rejectCancellationRequest = ({ options = {} }) => {
  const { requestId, params } = options;

  return HTTP.post(
    rejectCancellationRequestUrl,
    {
      params,
    },
    { urlParams: { requestId } }
  );
};
