import { getImportsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useImportOptionsQuery({ queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.IMPORT_OPTIONS],
    queryFn: () => HTTP.options(getImportsUrl),
    ...queryOptions,
  });

  return {
    importOptions: data,
    isFetchingImportOptions: isFetching,
  };
}
