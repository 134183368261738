import './styles.scss';

import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import Box from '@components/utility/box';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import useBulkAction from '@hooks/useBulkAction';
import { useIsMobile } from '@hooks/useIsMobile';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import { useDeleteProductRestrictionMutation } from '@services/api/hooks/useDeleteProductRestrictionMutation';
import { usePostRestrictProductsMutation } from '@services/api/hooks/usePostRestrictProductsMutation';
import usePageData from '@zustand-store/hooks/usePageData';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getProductColumns,
  getProductsTableActions,
  getRestrictedProductsColumns,
  getRestrictedProductsTableActions,
  ProductPermissionsTableName,
} from './common';
import useBulkRestrictions from './hooks/useBulkRestrictions';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

const { Text } = Typography;

const ProductPermissions = () => {
  const { t } = useTranslation(['Finance']);
  const isMobile = useIsMobile();
  const [productsPagination, setProductsPagination] = usePagination();
  const [restrictedProductsPagination, setRestrictedProductsPagination] = usePagination();
  const { id: datasourceId } = useParams();
  const filterFields = useFilterFields({ t });
  const filters = useFilterParams({ filterFields });
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];
  const {
    selectedRowKeys: availableSelectedRowKeys,
    setSelectedRowKeys: setAvailableSelectedRowKeys,
  } = useSelectedKeys({
    tableName: ProductPermissionsTableName.AVAILABLE_PRODUCTS,
  });
  const {
    selectedRowKeys: restrictedSelectedRowKeys,
    setSelectedRowKeys: setRestrictedSelectedRowKeys,
  } = useSelectedKeys({
    tableName: ProductPermissionsTableName.RESTRICTED_PRODUCTS,
  });

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const {
    productsDataSource,
    isFetchingProducts,
    totalProducts,
    restrictedProductsDataSource,
    isFetchingRestrictedProducts,
    totalRestrictedProducts,
    refetchProducts,
    refetchRestrictedProducts,
  } = useDataSource({
    filters,
    productsPagination,
    restrictedProductsPagination,
    datasourceId,
    dynamicFormMetaFields,
  });

  const refetchAllProducts = () => {
    refetchRestrictedProducts();
    refetchProducts();
  };

  const { restrictProduct } = usePostRestrictProductsMutation();
  const { deleteProductRestriction } = useDeleteProductRestrictionMutation();
  const { onDeleteRestrictions, onRestrictProducts } = useBulkRestrictions({
    restrictProduct,
    deleteProductRestriction,
    datasourceId,
    refetchAllProducts,
  });

  const { onShowModal: onShowDeleteRestrictionModal, ...onShowDeleteRestrictionModalProps } =
    useBulkAction({
      bulkActionMutate: onDeleteRestrictions,
      selectedRowKeys: restrictedSelectedRowKeys,
      setSelectedRowKeys: setRestrictedSelectedRowKeys,
    });

  const { onShowModal: onShowRestrictProductsModal, ...onShowRestrictProductsModalProps } =
    useBulkAction({
      bulkActionMutate: onRestrictProducts,
      selectedRowKeys: availableSelectedRowKeys,
      setSelectedRowKeys: setAvailableSelectedRowKeys,
    });

  const productPoolTableColumns = getProductColumns({
    t,
    isMobile,
    datasourceId,
    restrictProduct,
    refetchAllProducts,
  });

  const restrictedProductsTableColumns = getRestrictedProductsColumns({
    t,
    isMobile,
    deleteProductRestriction,
    refetchAllProducts,
  });

  const productsTableActions = getProductsTableActions({
    t,
    onShowRestrictProductsModal,
    refetchAllProducts,
    availableSelectedRowKeys,
  });

  const restrictedProductsTableActions = getRestrictedProductsTableActions({
    t,
    onShowDeleteRestrictionModal,
    refetchAllProducts,
  });

  return (
    <section className="product-based-commission">
      <BulkActionModal
        {...onShowDeleteRestrictionModalProps}
        modalProps={{
          title: t('bulk_delete_restriction'),
          description: t('bulk_delete_restriction_desc', {
            count: Math.max(1, restrictedSelectedRowKeys.length),
          }),
        }}
        progressModalProps={{
          title: t('bulk_delete_restriction', {
            count: Math.max(1, restrictedSelectedRowKeys.length),
          }),
        }}
      />
      <BulkActionModal
        {...onShowRestrictProductsModalProps}
        modalProps={{
          title: t('bulk_restrict_product'),
          description: t('bulk_restrict_product_desc', {
            count: Math.max(1, availableSelectedRowKeys.length),
          }),
        }}
        progressModalProps={{
          title: t('bulk_restrict_product', {
            count: Math.max(1, availableSelectedRowKeys.length),
          }),
        }}
      />
      <Box title={t('product_permissions')} className="box-primary form-box">
        <Text className="product-based-commission__description">
          {t('product_permissions_desc')}
        </Text>
      </Box>
      <div>
        <Row>
          <Col span={24}>
            <AkinonFilter title={t('filters')} filterFields={filterFields} />
          </Col>
        </Row>
        <Row span={24} gutter={16} className="campaign-detail__products">
          <Col span={12}>
            <AkinonTable
              tableName={ProductPermissionsTableName.AVAILABLE_PRODUCTS}
              title={t('products_available_for_sale')}
              columns={productPoolTableColumns}
              dataSource={productsDataSource}
              optionsUrl={getProductOffersUrl}
              loading={isFetchingProducts}
              total={totalProducts}
              rowKey={defaultRowKey}
              pagination={productsPagination}
              setPagination={setProductsPagination}
              enableDynamicColumns
              rowSelection={true}
              description={`${totalProducts || 0} ${t('results.found')}`}
              tableActions={productsTableActions}
            />
          </Col>
          <Col span={12}>
            <AkinonTable
              tableName={ProductPermissionsTableName.RESTRICTED_PRODUCTS}
              title={t('restricted_products')}
              columns={restrictedProductsTableColumns}
              dataSource={restrictedProductsDataSource}
              optionsUrl={getProductOffersUrl}
              loading={isFetchingRestrictedProducts}
              total={totalRestrictedProducts}
              rowKey={defaultRowKey}
              pagination={restrictedProductsPagination}
              setPagination={setRestrictedProductsPagination}
              enableDynamicColumns
              rowSelection={true}
              tableActions={restrictedProductsTableActions}
              description={`${totalRestrictedProducts || 0} ${t('results.found')}`}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ProductPermissions;
