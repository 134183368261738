import { submitAllPreOffersUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useSubmitAllPreOffersMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ preOfferIds }) {
      return HTTP.post(submitAllPreOffersUrl, null, {
        params: {
          id_in: preOfferIds.join(','),
        },
      });
    },
  });

  return {
    submitAllPreOffers: mutate,
    isSubmittingAllPreOffers: isLoading,
  };
}
