import {
  getDataSourceShippingCompanyOptionConfigsUrl,
  getDataSourceShippingCompanyOptionConfigUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useDataSourceShippingCompanyOptionConfigsQuery({ queryOptions, params } = {}) {
  const { data, isFetching, isFetched } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIGS, params],
    queryFn: () =>
      HTTP.get(getDataSourceShippingCompanyOptionConfigsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    dataSourceShippingCompanyOptionConfigs: data?.results,
    isFetchingDataSourceShippingCompanyOptionConfigs: isFetching,
    isFetchedDataSourceShippingCompanyOptionConfigs: isFetched,
  };
}

export function useDataSourceShippingCompanyOptionConfigQuery({ queryOptions, configId } = {}) {
  const { data, isFetching, isFetched } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIG, configId],
    queryFn: () =>
      HTTP.get(getDataSourceShippingCompanyOptionConfigUrl, {
        urlParams: {
          configId,
        },
      }),
    ...queryOptions,
  });

  return {
    dataSourceShippingCompanyOptionConfig: data,
    isFetchingDataSourceShippingCompanyOptionConfig: isFetching,
    isFetchedDataSourceShippingCompanyOptionConfig: isFetched,
  };
}
