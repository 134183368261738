import { getRequestFiltersParams } from '@common/index';
import { postResolveAllPreOffersUrl } from '@constants/apiUrls';
import { omit } from 'lodash';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useResolveAllPreOffers({ filters, params = {}, mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading, data, error, isSuccess, reset } = useAkinonMutation({
    mutationFn: ({ requestConfig } = {}) => {
      const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
      const formData = new FormData();
      fileFilter &&
        formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);

      if (fileFilter) {
        return HTTP.post(postResolveAllPreOffersUrl, formData, {
          params: {
            ...getRequestFiltersParams(omit(filters, 'filter_file')),
            ...params,
          },
          ...{
            ...requestConfig,
          },
        });
      } else {
        return HTTP.post(
          postResolveAllPreOffersUrl,
          {},
          {
            params: {
              ...getRequestFiltersParams(omit(filters, 'filter_file')),
              ...params,
            },
            ...requestConfig,
          }
        );
      }
    },
    ...mutationOptions,
  });

  return {
    resolveAllPreOffers: mutate,
    resolveAllPreOffersAsync: mutateAsync,
    isResolvingAllPreOffers: isLoading,
    isResolvingAllPreOffersError: error,
    isResolvingAllPreOffersSuccess: isSuccess,
    resolveAllPreOffersCacheKey: data?.key,
    resetResolveAllPreOffers: reset,
  };
}
