import { dateWith12HoursFormat } from '@common/date';
import AkinonButton from '@components/AkinonButton';
import Show from '@components/Show';
import { Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';

export const ExportTableColumnDataIndex = {
  ID: 'id',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  FILE: 'file',
};

export const ExportStatus = {
  PENDING: 1,
  PROCESSING: 2,
  CONVERTING: 3,
  SUCCEEDED: 4,
  FAILED: 5,
};

export const getExportStatusMap = ({ t }) => ({
  [ExportStatus.PENDING]: t('pending'),
  [ExportStatus.PROCESSING]: t('processing'),
  [ExportStatus.CONVERTING]: t('processing'),
  [ExportStatus.SUCCEEDED]: t('succeeded'),
  [ExportStatus.FAILED]: t('failed'),
});

export const getTagColor = (status) => {
  switch (status) {
    case ExportStatus.PENDING:
      return 'blue';
    case ExportStatus.PROCESSING:
      return 'purple';
    case ExportStatus.CONVERTING:
      return 'purple';
    case ExportStatus.SUCCEEDED:
      return 'green';
    case ExportStatus.FAILED:
      return 'red';
    default:
      return 'default';
  }
};

export const getExportTableColumns = ({ t, setDocumentUrl, isFetching, documentUrl }) => {
  const exportStatusMap = getExportStatusMap({ t });

  return [
    {
      dataIndex: ExportTableColumnDataIndex.ID,
      title: t('batch_request_id'),
    },
    {
      dataIndex: ExportTableColumnDataIndex.STATUS,
      title: t('file_status'),
      render(status) {
        return <Tag color={getTagColor(status)}>{exportStatusMap[status]}</Tag>;
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.CREATED_AT,
      title: t('created_at'),
      render(createdAt) {
        return moment(createdAt).format(dateWith12HoursFormat);
      },
    },
    {
      title: t('uploaded_file'),
      dataIndex: ExportTableColumnDataIndex.FILE,
      render(fileUrl, rowData) {
        if (rowData?.status !== ExportStatus.FAILED) {
          return (
            <Show when={fileUrl} fallback={'-'}>
              <AkinonButton
                loading={isFetching && documentUrl === `exports/${rowData.id}/download/`}
                htmlType="button"
                type="link"
                onClick={() => setDocumentUrl(`exports/${rowData.id}/download/`)}
              >
                {t('download')}
              </AkinonButton>
            </Show>
          );
        }
        return '-';
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.FILE,
      title: t('error'),
      render(_, rowData) {
        const status = rowData?.status;
        if (status === ExportStatus.FAILED) {
          return rowData?.error ? (
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                suffix:
                  rowData?.error?.toString().split('').length > 50 ? (
                    <Tooltip title={rowData?.error}>
                      <Typography.Text className="text-blue-600 ml-2">{t('more')}</Typography.Text>
                    </Tooltip>
                  ) : null,
              }}
            >
              {rowData?.error}
            </Typography.Paragraph>
          ) : (
            '-'
          );
        }
        return '-';
      },
    },
  ];
};

export const StaticFilterKey = {
  ID: 'id',
  STATUS: 'status',
};
