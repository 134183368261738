import { getActiveSkuUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useActiveSkuQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.ACTIVE_SKU, params],
    queryFn: () => HTTP.get(getActiveSkuUrl, { params }),
  });

  return {
    activeSku: data?.active_sku,
    isFetchingActiveSku: isFetching,
  };
}
