export const QueryKey = {
  ATTRIBUTE: 'attribute',
  ATTRIBUTES: 'attributes',
  APPLICATIONS: 'applications',
  ATTRIBUTE_CONFIGS: 'attributeConfigs',
  ATTRIBUTE_OPTIONS: 'attributeOptions',
  ATTRIBUTE_SET: 'attributeSet',
  ATTRIBUTE_SETS: 'attributeSets',
  ATTRIBUTE_VALUES: 'attributeValues',
  BILLING_ADDRESS: 'billingAddress',
  BASKET_OFFER: 'basketOffer',
  BASKET_OFFER_PARTICIPANTS: 'basketOfferParticipants',
  CANCELLATION: 'cancellationRequests',
  CANCELLATION_REASON: 'cancellationReason',
  CANCELLATION_REASONS: 'cancellationReasons',
  CANCELLATION_REASON_OPTIONS: 'cancellationReasonOptions',
  CANCELLATION_PLANS: 'cancellationPlans',
  CANCELLATION_PLAN_ORDER_ITEMS: 'cancellationPlanOrderItems',
  CATEGORIES: 'categories',
  CATEGORY_NODE_PERMISSIONS: 'categoryNodePermissions',
  CITY: 'city',
  COLLECTIONS: 'collections',
  COLLECTION_DETAIL: 'collectionDetail',
  COLLECTION_ITEMS: 'collectionItems',
  CONVERSATIONS: 'conversations',
  COUNTRY: 'country',
  COUNTRIES: 'countries',
  CITIES: 'cities',
  TOWNSHIPS: 'townships',
  DISTRICTS: 'districts',
  DASHBOARD: 'dashboard',
  DATA_SOURCES: 'dataSources',
  DATA_SOURCE_DETAIL: 'dataSourceDetail',
  DATA_SOURCE_LANDING_PAGES: 'dataSourceLandingPages',
  DATA_SOURCE_LANDING_PAGE: 'dataSourceLandingPage',
  DISTRICT: 'district',
  DOWNLOAD: 'download',
  DYNAMIC_SETTINGS: 'dynamicSettings',
  DYNAMIC_SETTINGS_REGISTRY: 'dynamicSettingsRegistry',
  DOCUMENT: 'document',
  EXPORT: 'export',
  EXPORTS: 'exports',
  IMPORTS: 'imports',
  IMPORT_DETAIL: 'importDetail',
  IMPORT_OPTIONS: 'importOptions',
  EXPORT_PRE_OFFERS: 'exportPreOffers',
  EXPORT_PRODUCT_PRICES: 'exportProductPrices',
  EXPORT_PRODUCT_STOCKS: 'exportProductStocks',
  EXPORT_ORDERS: 'exportOrders',
  EXPORT_PACKAGES: 'exportPackages',
  EXPORT_PRODUCT_OFFERS: 'exportProductOffers',
  REJECTION_REASONS: 'rejectionReasons',
  REJECTIONS: 'rejections',
  PRODUCT: 'product',
  PRODUCT_LOWEST_PRICE: 'productLowestPrice',
  PACKAGES: 'packages',
  ORDERS: 'orders',
  QUICK_REPLY: 'quickReply',
  QUICK_REPLIES: 'quickReplies',
  PRODUCT_META: 'productMeta',
  WIDGET_SCHEMA: 'widgetSchema',
  WIDGETS: 'widgets',
  RESET_PASSWORD: 'resetPassword',
  PRODUCT_COLLECTION_WIDGETS: 'productCollectionWidgets',
  PRODUCT_STOCKS: 'productStocks',
  PRODUCT_PRICES: 'productPrices',
  GENERATE_LABEL: 'generateLabel',
  IMPORT_CHUNK: 'importChunk',
  INTEGRATION_ACCESS_TOKENS: 'integrationAccessQuery',
  LANGUAGES: 'languages',
  ORDER_DETAIL: 'orderDetail',
  ORDER_ITEMS: 'orderItems',
  PACKAGE_DETAIL: 'packageDetail',
  PACKAGE_ITEMS: 'packageItems',
  PACKAGE_STATUSES: 'packageStatuses',
  PRE_OFFER: 'preOffer',
  PRE_OFFERS: 'preOffers',
  PRE_OFFER_IMAGES: 'preOfferImages',
  PRICE_LISTS: 'priceLists',
  PRODUCTS: 'products',
  PRODUCT_ATTRIBUTES: 'productAttributes',
  PRODUCT_IMAGES: 'productImages',
  PRODUCT_OFFERS: 'productOffers',
  PRODUCT_OFFER: 'productOffer',
  RESTRICTED_PRODUCTS: 'restrictedProducts',
  PRODUCT_PRICES_OPTIONS: 'productPricesOptions',
  PRODUCT_REVISIONS: 'productRevisions',
  SHIPMENT_COMPANY_OPTIONS: 'shipmentCompanyOptions',
  SHIPMENT_COMPANY_CONFIG: 'shipmentCompanyConfig',
  SHIPPING_COMPANIES: 'shippingCompanies',
  SHIPPING_COMPANY: 'shippingCompany',
  SHIPPING_METHOD: 'shippingMethod',
  SHIPPING_OPTION: 'shippingOption',
  SHIPPING_OPTION_DETAIL: 'shippingOptionDetail',
  SHIPPING_OPTIONS: 'shippingOptions',
  STOCK_LISTS: 'stockLists',
  PRODUCT_COLLECTION_WIDGET: 'productCollectionWidget',
  IMPORT_STATUS: 'importStatus',
  IMPORT_STATUS_PROGRESS: 'importStatusProgress',
  TOWNSHIP: 'township',
  USER: 'user',
  USER_ME: 'userMe',
  USERS: 'users',
  TICKET: 'ticket',
  TICKETS: 'tickets',
  TICKET_MESSAGES: 'ticketMessages',
  VARIANTS_META: 'variantsMeta',
  WIDGET: 'widget',
  SHIPPING_COMPANY_OPTION: 'shippingCompanyOption',
  SHIPPING_COMPANY_OPTIONS: 'shippingCompanyOptions',
  SHIPPING_COMPANY_OPTIONS_HTTP_OPTIONS: 'shippingCompanyOptionsHttpOptions',
  DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIGS: 'dataSourceShippingCompanyOptionConfigs',
  DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIG: 'dataSourceShippingCompanyOptionConfig',
  INTEGRATION_ERRORS: 'integrationErrors',
  CONTENT_TYPE: 'contentType',
  CONTENT_TYPES: 'contentTypes',
  EXPORT_ORDER_ITEMS: 'exportOrderItems',
  EXPORT_PACKAGE_ITEMS: 'exportPackageItems',
  OAUTH_PROVIDERS: 'oAuthProviders',
  OAUTH_OPTIONS: 'oAuthOptions',
  OAUTH_PROVIDERS_BY_SLUG: 'oAuthProvidersBySlug',
  ACTIVE_SKU: 'activeSku',
  TOTAL_STOCK: 'totalStock',
  TOTAL_AMOUNT: 'totalAmount',
  TOTAL_ITEMS: 'totalItems',
  FULFILLMENT_TIME: 'fulfillmentTime',
  GRADE: 'grade',
  CANCEL_RATIO: 'cancelRatio',
  MOST_SELLING_PRODUCTS: 'mostSellingProducts',
  MOST_SELLING_CATEGORIES: 'mostSellingCategories',
  CANCELLATION_REFUND_COUNT: 'cancellationRefundCount',
  ACTION_REQUIRED_PACKAGES: 'actionRequiredPackages',
  MASTER_PRODUCT_PRICES: 'masterProductPrices',
  NOTIFICATIONS: 'notifications',
  RECONCILIATION_RULES: 'reconciliationRules',
  ANNOUNCEMENTS: 'announcements',
  AGREEMENTS: 'agreements',
  AGREEMENT_DETAIL: 'agreementDetail',
  AGREEMENT_CONSENTS: 'agreementConsents',
  AGREEMENT_CONSENT_DETAIL: 'agreementConsentDetail',
  LOCATIONS: 'locations',
  LOCATION_DETAIL: 'locationDetail',
  SELLER_PERFORMANCE_METRICS: 'sellerPerformanceMetrics',
  SALES_REPORTS: 'salesReports',
  CATEGORIES_REPORT: 'categoriesReport',
  ADD_ALL_PRODUCTS_IMPORT_STATUS: 'addAllProductsImportStatus',
  BULK_PRODUCT_STATE_STATUS: 'bulkProductStateStatus',
  RECONCILIATION_REBATE_RULES: 'reconciliationRebateRules',
  PRE_OFFER_BULK_STATE_STATUS: 'preOfferBulkStateStatus',
};
