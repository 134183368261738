import './style.scss';

import AkinonProgressModal from '@components/AkinonProgressModal';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BulkActionModal = ({
  CustomModal,
  onStartBulkAction,
  isModalVisible,
  isProgressModalVisible,
  onCloseModal,
  onCloseProgressModal,
  totalRequestCount,
  successfulRequestCount,
  failedRequestCount,
  modalProps,
  progressModalProps,
}) => {
  const { t } = useTranslation();

  const { description, ...restModalProps } = modalProps;
  const isDone = successfulRequestCount + failedRequestCount === totalRequestCount;
  const FailedRequestInfo = () => {
    if (failedRequestCount > 0) {
      return (
        <div className="bulk-action-modal__info-line--error">
          {failedRequestCount} {t('failed.requests')}
        </div>
      );
    } else return null;
  };
  const SuccessfulRequestInfo = () => {
    if (successfulRequestCount > 0) {
      return (
        <div className="bulk-action-modal__info-line--success">
          {successfulRequestCount} {t('successful.requests')}
        </div>
      );
    } else return null;
  };
  const shouldShowErrorMessage = isDone && failedRequestCount > 0;

  const requestCount = successfulRequestCount + failedRequestCount;
  const progressText =
    !(requestCount > 1 && totalRequestCount === 0) && `${requestCount} / ${totalRequestCount}`;

  return (
    <>
      {CustomModal ? (
        <CustomModal
          open={isModalVisible}
          onStartBulkAction={onStartBulkAction}
          onCancel={onCloseModal}
          {...restModalProps}
        />
      ) : (
        <Modal
          width={800}
          open={isModalVisible}
          onOk={onStartBulkAction}
          onCancel={onCloseModal}
          okText={t('modal_ok')}
          cancelText={t('modal_cancel')}
          {...restModalProps}
        >
          {description && <div>{description}</div>}
        </Modal>
      )}
      <AkinonProgressModal
        progressProps={{
          strokeColor: 'red',
          percent: ((failedRequestCount + successfulRequestCount) / totalRequestCount) * 100,
          success: { percent: (successfulRequestCount / totalRequestCount) * 100 },
          format: () => progressText,
        }}
        modalProps={{
          open: isProgressModalVisible,
          onCancel: onCloseProgressModal,
          ...progressModalProps,
        }}
        errorMessage={
          shouldShowErrorMessage ? t('bulk.action.error.message', { failedRequestCount }) : null
        }
      >
        <div>
          <FailedRequestInfo />
          <SuccessfulRequestInfo />
        </div>
      </AkinonProgressModal>
    </>
  );
};

export default BulkActionModal;
