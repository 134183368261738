import { getCancellationRefundCountUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCancellationRefundCountQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.CANCELLATION_REFUND_COUNT, params],
    queryFn: () => HTTP.get(getCancellationRefundCountUrl, { params }),
  });

  return {
    cancellationRefundCount: data?.cancellation_refund_count,
    isFetchingCancellationRefundCount: isFetching,
  };
}
