import { updateAuthObject } from '@common/auth';
import { createLogCenterRecord } from '@common/index';
import { getPathFragments } from '@common/routing';
import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/AkinonNotification';
import { postRefreshTokenUrl, postTokenObtainUrl } from '@constants/apiUrls';
import { AUTHORIZATION_HEADER, HttpStatus } from '@constants/auth';
import { Language } from '@constants/locale';
import { PublicRoutes } from '@constants/routeUrls';
import i18n from '@root/i18n';
import useStore from '@zustand-store/index';
import { Space, Typography } from 'antd';
import axios from 'axios';
import { toast } from 'sonner';

import { HTTP } from '.';
import { Auth } from './auth-adapter';
import { getApiUrl } from './constants';

const { Text } = Typography;

export const abortControllers = new Map();

export const getControllerSignal = () => {
  const currentPage = window.location.pathname;
  let controllers = abortControllers.get(currentPage);

  if (!controllers) {
    controllers = [];
    abortControllers.set(currentPage, controllers);
  }

  const controller = new AbortController();
  controllers.push(controller);
  return controller.signal;
};

export const cancelPreviousRequests = (pagePath) => {
  const controllers = abortControllers.get(pagePath);
  if (controllers) {
    controllers.forEach((controller) => controller.abort());
    abortControllers.delete(pagePath);
  }
};

export const logErrorsToLogCenter = ({ apiErrorMessages }) => {
  useStore.getState().addLogCenterRecords(
    apiErrorMessages.map((apiErrorMessage) =>
      createLogCenterRecord({
        type: 'error',
        message: i18n.t('api.request.errorMessage'),
        description: apiErrorMessage,
      })
    )
  );
};

export const openSuccessNotification = ({ response }) => {
  i18n.loadLanguages(Object.values(Language), () => {
    openNotification({
      message: response?.config?.successMessage ?? i18n.t('api.request.successMessage'),
      description: response?.config?.successDescription ?? (
        <Space direction="vertical">
          <Space direction="vertical" size="small">
            <Text style={{ color: 'white' }}>{i18n.t('api.request.successMessage')}</Text>
          </Space>
        </Space>
      ),
    });
  });
};

export const openErrorNotification = ({ responseError, apiErrorMessages }) => {
  const showCheckLog = responseError?.response?.config?.showCheckLog ?? true;

  openNotification({
    duration: 20000,
    message: responseError?.response?.config?.errorMessage ?? i18n.t('api.request.errorMessage'),
    type: 'error',
    description: responseError?.response?.config?.errorDescription ? (
      showCheckLog ? (
        <Space direction="vertical">
          <Text style={{ color: 'white' }}>
            {responseError?.response?.config?.errorDescription?.concat(
              i18n.t('api.check_log.description')
            )}
          </Text>
          <AkinonButton
            className="text-blue-jeans border-none"
            type="ghost"
            onClick={() => {
              toast.dismiss();
              useStore.getState().setIsLogCenterOpen(true);
            }}
          >
            {i18n.t('api.check_log.title')}
          </AkinonButton>
        </Space>
      ) : (
        responseError?.response?.config?.errorDescription
      )
    ) : (
      <Space direction="vertical">
        <Space direction="vertical" size="small">
          {apiErrorMessages.map((apiErrorMessage, index) => (
            <Text style={{ color: 'white' }} key={index}>
              {apiErrorMessage}
            </Text>
          ))}
        </Space>
      </Space>
    ),
  });
};

export const handleSuppressErrorNotification = ({ responseError }) => {
  // token_not_valid is handled in tryToRefreshToken()
  if (responseError?.response?.data?.code === 'token_not_valid') throw responseError;

  const { suppressErrorNotifications, shouldSuppressErrorNotification } =
    responseError?.response?.config ?? {};

  if (suppressErrorNotifications) throw responseError;

  if (shouldSuppressErrorNotification) {
    const shouldSuppressNotification =
      responseError.response.config.shouldSuppressErrorNotification(responseError.response);
    if (shouldSuppressNotification) throw responseError;
  }
};

export const handleNoInternetMessage = ({ responseError }) => {
  if (responseError.code === 'ECONNABORTED') {
    responseError.message =
      i18n.t('api.request.timeoutErrorMessage') + i18n.t('api.request.timeoutErrorMessageDesc');
  }
};

export const attemptTokenRefresh = async ({
  responseError,
  shouldRedirectToSignIn,
  expiredAccessToken,
}) => {
  const originalRequest = responseError.config;
  // wait & retry all failed requests while we were getting a new token
  const accessToken = Auth.accessToken;
  if (accessToken !== expiredAccessToken) {
    originalRequest.headers.Authorization = `${AUTHORIZATION_HEADER} ${accessToken}`;
    return HTTP(originalRequest);
  }

  const refreshToken = Auth.refreshToken;

  const reset = () => {
    openNotification({
      duration: 20000,
      type: 'error',
      message: i18n.t('session.expired'),
      description: i18n.t('session.expired.description'),
    });
    shouldRedirectToSignIn && signOut();
    return Promise.reject(responseError);
  };

  const isSignInRequest = originalRequest.url === postTokenObtainUrl;
  const isUnauthorized = responseError?.response?.status === HttpStatus.UNAUTHORIZED;
  const shouldTryToObtainToken = !isSignInRequest && isUnauthorized;
  if (!shouldTryToObtainToken) {
    return null;
  }

  try {
    const response = await axios.post(
      getApiUrl(postRefreshTokenUrl),
      { refresh: refreshToken },
      {
        'Content-Type': 'application/json',
      }
    );
    const newAccessToken = response?.data?.access;
    if (newAccessToken) {
      updateAuthObject(accessToken, response?.data);
      const newAuthHeader = `${AUTHORIZATION_HEADER} ${newAccessToken}`;
      originalRequest.headers.Authorization = newAuthHeader;
      return HTTP(originalRequest);
    } else {
      reset();
    }
  } catch (e) {
    reset();
  }
};

export function signOut() {
  const currentUrl = getPathFragments(window.location.pathname).mainPath;
  // FIXME: This is a temporary solution. We should handle this in a better way.
  const isPublicRoute = PublicRoutes.some((route) =>
    currentUrl.includes(route.replace(/:\w+/g, ''))
  );

  if (isPublicRoute) return;
  Auth.clear();
}
