import { getMasterProductPricesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useMasterProductPricesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isLoading, error, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.MASTER_PRODUCT_PRICES, params],
    async queryFn() {
      const response = await HTTP.get(getMasterProductPricesUrl, {
        params: {
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    masterProductPrices: data?.results,
    isMasterProductPricesLoading: isLoading,
    isFetchingMasterProductPrices: isFetching,
    masterProductPricesError: error,
  };
}
