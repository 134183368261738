import {
  deleteProductOffersUrl,
  getProductOffersUrl,
  getProductOfferUrl,
  getProductStockListUrl,
  getRejectionsUrl,
  patchProductOffersUrl,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getProductOffers = (params) => {
  return HTTP.get(getProductOffersUrl, { params });
};

export const getProductStockList = ({ requestConfig }) => {
  return HTTP.get(getProductStockListUrl, requestConfig);
};

export const getProductOffer = ({ offerId, requestConfig }) => {
  return HTTP.get(getProductOfferUrl, { ...requestConfig, urlParams: { offerId } });
};

export const getRejections = (params) => {
  return HTTP.get(getRejectionsUrl, { params });
};

export const deleteProductOffers = ({ offerId, requestConfig }) => {
  return HTTP.delete(deleteProductOffersUrl, { ...requestConfig, urlParams: { offerId } });
};

export const patchProductOffers = ({ offerId, requestBody }) => {
  return HTTP.patch(patchProductOffersUrl, requestBody, {
    urlParams: { offerId },
  });
};
