import { getShippingCompanyOptionUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useShippingCompanyOptionQuery({ optionId, queryOptions }) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_COMPANY_OPTION, optionId],
    queryFn: () => HTTP.get(getShippingCompanyOptionUrl, { urlParams: { optionId } }),
    ...queryOptions,
  });

  return {
    shippingCompanyOption: data,
    isShippingCompanyOptionLoading: isLoading,
    isFetchingShippingCompanyOption: isFetching,
  };
}
