import './styles.scss';

import { getPathFragments } from '@common/routing';
import useSelectedKeys from '@hooks/useSelectedKeys';
import usePageData from '@zustand-store/hooks/usePageData';
import { Col, Row, Typography } from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';

import { getFilterFields, getFilterSchema, getInitialValues } from './common';
import FilterOptions from './components/FilterOptions';
import FiltersForm from './components/FiltersForm';
import useFiltersSlice from './hooks/store/useFiltersSlice';

const { Title } = Typography;

const AkinonFilter = ({
  tableName,
  title,
  showFileImport = false,
  total,
  filterFields,
  actions,
  filterActions,
}) => {
  const { clearSelectedRowKeys } = useSelectedKeys({
    tableName,
  });
  const location = useLocation();
  const searchQuery = get(location, ['state', 'searchQuery']);
  const { t } = useTranslation();
  const { pageFilters, setFilters } = useFiltersSlice();
  const [isFilterOptionsModalOpen, setIsFilterOptionsModalOpen] = useState(false);
  const { pageData, setPageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];
  const setVisibleFilters = (newVisibleFilters) =>
    setPageData({ visibleFilters: newVisibleFilters });

  const filterSchema = getFilterSchema({ filterFields });

  const form = useForm({
    defaultValues: {},
  });
  const { control, reset } = form;

  const allFields = useMemo(
    () =>
      filterFields.dynamic.isLoading
        ? []
        : getFilterFields({
            filterFields,
            form,
          }),
    [filterFields]
  );

  const formValues = useWatch({ control });
  const { pathname, search } = location;
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  const onSetFilters = useCallback(
    debounce((formValues) => {
      const sanitizeValues = (obj) =>
        mapValues(obj, (value) => {
          if (typeof value === 'string') {
            const trimmedValue = value.trim();
            return trimmedValue === '' ? null : trimmedValue;
          }
          return isNil(value) ? null : value;
        });

      const sanitizedFilters = sanitizeValues(formValues);

      setFilters({
        pagePath: filterKey,
        filters: sanitizedFilters,
      });
    }, 700),
    [filterKey]
  );

  useEffect(() => {
    if (pageData?.visibleFilters || filterFields.dynamic.isLoading) return;
    const filterInitiallyVisibleFields = (field) => field.isInitiallyVisible;

    setVisibleFilters(allFields.filter(filterInitiallyVisibleFields).map((field) => field.name));
  }, [allFields, pageData?.visibleFilters, filterFields.dynamic.isLoading]);

  useEffect(() => {
    const parsedFormValues = filterSchema.safeParse(formValues).data;
    onSetFilters(parsedFormValues);
  }, [formValues]);

  useMount(() => {
    const initialValues = getInitialValues({
      pageFilters,
      fields: allFields,
      searchQuery,
    });
    reset(initialValues);
  });

  const applyFilters = (formValues) => {
    const parsedFormValues = filterSchema.safeParse(formValues).data;
    onSetFilters(parsedFormValues);
  };

  useUnmount(() => {
    reset();
  });

  return (
    <section className="box-primary form-box akinon-filter">
      <Row justify="space-between" align="middle" className="akinon-filter__header">
        <Col>
          <Title className="akinon-filter__title" level={3}>
            {title ?? t('filters')}
          </Title>
        </Col>
        <Col>
          <FilterOptions
            filterForm={form}
            setVisibleFilters={setVisibleFilters}
            showFileImport={showFileImport}
            clearSelectedRowKeys={clearSelectedRowKeys}
            filterActions={filterActions}
            filterFields={allFields}
            isDynamicFiltersLoading={filterFields.dynamic.isLoading}
            isFilterOptionsModalOpen={isFilterOptionsModalOpen}
            setIsFilterOptionsModalOpen={setIsFilterOptionsModalOpen}
          />
        </Col>
      </Row>
      <FiltersForm
        form={form}
        visibleFilters={visibleFilters}
        setVisibleFilters={setVisibleFilters}
        filterFields={allFields}
        applyFilters={applyFilters}
        total={total}
        searchQuery={searchQuery}
        actions={actions}
      />
    </section>
  );
};

export default React.memo(AkinonFilter);
