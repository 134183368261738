import { getPackagesIdUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function usePackageDetailQuery({ packageId, queryOptions, onSuccess }) {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PACKAGE_DETAIL, packageId],
    async queryFn() {
      const response = await HTTP.get(getPackagesIdUrl, {
        urlParams: { packageId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    packageDetail: data,
    isPackageDetailLoading: isLoading,
    packageDetailError: error,
  };
}
