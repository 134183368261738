/**
 * @typedef {{ label: string, onTableAction: (selectedRows: any[]) => void, isSelectionRequired: boolean, isSingleSelectionRequired: boolean, isDisabled: boolean, className: string }} TableAction
 * @typedef {{ label: string, onTableAction: (selectedRows: any[]), icon: JSX.Element }} ExportOption
 */

/**
 * @param {TableAction[]} actions
 * @returns {TableAction[]}
 */
export const createTableActions = (actions) => actions;

/**
 * @param {ExportOption[]} exportOptions
 * @returns {ExportOption[]}
 */
