import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { defaultRowKey } from '@constants/index';
import useSelectedKeys from '@hooks/useSelectedKeys';
import { getDynamicColumns } from '@pages/ProductsAndCategories/common';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useProductQuery } from '@services/api/hooks/useProductQuery';
// import { useProductQuery } from '@services/hooks';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import usePageData from '@zustand-store/hooks/usePageData';
import useStore from '@zustand-store/index';
import { setSelectedRelatedProductSelector } from '@zustand-store/selectors/preOffer';
import { first } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

const RelatedProductsTable = ({ categories, preOffer }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { product } = useProductQuery({
    productId: preOffer?.product,
    queryOptions: { enabled: Boolean(preOffer?.product) },
  });
  const setSelectRelatedProduct = useStore(setSelectedRelatedProductSelector);
  const [pagination, setPagination] = usePagination();
  const { selectedRowKeys, getRowSelection } = useSelectedKeys();

  const { attributes, isAttributesLoading } = useAttributesQuery();
  const filterFields = useFilterFields({
    product,
    attributes,
    isAttributesLoading,
  });
  const filters = useFilterParams({ filterFields });
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const { dataSource, isDataSourceLoading, total } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const columns = getColumns({ t, categories });

  const onSetSearchRelatedProduct = useCallback(
    (selectedRowKeys) => {
      const firstSelectedProduct = first(
        dataSource?.filter?.((item) => selectedRowKeys.includes(item?.id))
      );
      if (firstSelectedProduct) {
        setSelectRelatedProduct(firstSelectedProduct);
      }
    },
    [dataSource]
  );

  useEffect(() => {
    onSetSearchRelatedProduct(selectedRowKeys);
  }, [selectedRowKeys]);

  return (
    <section className="related-products">
      <AkinonFilter filterFields={filterFields} />
      <AkinonTable
        title={t('relatable.products')}
        columns={columns}
        dataSource={dataSource}
        total={total}
        rowKey={defaultRowKey}
        pagination={pagination}
        loading={isDataSourceLoading}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        rowSelection={getRowSelection({
          type: 'radio',
        })}
        enableDynamicColumns
      />
    </section>
  );
};

export default RelatedProductsTable;
