import { PluginRenderer } from '@akinon/app-shell';
import { createSelectOptions } from '@common/index';
import { createTableActions } from '@components/AkinonTable/common';
import DynamicFormElement from '@components/DynamicFormElement';
import ImageTableHead from '@components/ImageTableHead';
import Intrinsic from '@components/Intrinsic';
import SmallColumnImage from '@components/SmallColumnImage';
import TitleWithHint from '@components/TitleWithHint';
import TooltipInput from '@components/TooltipInput';
import { WidgetType } from '@constants/commontypes';
import {
  getFloatSchema,
  getIntegerSchema,
} from '@pages/ProductsAndCategories/OfferList/OfferDetail/common';
import i18n from '@root/i18n';
import { Button, Tooltip } from 'antd';
import { uniq } from 'lodash';
import omit from 'lodash/omit';
import set from 'lodash/set';
import React from 'react';
import { z } from 'zod';

export const ProductsToBeAddedTableKey = {
  PRODUCT_IMAGE: 'product_image',
  SKU: 'sku',
  SELLER_BARCODE: 'seller_barcode',
  STOCK: 'stock',
  RETAIL_PRICE: 'retail_price',
  PRICE: 'price',
  REFERENCE_PRICE: 'reference_price',
  CURRENCY_TYPE: 'currency_type',
  TAX_RATE: 'tax_rate',
  TRANSACTIONS: 'transactions',
};

export const ProductsToBeAddedTableFormFormItemKey = {
  ...ProductsToBeAddedTableKey,
};

export const PRODUCT_ATTRIBUTES = 'PRODUCT_ATTRIBUTES';

export const getInitialTableFormData = ({ productSummariesDataSource, buyboxPrice }) => {
  return productSummariesDataSource.reduce((acc, row) => {
    const productId = row.id;

    Object.entries(row).forEach(([key, value]) => {
      const targetPath = [productId, key].join('.');
      set(acc, targetPath, value);
    });
    const referencePriceKey = [productId, ProductsToBeAddedTableKey.REFERENCE_PRICE].join('.');
    set(acc, referencePriceKey, buyboxPrice);
    return acc;
  }, {});
};

export const getProductsToBeAddedTableFormSchema = ({ t }) => {
  const integerSchema = getIntegerSchema({ t });
  const floatSchema = getFloatSchema({ t });

  return z.record(
    z.object({
      [ProductsToBeAddedTableKey.SELLER_BARCODE]: z.string().min(1),
      [ProductsToBeAddedTableKey.SKU]: z.string().min(1),
      [ProductsToBeAddedTableKey.STOCK]: integerSchema,
      [ProductsToBeAddedTableKey.RETAIL_PRICE]: floatSchema,
      [ProductsToBeAddedTableKey.PRICE]: floatSchema,
      [ProductsToBeAddedTableKey.CURRENCY_TYPE]: z.string().min(1),
      [ProductsToBeAddedTableKey.TAX_RATE]: floatSchema,
    })
  );
};

export const getColumns = ({
  t,
  variantsFormDynamicMetaFields,
  variantsListAttributeMetaFields,
  dataSource,
  form,
  productPricesOptions,
  isProductPricesOptionsLoading,
  setIsProductImagesModalVisible,
  isMobile,
  isSuperUser,
  setRowsToHide,
}) => {
  const variantColumns = variantsFormDynamicMetaFields.map((metaField) => ({
    dataIndex: metaField.key,
    key: metaField.key,
    title: metaField.label,
    render: (cellData) => <Tooltip title={cellData}>{cellData}</Tooltip>,
  }));

  const getTitlePrefix = (isRequired) => (isRequired ? '*' : '');
  const variantAttributeColumns = variantsListAttributeMetaFields.map((metaField) => ({
    dataIndex: metaField.key,
    key: metaField.key,
    title: `${getTitlePrefix(metaField.required)} ${metaField.label}`,
    disabled: !metaField.is_offer,
    required: metaField.required,
    options: metaField?.options ?? [],
    widget: metaField.widget,
  }));

  const isThereOneProduct = dataSource.length === 1;
  const selectableColumns = [ProductsToBeAddedTableKey.CURRENCY_TYPE];
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const inputColumns = [
    {
      dataIndex: ProductsToBeAddedTableKey.PRODUCT_IMAGE,
      title: <ImageTableHead />,
      render: (productImage) => {
        const onImageClick = () => {
          setIsProductImagesModalVisible(true);
        };
        return <SmallColumnImage onClick={onImageClick} src={productImage} />;
      },
      align: 'center',
    },
    {
      dataIndex: ProductsToBeAddedTableKey.SKU,
      title: '* seller sku',
      required: true,
      customWidget: (inputData) => {
        return (
          <div>
            <TooltipInput data={inputData} />
            <PluginRenderer
              placeholderId="ui-protocol-product-detail-sku"
              params={{ sku: inputData?.value }}
            />
          </div>
        );
      },
    },
    {
      dataIndex: ProductsToBeAddedTableKey.SELLER_BARCODE,
      title: '* seller barcode',
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.STOCK,
      title: `* ${t('stock')}`,
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.RETAIL_PRICE,
      title: `* ${t('psf')}`,
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.PRICE,
      title: `* ${t('selling.price')}`,
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.REFERENCE_PRICE,
      title: (
        <TitleWithHint
          text={t('reference_price').toLocaleUpperCase(i18n.language)}
          hint={t('reference_price_desc')}
        />
      ),
      disabled: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.CURRENCY_TYPE,
      title: `* ${t('currency')}`,
      options: currencyOptions,
      isLoading: isProductPricesOptionsLoading,
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableKey.TAX_RATE,
      title: '* vat',
      required: true,
    },
    ...variantAttributeColumns,
    !isThereOneProduct && {
      dataIndex: ProductsToBeAddedTableKey.TRANSACTIONS,
      title: t('transactions'),
      fixed: isMobile ? false : 'right',
      render: ({ deleteRow }) => {
        return (
          <Button onClick={deleteRow} className="row-action">
            <Intrinsic className="icon-bin" />
          </Button>
        );
      },
    },
  ]
    .filter(Boolean)
    .map((column) => {
      const shouldHaveTooltip = !column?.render;
      if (shouldHaveTooltip) {
        column.widget = (data) => <TooltipInput data={data} />;
        if (column?.customWidget) {
          column.widget = column.customWidget;
        }
      }
      return {
        ...column,
        disabled: isSuperUser ? true : column.disabled,
        render: (cellData, rowData, rowIndex) => {
          const deleteRow = () => {
            form.unregister(productId);
            setRowsToHide((prev) => uniq([...prev, rowData.id]));
          };
          const isColumnSelectable = selectableColumns.includes(column.dataIndex);
          const isProductImageColumn = column.dataIndex === ProductsToBeAddedTableKey.PRODUCT_IMAGE;

          if (isProductImageColumn) {
            return column.render(cellData, rowData, rowIndex);
          }

          const shouldCustomRender = [ProductsToBeAddedTableKey.TRANSACTIONS].includes(
            column.dataIndex
          );

          const productId = rowData.id;

          return shouldCustomRender ? (
            column.render({ deleteRow })
          ) : (
            <DynamicFormElement
              formItemProps={{
                control: form.control,
                name: `${productId}.${column.dataIndex}`,
                required: column.required,
              }}
              widget={isColumnSelectable ? WidgetType.SELECT : column.widget}
              options={column?.options ?? []}
              disabled={isSuperUser ? true : column.disabled}
              isLightTheme
            />
          );
        },
      };
    });

  return [...variantColumns, ...inputColumns];
};

export const getTableActions = ({
  t,
  dataSource,
  onShowProductRevisionModal,
  setIsBulkUpdatePriceModalVisible,
  setIsBulkUpdateStockModalVisible,
}) => {
  return createTableActions(
    [
      {
        label: t('add.bulk.price'),
        onTableAction: () => {
          setIsBulkUpdatePriceModalVisible(true);
        },
      },
      {
        label: t('add.bulk.stock.amount'),
        onTableAction: () => {
          setIsBulkUpdateStockModalVisible(true);
        },
      },
      {
        label: t('product_revision'),
        onTableAction: () => {
          onShowProductRevisionModal();
        },
      },
    ].map((tableAction) => ({
      ...tableAction,
      isDisabled: dataSource.length === 0,
    }))
  );
};

export const getDataSource = ({ productMeta }) => {
  return productMeta?.map((productMetaItem) => ({
    ...omit(productMetaItem, ['attributes']),
    ...productMetaItem.attributes,
  }));
};
