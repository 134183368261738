import './style.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonDividerBox from '@components/AkinonDividerBox';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@root/contexts/hooks/useUser';
import { useDeleteReconciliationRebateRuleMutation } from '@services/api/hooks/useDeleteReconciliationRebateRuleMutation';
import { usePostReconciliationRebateRuleMutation } from '@services/api/hooks/usePostReconciliationRebateRuleMutation';
import { useReconciliationRebateRulesQuery } from '@services/api/hooks/useReconciliationRebateRulesQuery';
import { queryClient } from '@services/api/queryClient';
import { IconCheckbox, IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Col, Typography } from 'antd';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getCategoryBasedRebateFormSchema, ReconciliationRebateType } from './common';

const { Text } = Typography;

const CategoryBasedRebateRule = () => {
  const { t } = useTranslation('Finance');
  const schema = getCategoryBasedRebateFormSchema({ t });
  const { postReconciliationRebateRule } = usePostReconciliationRebateRuleMutation();
  const { deleteReconciliationRebateRule } = useDeleteReconciliationRebateRuleMutation();
  const { id } = useParams();
  const user = useUser();
  const userRole = useUserRole();
  const dataSourceId = id ?? user?.datasource;
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const isDisabled = !isSuperUser;

  const reconciliationRebateRulesParams = isSuperUser
    ? {
        datasource: dataSourceId,
        is_active: true,
      }
    : {};

  const { reconciliationRebateRules, isFetchingReconciliationRebateRules } =
    useReconciliationRebateRulesQuery({
      params: reconciliationRebateRulesParams,
    });
  const categoryBasedReconciliationRebateRules = reconciliationRebateRules?.filter?.(
    (rule) => rule?.category_node
  );

  const { control, reset, trigger, getValues } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const formData = useWatch({
    control,
    name: 'formData',
  });

  useEffect(() => {
    if (!categoryBasedReconciliationRebateRules?.length) return;

    const parsedInitialValues = schema.safeParse({
      formData:
        categoryBasedReconciliationRebateRules?.map((rule) => ({ ...rule, ruleId: rule.id })) ?? [],
    });
    if (parsedInitialValues.success) {
      reset({ ...parsedInitialValues.data });
    }
  }, [reconciliationRebateRules]);

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      disableItemBy: (item) => Boolean(item?.numchild),
    },
  });

  const invalidateReconciliationRules = () => {
    queryClient.invalidateQueries([
      QueryKey.RECONCILIATION_REBATE_RULES,
      reconciliationRebateRulesParams,
    ]);
  };

  const disableTreeNodes = useCallback(
    (data) => {
      return data?.map((item) => {
        if (item.children) {
          return {
            title: item.title,
            value: item.value,
            disabled:
              formData?.some?.((formItem) => formItem.category_node === item.value) ||
              item.disabled,
            key: item.value,
            children: disableTreeNodes(item.children),
          };
        }
        return {
          title: item.title,
          value: item.value,
          key: item.value,
        };
      });
    },
    [formData]
  );

  const _categoryTreeData = disableTreeNodes(categoryTreeData);

  return (
    <>
      <AkinonBox
        title={t('category_based_reconciliation')}
        bannerColor={Color.WILD_WATERMELON}
        className="box-primary form-box w-full shadow-md shadow-gray-900"
        shadow
      >
        <AkinonDividerBox header={<Text style={{ color: 'white' }}>{t('categories')}</Text>}>
          <AkinonSpin spinning={isFetchingReconciliationRebateRules}>
            <div
              style={{
                marginTop: 48,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              {fields.map((field, index) => {
                const _field =
                  schema.shape.formData.element.safeParse(getValues(`formData.${index}`)).data ??
                  {};
                const reconciliationRule = categoryBasedReconciliationRebateRules?.find(
                  ({ id }) => id === _field.ruleId
                );
                const _reconciliationRule = schema.shape.formData.element.safeParse({
                  ...pick(reconciliationRule, keys(_field)),
                  ruleId: _field.ruleId,
                }).data;

                const isFieldChanged = !isEqual(_reconciliationRule, _field);
                return (
                  <AkinonForm layout="vertical" isFlex key={field.id}>
                    <AkinonFormItem
                      label={t('category')}
                      name={`formData.${index}.category_node`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                      wrapperCol={{ span: 24 }}
                    >
                      <AkinonTreeSelect
                        loading={isCategoriesLoading}
                        treeData={_categoryTreeData}
                        treeNodeFilterProp={'title'}
                        showSearch
                        treeDefaultExpandAll
                        virtual={false}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        disabled={isDisabled}
                      />
                    </AkinonFormItem>
                    <AkinonFormItem
                      label={t('rebate_type')}
                      name={`formData.${index}.rebate_type`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                    >
                      <AkinonSelect
                        disabled={isDisabled}
                        options={[
                          {
                            label: t('amount_discount'),
                            value: ReconciliationRebateType.FIXED,
                          },
                          {
                            label: t('percentage'),
                            value: ReconciliationRebateType.DYNAMIC,
                          },
                        ]}
                      />
                    </AkinonFormItem>

                    <AkinonFormItem
                      label={t('discount_value')}
                      name={`formData.${index}.value`}
                      control={control}
                      isWithColumn
                      colSpan={7}
                    >
                      <AkinonInput disabled={isDisabled} />
                    </AkinonFormItem>

                    {!isDisabled && (
                      <Col style={{ alignSelf: 'center', display: 'inline-flex', gap: 2 }}>
                        <IconCheckbox
                          alignmentBaseline="middle"
                          size={24}
                          className={clsx('color-lima block', {
                            hidden: !isFieldChanged,
                          })}
                          cursor="pointer"
                          onClick={() => {
                            trigger(`formData.${index}`).then((isValid) => {
                              if (!isValid) return;
                              const existingRule = categoryBasedReconciliationRebateRules?.find(
                                (rule) => rule?.id === _field?.ruleId
                              );

                              const createRebateRule = () =>
                                postReconciliationRebateRule(
                                  {
                                    requestBody: {
                                      datasource: id,
                                      ...omit(_field, 'ruleId'),
                                    },
                                    requestConfig: {
                                      successMessage: t('transaction_success'),
                                      successDescription: t('changes_saved'),
                                      errorMessage: t('transaction_failed'),
                                      errorDescription: t('changes_error'),
                                    },
                                  },
                                  {
                                    onSuccess() {
                                      invalidateReconciliationRules();
                                    },
                                  }
                                );

                              existingRule
                                ? deleteReconciliationRebateRule(
                                    {
                                      ruleId: field.ruleId,
                                    },
                                    {
                                      onSuccess() {
                                        setTimeout(() => {
                                          createRebateRule();
                                        }, 1000);
                                      },
                                    }
                                  )
                                : createRebateRule();
                            });
                          }}
                        />
                        <IconCircleMinus
                          alignmentBaseline="middle"
                          size={24}
                          className="color-wild-watermelon"
                          cursor="pointer"
                          style={{ alignSelf: 'center' }}
                          onClick={() => {
                            if (field.ruleId) {
                              deleteReconciliationRebateRule(
                                {
                                  ruleId: field.ruleId,
                                },
                                {
                                  onSuccess() {
                                    invalidateReconciliationRules();
                                  },
                                }
                              );
                            } else {
                              remove(index);
                            }
                          }}
                        />
                      </Col>
                    )}
                  </AkinonForm>
                );
              })}

              <Col span={6}>
                <AkinonButton
                  icon={<IconCirclePlus alignmentBaseline="middle" size={20} className="white" />}
                  htmlType="button"
                  onClick={() =>
                    append({ ruleId: null, category_node: null, value: null, type: null })
                  }
                  type="primary"
                  disabled={isDisabled}
                >
                  {t('add').toUpperCase()}
                </AkinonButton>
              </Col>
            </div>
          </AkinonSpin>
        </AkinonDividerBox>
      </AkinonBox>
    </>
  );
};

export default CategoryBasedRebateRule;
