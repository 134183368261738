import { postNotificationStateUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePostNotificationStateMutation({ mutationOptions = {} } = {}) {
  const { mutate, isSuccess } = useAkinonMutation({
    mutationFn: ({ requestBody, notificationId, requestConfig }) =>
      HTTP.post(postNotificationStateUrl, requestBody, {
        suppressErrorNotifications: true,
        suppressSuccessNotifications: true,
        urlParams: { notificationId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    postNotificationState: mutate,
    isPostNotificationStateSuccess: isSuccess,
  };
}
