import {
  createMessageUrl,
  getConversationsUrl,
  getConversationUrl,
  getQuickRepliesUrl,
  getQuickReplyUrl,
} from '@constants/apiUrls';
import { HTTP } from '@services/client';

export const getConversation = ({ conversationId }) => {
  return HTTP.get(getConversationUrl, {
    urlParams: { conversationId },
  });
};

export const getConversations = ({ requestConfig }) => {
  return HTTP.get(getConversationsUrl, requestConfig);
};

export const getQuickReplies = ({ requestConfig }) => {
  return HTTP.get(getQuickRepliesUrl, requestConfig);
};

export const getQuickReply = ({ quickReplyId }) => {
  return HTTP.get(getQuickReplyUrl, {
    urlParams: { quickReplyId },
  });
};

export const createQuickReply = ({ requestBody, requestConfig = {} }) => {
  return HTTP.post(getQuickRepliesUrl, requestBody, requestConfig);
};

export const updateQuickReply = ({ quickReplyId, requestBody, requestConfig = {} }) => {
  return HTTP.patch(getQuickReplyUrl, requestBody, {
    urlParams: { quickReplyId },
    ...requestConfig,
  });
};

export const createMessage = ({ requestBody, requestConfig = {} }) => {
  return HTTP.post(createMessageUrl, requestBody, requestConfig);
};
