import { getMostSellingProductsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useMostSellingProductsQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.MOST_SELLING_PRODUCTS, params],
    queryFn: () => HTTP.get(getMostSellingProductsUrl, { params }),
  });

  return {
    mostSellingProducts: data?.most_selling_products,
    isFetchingMostSellingProducts: isFetching,
  };
}
