import './style.scss';

import { addAuthObject } from '@common/auth';
import { createSelectOptions } from '@common/index';
import { getPathFragments } from '@common/routing';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import Box from '@components/utility/box';
import { getUsersUrl } from '@constants/apiUrls';
import { getDatasourcePermissionLevelOptions } from '@constants/commontypes';
import { overallDateFormat } from '@constants/index';
import { formBuilderVerticalLayout } from '@constants/layoutTypes';
import { QueryKey } from '@constants/query';
import { defaultRedirectionUrl, RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useDataSourcesQuery } from '@services/api/hooks';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { useLoginAsMutation } from '@services/api/hooks/useLoginAsMutation';
import { useOptionsQuery } from '@services/api/hooks/useOptionsQuery';
import { queryClient } from '@services/api/queryClient';
import { useSaveUserMutation } from '@services/hooks/user/useSaveUserMutation';
import { useUserDetail } from '@services/hooks/user/useUserDetail';
import { Modal } from 'antd';
import first from 'lodash/first';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { FormKey, getFormSchema, SchemaKey } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const UserManagementForm = () => {
  const { t } = useTranslation('Settings');
  const location = useLocation();
  const navigate = useAppNavigate();
  const { id: userId } = useParams();
  const { data: userDetail } = useUserDetail({
    id: userId,
  });
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery();

  const { options: userOptions } = useOptionsQuery({
    url: getUsersUrl,
    queryOptions: {
      enabled: Boolean(userId),
    },
  });
  const permissionLevelChoises = get(
    userOptions,
    'actions.POST.datasource_permission_level.choices',
    getDatasourcePermissionLevelOptions({ t })
  );

  const { saveUser } = useSaveUserMutation({
    mutationOptions: {
      onSuccess: () => {
        navigate(RouteUrls.settings.userManagement);
      },
    },
  });
  const { dynamicSettings } = useDynamicSettingsQuery({
    params: {
      key: 'USER_CUSTOM_FIELDS',
      is_active: true,
    },
  });

  const dynamicUserSettings = first(dynamicSettings)?.value;
  const schema = getFormSchema({
    t,
    dynamicUserSettings,
    permissionLevelChoises,
  });
  const { control, reset, handleSubmit, getValues, watch } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const isViewingSuperUserDetail = watch(FormKey.IS_SUPERUSER);

  const dynamicFormFields = useMemo(
    () =>
      dynamicUserSettings?.map((key) => (
        <AkinonFormItem key={key} control={control} name={`extra.${key}`} label={key}>
          <AkinonInput placeholder={t('enter_variable', { variable: key })} />
        </AkinonFormItem>
      )),
    [dynamicUserSettings]
  );

  const { postLoginAs } = useLoginAsMutation({
    mutationOptions: {
      onSuccess: (data) => {
        const currentUrl = getPathFragments(location.pathname).mainPath;
        addAuthObject({ ...data, redirectUrl: currentUrl });
        queryClient.invalidateQueries({
          queryKey: [QueryKey.USER_ME],
        });
        navigate(defaultRedirectionUrl);
      },
    },
  });

  useBreadcrumbs();
  usePageMeta();

  useEffect(() => {
    reset({
      ...userDetail,
      [FormKey.USER_LEVEL]: {
        [SchemaKey.IS_SUPERUSER]: userDetail?.[SchemaKey.IS_SUPERUSER],
        [SchemaKey.DATASOURCE]: userDetail?.[SchemaKey.DATASOURCE],
      },
    });
  }, [userDetail]);

  const onSubmit = useCallback(
    (requestBody) => {
      const userLevel = requestBody[FormKey.USER_LEVEL];
      const isSuperUser = get(requestBody, FormKey.IS_SUPERUSER);
      const datasource = get(requestBody, FormKey.DATASOURCE);

      requestBody = {
        ...requestBody,
        ...userLevel,
        [SchemaKey.DATASOURCE]: !isSuperUser ? datasource : null,
      };

      saveUser({
        userId,
        requestBody: omit(requestBody, FormKey.USER_LEVEL),
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('user_detail_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('user_detail_message.error'),
        },
      });
    },
    [userId, saveUser]
  );

  return (
    <section className="user-management-content__detail">
      <Box
        className="box-primary form-box"
        title={t('add_edit_user')}
        subtitle={t('add_edit_user_desc')}
        action={{
          text: t('userManagement.form.loginAsText'),
          className: 'box__title-wrapper-action',
          onClick: () => {
            Modal.confirm({
              title: t('userManagement.form.warningModalTitle'),
              content: t('userManagement.form.warningModalDescription'),
              onOk() {
                postLoginAs({ userId });
              },
              okText: t('modal_ok'),
              cancelText: t('modal_cancel'),
            });
          },
        }}
      >
        <AkinonForm
          preserve={false}
          layout="vertical"
          className="akn-form akn-form-v2"
          wrapperCol={formBuilderVerticalLayout.wrapperCol}
        >
          <AkinonFormItem control={control} label={t('email')} required name={FormKey.EMAIL}>
            <AkinonInput placeholder={t('enter_variable', { variable: t('email') })} />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            label={t('first_name')}
            required
            name={FormKey.FIRST_NAME}
          >
            <AkinonInput placeholder={t('enter_variable', { variable: t('first_name') })} />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            label={t('last_name')}
            required
            name={FormKey.LAST_NAME}
          >
            <AkinonInput placeholder={t('enter_variable', { variable: t('last_name') })} />
          </AkinonFormItem>
          <AkinonFormItem control={control} label={t('status')} name={FormKey.IS_ACTIVE}>
            <AkinonSwitch checkedChildren={t('active')} unCheckedChildren={t('passive')} />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            label={t('marketplace_owner')}
            name={FormKey.IS_SUPERUSER}
          >
            <AkinonSwitch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
          </AkinonFormItem>
          {!isViewingSuperUserDetail && (
            <AkinonFormItem
              control={control}
              label={t('seller.name')}
              name={FormKey.DATASOURCE}
              required
            >
              <AkinonSelect
                placeholder={t('enter_variable', { variable: t('seller.name') })}
                showSearch
                loading={isFetchingDataSources}
                options={createSelectOptions(dataSources, {
                  valueKey: 'id',
                  labelKey: 'name',
                })}
                optionFilterProp="label"
              />
            </AkinonFormItem>
          )}
          <AkinonSelect
            formItemProps={{
              control,
              label: t('datasource_permission_level'),
              name: FormKey.DATASOURCE_PERMISSION_LEVEL,
            }}
            options={createSelectOptions(permissionLevelChoises, {
              valueKey: 'value',
              labelKey: 'display_name',
            })}
          />
          {userId && (
            <AkinonFormItem control={control} label={t('last_login')} name={FormKey.LAST_LOGIN}>
              <AkinonInput
                value={moment(getValues(FormKey.LAST_LOGIN)).format(overallDateFormat)}
                placeholder={t('last_login')}
                disabled
              />
            </AkinonFormItem>
          )}
          {dynamicFormFields}
          <AkinonFormItem className="ant-col-md-24 ant-col-sm-24 ant-col-xs-24">
            <AkinonButton
              onClick={handleSubmit(onSubmit)}
              type="primary"
              className="akn-submit-button"
            >
              {t('save')}
            </AkinonButton>
          </AkinonFormItem>
        </AkinonForm>
      </Box>
    </section>
  );
};

export default UserManagementForm;
