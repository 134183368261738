import { postAgreementConsentUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

/**
 * @param {Object} [args]
 * @param {import('@tanstack/react-query').MutationOptions} [args.mutationOptions]
 */
export function usePostAgreementConsentMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, agreementId, requestConfig }) =>
      HTTP.post(postAgreementConsentUrl, requestBody, {
        urlParams: { agreementId },
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    acceptAgreementConsent: mutate,
    isAgreementConsentAccepting: isLoading,
  };
}
