import '../style.scss';

import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import AkinonFormItem from '@components/AkinonFormItem';
import Box from '@components/utility/box/index';
import { QueryKey } from '@constants/query';
import { useBulkImportProductOffersMutation } from '@services/api/hooks/useBulkImportProductOffersMutation';
import { queryClient } from '@services/api/queryClient';
import { Divider, Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField } from '../common';
import { BulkActionsProgressModal } from './BulkActionsProgressModal';
import { FormKey, getFormResolver } from './common';

const BulkUpdateOfferStatus = ({
  downloadBulkProductOffersTemplate,
  isDownloadBulkProductOffersTemplateFetching,
} = {}) => {
  const { t } = useTranslation('ProductsAndCategories');

  const resolver = getFormResolver({ t });
  const { control, handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {
      file: {
        fileList: [],
      },
    },
  });

  const {
    bulkImportProductOffers,
    resetBulkImportProductOffers,
    isBulkImportingProductOffers,
    isBulkImportProductOffersSuccess,
    isBulkImportProductOffersError,
    bulkImportProductOffersData,
  } = useBulkImportProductOffersMutation({
    mutationOptions: {
      onSuccess: async ({ key }) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.IMPORT_STATUS, key],
        });
      },
    },
  });

  const handleFileUpload = (data) => {
    const formData = new FormData();
    data.file.fileList.forEach((fileItem) => {
      formData.append(FormField.FILE, fileItem.originFileObj);
    });
    bulkImportProductOffers({
      formData,
      requestConfig: {
        successMessage: t('transaction_success'),
        errorMessage: t('transaction_failed'),
        successDescription: t('approved_offers_message.success'),
        errorDescription: t('approved_offers_message.error'),
      },
    });
  };

  return (
    <>
      <div className="bulk-actions mb-8">
        <Box when className="box-primary form-box bulk" title={t('offer_bulk_update_status')}>
          <Form className="ant-form" layout="vertical">
            <div className="download-template">
              <span>{t('download_offers')}</span>
              <AkinonButton
                type="default"
                icon={<i className="icon icon-download" />}
                size="small"
                loading={isDownloadBulkProductOffersTemplateFetching}
                onClick={downloadBulkProductOffersTemplate}
              >
                XLSX
              </AkinonButton>
            </div>
            <Divider />
            <AkinonFormItem
              name={FormKey.FILE}
              label={t('bulk_actions_upload_statuses')}
              control={control}
              required
            >
              <DraggerInputWithLabel
                accept=".csv,.xlsx,.xls"
                singleAction
                multiple={false}
                beforeUpload={() => false}
                label={t('bulk.actions.upload.note')}
                aria-label={t('bulk_actions_upload_statuses')}
              />
            </AkinonFormItem>
            <div className="actions">
              <AkinonButton
                loading={isBulkImportingProductOffers}
                style={{
                  marginTop: '24px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
                type="primary"
                onClick={handleSubmit(handleFileUpload)}
              >
                {t('save')}
              </AkinonButton>
            </div>
          </Form>
        </Box>
      </div>
      <BulkActionsProgressModal
        bulkImportKey={bulkImportProductOffersData?.key}
        open={isBulkImportProductOffersSuccess && !isBulkImportProductOffersError}
        onCancel={() => {
          resetBulkImportProductOffers();
          reset();
        }}
        messages={{
          successCount: ({ succeededCount }) =>
            t('offer_status_import_progress_succeeded_count', { count: succeededCount }),
          failCount: ({ failedCount }) =>
            t('offer_status_import_progress_failed_count', { count: failedCount }),
          finishButtonText: t('go_to_created_items', {
            to: t('to_offer_status'),
          }),
        }}
      />
    </>
  );
};

export default BulkUpdateOfferStatus;
