import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getReconciliationRules } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';
/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions, params: Object }} args
 */
export function usePaginatedReconciliationRulesQuery({
  queryOptions,
  filters,
  pagination,
  params,
  onSuccess,
} = {}) {
  const { data, isFetching, refetch, error } = useAkinonQuery({
    queryKey: [QueryKey.RECONCILIATION_RULES, params, pagination, filters],
    async queryFn() {
      const response = await HTTP.get(getReconciliationRules, {
        params: {
          ...params,
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });
  return {
    reconciliationRulesResponse: data,
    reconciliationRules: data?.results,
    totalReconciliationRules: data?.count,
    isFetchingReconciliationRules: isFetching,
    reconciliationRulesError: error,
    refetchReconciliationRules: refetch,
  };
}
