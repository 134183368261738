import { postResolveRejectionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export function useResolveRejectionMutation({ mutationOptions } = {}) {
  const mutation = useAkinonMutation({
    mutationFn: ({ rejectionReasonId, requestConfig }) =>
      HTTP.post(
        postResolveRejectionUrl,
        {},
        {
          urlParams: { rejectionReasonId },
          ...requestConfig,
        }
      ),
    ...mutationOptions,
  });

  return {
    resolveRejection: mutation.mutate,
    resolveRejectionAsync: mutation.mutateAsync,
    isResolvingRejection: mutation.isLoading,
  };
}
