import { getWidgetSchemaUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useWidgetSchemaQuery({ queryOptions, params, schemaId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.WIDGET_SCHEMA, schemaId, params],
    queryFn: () => HTTP.get(getWidgetSchemaUrl, { params, urlParams: { schemaId } }),
    ...queryOptions,
  });

  return {
    widgetSchema: data,
    isWidgetSchemaLoading: isLoading,
  };
}
