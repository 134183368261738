import { postProductOfferFileUploadURL } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useBulkImportProductOffersMutation({ mutationOptions } = {}) {
  const { isError, isSuccess, isLoading, data, mutate, reset } = useAkinonMutation({
    mutationFn: ({ formData, onProgress, requestConfig }) =>
      HTTP.post(postProductOfferFileUploadURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => onProgress?.(progressEvent),
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    bulkImportProductOffers: mutate,
    resetBulkImportProductOffers: reset,
    isBulkImportingProductOffers: isLoading,
    isBulkImportProductOffersSuccess: isSuccess,
    isBulkImportProductOffersError: isError,
    bulkImportProductOffersData: data,
  };
}
