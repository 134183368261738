import { createSelectOptions } from '@common/index';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { useAttributeSetQuery } from '@services/hooks/attributes/useAttributeSetQuery';
import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueriesContext } from '../../contexts/Queries';
import { BasicProductInfoKey } from './common';

const BasicProductInfoForm = ({ form }) => {
  const { attributeSetId, product } = useQueriesContext();
  const { t } = useTranslation('ProductsAndCategories');
  const { attributeSet, isAttributeSetFetching } = useAttributeSetQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });

  useEffect(() => {
    form.reset({ ...product, [BasicProductInfoKey.ATTRIBUTE_SET]: product?.attribute_set });
  }, [product]);

  const attributeSetOptions = createSelectOptions([attributeSet ?? {}], {
    labelKey: 'name',
    valueKey: 'id',
  });

  return (
    <Box
      className="box-primary form-box"
      title={t('basic_product_info_title')}
      subtitle={t('basic_product_info_subtitle')}
    >
      <AkinonSpin spinning={isAttributeSetFetching}>
        <Form className="akn-form akn-form-v2" layout="vertical">
          <AkinonInput
            formItemProps={{
              control: form.control,
              label: t('product_name'),
              name: BasicProductInfoKey.NAME,
              required: true,
            }}
            disabled
          />
          <AkinonInput
            formItemProps={{
              control: form.control,
              label: t('basecode'),
              name: BasicProductInfoKey.BASE_CODE,
              required: true,
            }}
            disabled
          />
          <AkinonSelect
            formItemProps={{
              control: form.control,
              label: t('attribute_set'),
              name: BasicProductInfoKey.ATTRIBUTE_SET,
              required: true,
            }}
            options={attributeSetOptions}
            disabled
            virtual={false}
            showSearch={false}
            optionFilterProp="label"
            loading={isEmpty(attributeSetOptions)}
          />
        </Form>
      </AkinonSpin>
    </Box>
  );
};

export default BasicProductInfoForm;
