import { getCategoryNodePermissionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export const useCategoryNodePermissionsQuery = ({ queryOptions, onSuccess } = {}) => {
  const { data, isLoading, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.CATEGORY_NODE_PERMISSIONS],
    async queryFn() {
      const response = await HTTP.get(getCategoryNodePermissionsUrl, {
        params: {
          ...limitQuery,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    categoryNodePermissions: data?.results,
    isCategoryNodePermissionsLoading: isLoading,
    isFetchingCategoryNodePermissions: isFetching,
    categoryNodePermissionsError: error,
  };
};
