import { useState } from 'react';

const useModalAction = ({ mutateFn, enforceRowSelection = false, selectedRowKeys }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onShowModal = () => {
    if (enforceRowSelection && !selectedRowKeys?.length) return;
    setIsVisible(true);
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  const onOk = () => {
    setIsVisible(false);
    mutateFn();
  };

  return {
    onShowModal,
    visible: isVisible,
    onOk,
    onCancel,
  };
};

export default useModalAction;
