import { getLocationDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

/**
 * @param {{locationId: string, queryOptions: import('@tanstack/react-query').UseQueryOptions, onSuccess: Function}} args
 */

export function useGetLocationDetailQuery({ locationId, queryOptions, onSuccess } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.LOCATION_DETAIL, locationId],
    async queryFn() {
      const response = await HTTP.get(getLocationDetailUrl, {
        urlParams: {
          locationId,
        },
      });
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    locationDetail: data,
    isFetchingLocationDetail: isFetching,
  };
}
