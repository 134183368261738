import { deleteSellerPerformanceMetricsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export const useDeleteSellerPerformanceMetricMutation = ({ mutationOptions } = {}) => {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestConfig, metricId }) =>
      HTTP.delete(deleteSellerPerformanceMetricsUrl, {
        ...requestConfig,
        urlParams: {
          metricId,
        },
      }),
    ...mutationOptions,
  });
  return {
    deleteSellerPerformanceMetric: mutate,
    deleteSellerPerformanceMetricAsync: mutateAsync,
    isDeletingSellerPerformanceMetric: isLoading,
  };
};
