import { getDistrictsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useDistrictsQuery({ queryOptions, params } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.DISTRICTS, params],
    queryFn: () =>
      HTTP.get(getDistrictsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    districts: data?.results,
    isDistrictsFetching: isFetching,
  };
}
