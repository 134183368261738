import { deleteQuickReplyUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useDeleteQuickReplyMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ id }) =>
      HTTP.delete(deleteQuickReplyUrl, {
        urlParams: { quickReplyId: id },
      }),
    ...mutationOptions,
  });

  return {
    deleteQuickReply: mutate,
    isDeletingQuickReply: isLoading,
  };
}
