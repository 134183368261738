import { getCancelReasonUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';

export function useCancellationReasonQuery({ queryOptions, cancellationReasonId }) {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.CANCELLATION_REASON, cancellationReasonId],
    queryFn: () => HTTP.get(getCancelReasonUrl, { urlParams: { cancellationReasonId } }),
    ...queryOptions,
  });

  return {
    cancellationReason: data,
    isFetchingCancellationReason: isFetching,
  };
}
