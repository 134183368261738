import { TableName } from '@constants/table';
import usePageData from '@zustand-store/hooks/usePageData';

const useSelectedKeys = ({ tableName = TableName.DEFAULT, enabled = true } = {}) => {
  const { pageData, setPageData } = usePageData();
  const hasMultipleTableNames = Array.isArray(tableName);
  const selectedRowKeys = pageData?.table?.[tableName]?.selectedRowKeys ?? [];
  const multiSelectedRowKeys =
    hasMultipleTableNames &&
    tableName.reduce((acc, name) => {
      return {
        ...acc,
        [name]: pageData?.table?.[name]?.selectedRowKeys ?? [],
      };
    }, {});

  const setSelectedRowKeys = (newSelectedRowKeys, _tableName) => {
    setPageData({
      table: {
        [hasMultipleTableNames ? _tableName : tableName]: {
          selectedRowKeys: newSelectedRowKeys,
        },
      },
    });
  };

  const clearSelectedRowKeys = () => {
    if (hasMultipleTableNames) {
      tableName.forEach((name) => {
        setSelectedRowKeys([], name);
      });
    } else {
      setSelectedRowKeys([]);
    }
  };

  const getRowSelection = (extraRowSelectionOptions = {}, _tableName) => {
    if (extraRowSelectionOptions?.selectedRowKeys || extraRowSelectionOptions?.onChange) {
      throw new Error(
        'Do not pass selectedRowKeys or onChange to getRowSelection. Its implemented internally.'
      );
    }

    if (!enabled) {
      return null;
    }

    const rowSelection = {
      selectedRowKeys: hasMultipleTableNames ? multiSelectedRowKeys?.[_tableName] : selectedRowKeys,
      onChange: (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
      },
      ...extraRowSelectionOptions,
    };

    return rowSelection;
  };

  return {
    selectedRowKeys: hasMultipleTableNames ? multiSelectedRowKeys : selectedRowKeys,
    setSelectedRowKeys,
    clearSelectedRowKeys,
    getRowSelection,
  };
};

export default useSelectedKeys;
