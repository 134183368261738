import { getShipmentCompanyOptionsByIdUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const useShippingOptionQuery = ({ shippingMethodId, queryOptions, params, onSuccess }) => {
  const { data, isLoading, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_OPTION, params, shippingMethodId],
    async queryFn() {
      const response = await HTTP.get(getShipmentCompanyOptionsByIdUrl, {
        urlParams: { shippingMethodId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    shippingOption: data,
    isShippingOptionLoading: isLoading,
    shippingOptionError: error,
    refetchShippingOption: refetch,
  };
};
