import { getRejectionsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export const useRejectionsQuery = ({ params, queryOptions }) => {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.REJECTIONS, params],
    queryFn: () => HTTP.get(getRejectionsUrl, { params }),
    ...queryOptions,
  });

  return {
    rejectionsResponse: data,
    rejections: data?.results,
    totalRejections: data?.count,
    isFetchingRejections: isFetching,
    isRejectionsLoading: isLoading,
    rejectionsError: error,
  };
};
