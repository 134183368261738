import { getPathFragments } from '@common/routing';
import useStore from '@zustand-store/index';
import { filtersSelector } from '@zustand-store/selectors/filters';
import get from 'lodash/get';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageFilters = () => {
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filters = useStore(filtersSelector);

  return filters[`${mainPath}${search}`] ?? {};
};

export const useFilterParams = ({ filterFields } = {}) => {
  const result = usePageFilters();

  const allFilterFields = useMemo(() => {
    return filterFields?.static?.fields?.concat?.(filterFields?.dynamic?.fields);
  }, [filterFields]);

  const _filters = useMemo(() => {
    return Object.entries(result).reduce((acc, [key, value]) => {
      const processedValue = typeof value === 'string' ? value.trim() : value;
      acc[key] = get(processedValue, 'value', processedValue);
      return acc;
    }, {});
  }, [result, allFilterFields]);

  return _filters;
};
