import { getGradeUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useGradeQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.GRADE, params],
    queryFn: () => HTTP.get(getGradeUrl, { params }),
  });

  return {
    grade: data?.grade,
    isFetchingGrade: isFetching,
  };
}
