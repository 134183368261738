import { downloadBulkPackagesTemplateUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

// TODO refactor this hook to Query
export function useDownloadBulkPackagesTemplateMutation({ mutateOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: () =>
      HTTP.get(downloadBulkPackagesTemplateUrl, {
        responseType: 'blob',
      }),
    ...mutateOptions,
  });

  return {
    downloadBulkPackagesTemplate: mutate,
    isDownloadBulkPackagesTemplateMutating: isLoading,
  };
}
