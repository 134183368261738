import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getNotificationsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export const usePaginatedNotificationsQuery = ({ queryOptions, filters, pagination } = {}) => {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.NOTIFICATIONS, filters, pagination],
    queryFn: () =>
      HTTP.get(getNotificationsUrl, {
        params: {
          ...getRequestFiltersParams(filters),
          ...getRequestPaginationParams(pagination),
        },
      }),
    ...queryOptions,
  });

  return {
    notifications: data?.results,
    totalNotifications: data?.count,
    isFetchingNotifications: isFetching,
  };
};
