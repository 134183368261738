import { getRequestFiltersParams } from '@common/index';
import { getCancellationReasonsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

import { getRequestPaginationParams } from '../../../common/index';

export const usePaginatedCancellationReasonsQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
} = {}) => {
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.CANCELLATION_REASONS, pagination, filters, params],
    async queryFn() {
      const response = await HTTP.get(getCancellationReasonsUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    cancellationReasonsResponse: data,
    cancellationReasons: data?.results,
    isFetchingCancellationReasons: isFetching,
    totalCancellationReasons: data?.count,
    cancellationReasonsError: error,
  };
};
