import { getExportUrl } from '@constants/apiUrls.js';
import { QueryKey } from '@constants/query.js';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery.js';
import { HTTP } from '@services/client';

export function useExportQuery({ cacheKey }) {
  const { data, error, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.EXPORT, cacheKey],
    queryFn: () => HTTP.get(getExportUrl, { urlParams: { cacheKey } }),
  });

  return {
    exportData: data,
    isExporting: isFetching,
    exportError: error,
  };
}
