import { getUserDetailURL, getUsersUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { HTTP } from '@services/client';

export const useSaveUserMutation = ({ mutationOptions }) => {
  const { mutate, isLoading, error } = useAkinonMutation({
    mutationFn: ({ userId = null, requestBody, requestConfig }) => {
      return userId
        ? HTTP.put(getUserDetailURL, requestBody, {
            urlParams: { userId },
            ...requestConfig,
          })
        : HTTP.post(getUsersUrl, requestBody, requestConfig);
    },
    ...mutationOptions,
  });

  return {
    saveUser: mutate,
    isSavingUser: isLoading,
    saveUserError: error,
  };
};
