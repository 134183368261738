import { postReconciliationRules } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';
/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePostReconciliationRulesMutation({ mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      HTTP.post(postReconciliationRules, requestBody, {
        ...requestConfig,
      }),
    ...mutationOptions,
  });
  return {
    postReconciliationRules: mutate,
    postReconciliationRulesAsync: mutateAsync,
    isPostingReconciliationRules: isLoading,
  };
}
