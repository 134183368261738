import { getFulfillmentTimeUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useFulfillmentTimeQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.FULFILLMENT_TIME, params],
    queryFn: () => HTTP.get(getFulfillmentTimeUrl, { params }),
  });

  return {
    fulfillmentTime: data?.fulfillment_time,
    isFetchingFulfillmentTime: isFetching,
  };
}
