import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getImportsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function usePaginatedImportsQuery({ queryOptions, filters, pagination, params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.IMPORTS, filters, pagination, params],
    queryFn() {
      return HTTP.get(getImportsUrl, {
        params: {
          ...getRequestFiltersParams(filters),
          ...getRequestPaginationParams(pagination),
          ...params,
        },
      });
    },
    ...queryOptions,
  });

  return {
    imports: data?.results,
    totalImports: data?.count,
    isFetchingImports: isFetching,
  };
}
