import { getAttributeConfigsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQuery } from '@tanstack/react-query';

import { HTTP } from '../../client';

export function useAttributeConfigsQuery({ requestConfig, queryOptions, onSuccess } = {}) {
  const { data } = useQuery({
    queryKey: [QueryKey.ATTRIBUTE_CONFIGS, requestConfig],
    async queryFn() {
      const response = await HTTP.get(getAttributeConfigsUrl, requestConfig);
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    attributeConfigs: data?.results,
  };
}
