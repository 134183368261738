import { getContentTypesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useContentTypesQuery({ params, queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.CONTENT_TYPES, params],
    queryFn: () =>
      HTTP.get(getContentTypesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    contentTypes: data?.results,
    isFetchingContentTypes: isFetching,
  };
}
