import './index.scss';

import { ExclamationCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import DynamicFormElement from '@components/DynamicFormElement';
import { QuillEditor } from '@components/QuillEditor';
import TitleWithHint from '@components/TitleWithHint';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { zodResolver } from '@hookform/resolvers/zod';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Carousel, Col, Image, Modal, Space, Tooltip } from 'antd';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePatchProductOffersMutation } from '../hooks/api/usePatchProductOffersMutation';
import { usePatchProductPriceMutation } from '../hooks/api/usePatchProductPriceMutation';
import { usePatchProductStockMutation } from '../hooks/api/usePatchProductStockMutation';
import {
  BasicProductInfoFormItemKey,
  DataType,
  DataTypeWidgetMap,
  getSchema,
  ProductToBeAddedFormKey,
} from './common';
import { useApprovedOfferDetail } from './hooks/useApprovedOfferDetail';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const OfferDetailApproved = () => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const [isProductImagesModalVisible, setIsProductImagesModalVisible] = useState(false);

  const { isPatchProductPriceLoading, patchProductPriceAsync } = usePatchProductPriceMutation({});
  const { isPatchProductStockLoading, patchProductStockAsync } = usePatchProductStockMutation({});
  const { isPatchProductOffersMutating, patchProductOffersAsync } = usePatchProductOffersMutation(
    {}
  );

  const {
    productPrice,
    productStock,
    dataSource,
    productImages,
    dynamicFields,
    productLowestPriceError,
    isProductLowestPriceLoading,
    masterProductPricesError,
    isMasterProductPricesLoading,
  } = useApprovedOfferDetail({ t });

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData();

  const filteredDynamicFields = dynamicFields.filter(({ key }) => key !== 'SELLER_DESCRIPTION');
  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(getSchema({ t, dynamicFields })),
  });
  const { control, reset, handleSubmit } = form;

  const formValues = useWatch({ control, name: 'productToBeAdded' });
  const onSubmit = useOnSubmit({
    formValues,
    handleSubmit,
    patchProductPriceAsync,
    patchProductStockAsync,
    patchProductOffersAsync,
    productPrice,
    productStock,
    t,
  });

  const onImageModalCancel = () => {
    setIsProductImagesModalVisible(false);
  };

  const initialValues = useMemo(
    () => ({
      basicProductInfo: pick(dataSource?.product, ['name', 'base_code', 'category']),
      productToBeAdded: {
        ...pick(dataSource, [
          'sku',
          'stock',
          'price',
          'retail_price',
          'tax_rate',
          'currency_type',
          'buybox_price',
          'lowest_price',
        ]),
        attributes: dataSource?.attributes,
      },
    }),
    [dataSource]
  );

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  useBreadcrumbs();
  usePageMeta();

  const getFormItemErrorMessage = ({ error }) => {
    if (!error) return {};
    return {
      status: 'error',
      prefix: (
        <Tooltip title={t('this_field_could_not_be_loaded')} placement="topLeft">
          <ExclamationCircleOutlined />
        </Tooltip>
      ),
    };
  };

  return (
    <section className="layoutContentWrapper-v2 single-product-form">
      <Box
        className="box-primary form-box"
        title={t('basic_product_info_title')}
        subtitle={
          isSuperUser ? t('basic_product_info_subtitle_mpo') : t('basic_product_info_subtitle')
        }
      >
        <AkinonForm layout="vertical">
          <AkinonFormItem
            label={t('product_name')}
            name={BasicProductInfoFormItemKey.NAME}
            control={control}
            disabled
            required
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('basecode')}
            name={BasicProductInfoFormItemKey.BASE_CODE}
            control={control}
            disabled
            required
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('category')}
            name={BasicProductInfoFormItemKey.CATEGORY}
            control={control}
            disabled
            required
          >
            <AkinonTreeSelect
              loading={isCategoriesLoading}
              treeData={categoryTreeData}
              treeNodeFilterProp={'title'}
              allowClear
              showSearch
              treeDefaultExpandAll
              virtual={false}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            />
          </AkinonFormItem>
        </AkinonForm>
      </Box>
      <Box
        className="box-primary form-box"
        title={t('product_summary')}
        subtitle={
          isSuperUser
            ? t('products_to_be_added_form_description_mpo')
            : t('products_to_be_added_form_description')
        }
      >
        <AkinonForm layout="vertical" isFlex>
          {filteredDynamicFields?.map(({ key, label, is_offer, data_type, ...rest }) => {
            const props =
              data_type === DataType.DROPDOWN ? { options: get(rest, 'options', []) } : {};

            return (
              <AkinonFormItem
                key={key}
                name={`productToBeAdded.attributes.${key}`}
                control={control}
                required={rest.required}
                disabled={!is_offer}
                label={label || key}
                colSpan={11}
                isWithColumn
              >
                <DynamicFormElement widget={DataTypeWidgetMap[data_type]} {...props} />
              </AkinonFormItem>
            );
          })}
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.SKU}
            label={t('sku')}
            control={control}
            required
            disabled
            isWithColumn
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.STOCK}
            control={control}
            required
            label={t('stock')}
            isWithColumn
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.PRICE}
            control={control}
            required
            label={t('selling.price')}
            isWithColumn
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.RETAIL_PRICE}
            control={control}
            required
            label={t('retail.price')}
            isWithColumn
          >
            <AkinonInput />
          </AkinonFormItem>
          <Col span={11}>
            <AkinonSpin spinning={isMasterProductPricesLoading}>
              <AkinonFormItem
                name={ProductToBeAddedFormKey.REFERENCE_PRICE}
                control={control}
                disabled
                label={
                  <TitleWithHint
                    style={{ color: 'white' }}
                    text={t('reference_price')}
                    hint={t('reference_price_desc')}
                  />
                }
                isWithColumn
              >
                <AkinonInput {...getFormItemErrorMessage({ error: masterProductPricesError })} />
              </AkinonFormItem>
            </AkinonSpin>
          </Col>
          <Col span={11}>
            <AkinonSpin spinning={isProductLowestPriceLoading}>
              <AkinonFormItem
                name={ProductToBeAddedFormKey.LOWEST_PRICE}
                control={control}
                disabled
                label={
                  <TitleWithHint
                    style={{ color: 'white' }}
                    text={t('lowest_price')}
                    hint={t('lowest_price_desc')}
                  />
                }
                isWithColumn
              >
                <AkinonInput {...getFormItemErrorMessage({ error: productLowestPriceError })} />
              </AkinonFormItem>
            </AkinonSpin>
          </Col>
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.TAX_RATE}
            control={control}
            required
            label={t('tax_rate')}
            isWithColumn
          >
            <AkinonInput />
          </AkinonFormItem>
          <AkinonFormItem
            colSpan={11}
            name={ProductToBeAddedFormKey.CURRENCY_TYPE}
            control={control}
            required
            disabled
            label={t('currency')}
            isWithColumn
          >
            <AkinonSelect loading={isProductPricesOptionsLoading} options={currencyOptions} />
          </AkinonFormItem>
        </AkinonForm>
      </Box>

      <Box
        className="box-primary form-box"
        title={t('product_images')}
        subtitle={isSuperUser ? t('product_images_subtitle_mpo') : null}
      >
        <Space direction="horizontal" size="middle">
          {dataSource?.product_image && (
            <Col className="file-item">
              <Image src={dataSource?.product_image} width={100} height={100} />
            </Col>
          )}
          {productImages?.length - 1 > 0 && (
            <Col
              className="file-item remaining-images"
              onClick={() => setIsProductImagesModalVisible(true)}
            >
              {`+ ${productImages?.length - 1}`}
            </Col>
          )}
        </Space>
      </Box>
      <Box
        className="box-primary form-box description-form"
        title="Description"
        subtitle={<div>{t('product.description.form.description')}</div>}
      >
        <AkinonFormItem
          control={control}
          label={t('product.description')}
          name={'productToBeAdded.attributes.SELLER_DESCRIPTION'}
          wrapperCol={verticalFormItemLayout.wrapperCol}
        >
          <QuillEditor
            readOnly={!dynamicFields?.find(({ key }) => key === 'SELLER_DESCRIPTION')?.is_offer}
            value={dataSource?.attributes?.SELLER_DESCRIPTION}
          />
        </AkinonFormItem>
      </Box>
      <Box className="box-primary form-box">
        <AkinonButton
          className="submit-form__approve-button"
          loading={
            isPatchProductPriceLoading || isPatchProductStockLoading || isPatchProductOffersMutating
          }
          htmlType="button"
          onClick={onSubmit}
          type="primary"
        >
          {t('save').toLocaleUpperCase(i18n.language)}
        </AkinonButton>
      </Box>

      <Modal
        className="products-to-be-added-table-form__product-images-modal"
        footer={null}
        width={800}
        onCancel={onImageModalCancel}
        open={isProductImagesModalVisible}
        title={t('product.images.title')}
      >
        <Carousel
          arrows
          prevArrow={<LeftOutlined color="white" />}
          nextArrow={<RightOutlined color="white" />}
          slidesToShow={4}
          dots={null}
          infinite={(productImages?.length || 0 + productImages?.length || 0) > 4}
        >
          {productImages?.map((productImage) => (
            <Space
              style={{ display: 'flex' }}
              align="center"
              className="products-to-be-added-table-form__product-image-container"
              direction="vertical"
              key={productImage.id}
            >
              <Image
                className="products-to-be-added-table-form__product-image"
                src={productImage.image}
              />
            </Space>
          ))}
        </Carousel>
      </Modal>
    </section>
  );
};

export default OfferDetailApproved;
