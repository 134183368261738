import { resetPasswordWithTokenUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useResetPasswordQuery({ queryOptions, requestConfig, resetToken } = {}) {
  const { isSuccess, error, isFetched } = useAkinonQuery({
    queryKey: [QueryKey.RESET_PASSWORD, resetToken, requestConfig],
    queryFn: () =>
      HTTP.get(resetPasswordWithTokenUrl, {
        ...requestConfig,
        urlParams: { resetToken },
        suppressErrorNotifications: true,
        suppressSuccessNotifications: true,
      }),
    ...queryOptions,
  });

  return {
    isPasswordResetTokenValid: isFetched ? isSuccess : true,
    passwordResetTokenError: error,
  };
}
