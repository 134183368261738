import { postRestrictedProductsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

export function usePostRestrictProductsMutation(mutationOptions = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: (params) => HTTP.post(postRestrictedProductsUrl, params),
    onSuccess() {
      mutationOptions.onSuccess?.();
    },
    ...mutationOptions,
  });

  return {
    restrictProduct: mutateAsync,
    isRestrictingProduct: isLoading,
  };
}
