import { getCollectionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCollectionsQuery({ queryOptions, params } = {}) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.COLLECTIONS, params],
    queryFn: () =>
      HTTP.get(getCollectionsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    collections: data?.results,
    isCollectionsLoading: isLoading,
    isCollectionsFetching: isFetching,
  };
}
