import { getBasketOfferParticipantsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

export function useBasketOfferParticipantsQuery({ id, queryOptions, onSuccess } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.BASKET_OFFER_PARTICIPANTS, id],
    async queryFn() {
      const response = await HTTP.get(getBasketOfferParticipantsUrl, { urlParams: { id } });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    basketOfferParticipants: data?.results,
    isBasketOfferParticipantsLoading: isLoading,
  };
}
