import { safeParseJSON } from '@utils/index';
import { isEqual, isObject } from 'lodash';

export const sync = (stateCreator, options) => (set, get, api) => {
  const { enabled, name, partialize = (state) => state } = options;
  // eslint-disable-next-line no-undef
  const shouldSync = enabled && globalThis?.localStorage;
  if (shouldSync) {
    window.addEventListener('storage', (e) => {
      const isStoreUpdate = e.key === name;
      if (!isStoreUpdate) return;

      const currentState = get();
      const partializedCurrentState = partialize(currentState);
      const newState = partialize(safeParseJSON(e.newValue)?.state);
      if (!isObject(newState)) return;

      const hasShallowUpdate = Object.keys(newState).some((key) => {
        return !isEqual(newState[key] !== partializedCurrentState[key]);
      });

      if (!hasShallowUpdate) return;
      set({ ...currentState, ...newState });
    });
  }

  return stateCreator(set, get, api);
};
