import { approveCancellationPlansUrl } from '@constants/apiUrls';

import { HTTP } from '../../client';
import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function useApproveCancellationPlansMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ cancellationPlanId, requestBody }) =>
      HTTP.post(approveCancellationPlansUrl, requestBody, {
        urlParams: {
          cancellationPlanId,
        },
      }),
    ...mutationOptions,
  });

  return {
    approveCancellationPlans: mutate,
    isApprovingCancellationPlans: isLoading,
  };
}
