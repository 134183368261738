import { openNotification } from '@components/AkinonNotification';

import { FormKey } from '../common';

export function useOnSubmit({ createTicketMessage, reset, ticketId, t }) {
  return (formValues) => {
    const formData = new FormData();
    formData.append(FormKey.CONTENT, formValues[FormKey.CONTENT]);
    formValues[FormKey.ATTACHMENT]?.fileList.forEach((file) => {
      formData.append(FormKey.ATTACHMENT, file.originFileObj);
    });
    createTicketMessage(
      {
        requestBody: formData,
        requestConfig: {
          suppressErrorNotifications: true,
          suppressSuccessNotifications: true,
          urlParams: { ticketId },
        },
      },
      {
        onSuccess() {
          openNotification({
            message: t('transactions.title.success'),
            description: t('transactions.body.reply_success'),
          });
          reset();
        },
        onError() {
          openNotification({
            message: t('transactions.title.unsuccess'),
            description: t('transactions.body.reply_unsuccess'),
            type: 'error',
          });
        },
      }
    );
  };
}
