import * as z from 'zod';

export const ReconciliationRebateType = {
  FIXED: 0,
  DYNAMIC: 1, // percentage
};

export const getCategoryBasedRebateFormSchema = ({ t }) => {
  return z.object({
    formData: z.array(
      z.object({
        ruleId: z.string().nullable().optional(),
        category_node: z.string().min(1).optional(),
        value: z.coerce
          .number({ invalid_type_error: t('number.validation.message') })
          .min(0, { message: t('formRule.min', { amount: 0 }) })
          .max(100, { message: t('formRule.max', { amount: 100 }) }),
        rebate_type: z.number().min(0).max(1),
      })
    ),
  });
};
