import { getRequestPaginationParams } from '@common/index';
import { getOrdersUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { HTTP } from '@services/client';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

export function useOrders({ pagination, filters }) {
  // TODO: find a better way to handle date filters and move it to AkinonFilter component
  Object.keys(filters).forEach((key) => {
    if (moment.isMoment(filters[key])) {
      filters[key] = filters[key].format('YYYY-MM-DD');
    }
  });

  const queryResponse = useQuery({
    queryKey: [QueryKey.ORDERS, pagination, filters],
    async queryFn() {
      return HTTP.get(getOrdersUrl, {
        params: {
          is_send: Number(true),
          sort: '-date_placed',
          ...getRequestPaginationParams(pagination),
          ...filters,
        },
      });
    },
  });

  return {
    ...queryResponse,
    total: queryResponse.data?.count,
    orders: queryResponse.data?.results,
  };
}
