import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

const RequestedAttributes = ({ attributes, requestedAttributes, record }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const requestedName = record.name;
  const currentName = record?.product_detail?.name;
  const { revisionImages, images, hasImageChange } = record;
  const shouldShowImages = revisionImages?.length > 0 || images?.length > 0;

  return (
    <div className="new-offer-attributes">
      {requestedName !== currentName && (
        <div className="offer-attribute">
          <div className="offer-attribute-name">{t('product_name')}:</div>{' '}
          <div className="offer-attribute-value">{requestedName ?? '-'}</div>
        </div>
      )}
      {shouldShowImages && hasImageChange && (
        <div className="revision-images-container">
          <div className="offer-attribute-name">
            {t('product_images')}
            {!revisionImages && ': -'}
          </div>
          {revisionImages && (
            <div className="revision-images-container__all-images">
              <Image.PreviewGroup>
                {revisionImages.map((item) => (
                  <Image width="calc(25% - 0.8rem)" key={item.id} src={item.image} />
                ))}
              </Image.PreviewGroup>
            </div>
          )}
        </div>
      )}
      {Object.entries(requestedAttributes ?? {}).map(([key, value]) => {
        const attributeName = attributes.find((attribute) => attribute.key === key)?.name ?? key;

        return (
          <div className="offer-attribute" key={key}>
            <div className="offer-attribute-name">{attributeName}:</div>{' '}
            <div className="offer-attribute-value">{value ?? '-'}</div>
          </div>
        );
      })}
    </div>
  );
};

export default RequestedAttributes;
