import { getCancelRatioUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { HTTP } from '../../client';
import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCancelRatioQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.CANCEL_RATIO, params],
    queryFn: () => HTTP.get(getCancelRatioUrl, { params }),
  });

  return {
    cancelRatio: data?.cancel_ratio,
    isFetchingCancelRatio: isFetching,
  };
}
