import { postPreOffersUrl } from '@constants/apiUrls';
import { HTTP } from '@services/client';

import { useAkinonMutation } from '../adapters/useAkinonMutation';

export function usePostPreOfferMutation() {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: async ({ requestBody }) => {
      return HTTP.post(postPreOffersUrl, requestBody);
    },
  });

  return {
    postPreOffer: mutateAsync,
    isPostingPreOffer: isLoading,
  };
}
