import { getShippingOptionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { HTTP } from '@services/client';

/**
 * @param {Object} [args]
 * @param {Object} [args.params]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function useShippingOptionsQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isLoading, error, refetch, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_OPTIONS, params],
    async queryFn() {
      const response = await HTTP.get(getShippingOptionsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    shippingOptions: data?.results,
    isShippingOptionsLoading: isLoading,
    isFetchingShippingOptions: isFetching,
    shippingOptionsError: error,
    refetchShippingOptions: refetch,
  };
}
