import {
  downloadBulkProductPricesTemplateUrl,
  exportProductPricesUrl,
  getProductPricesUrl,
  patchProductPriceUrl,
} from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { HTTP } from '@services/client';

export const getProductPrices = (params) => {
  return HTTP.get(getProductPricesUrl, { params });
};

export const optionsProductPrices = (params) => {
  return HTTP.options(getProductPricesUrl, { params });
};

export const getBulkTemplatePrices = () => {
  return HTTP.get(downloadBulkProductPricesTemplateUrl, {
    responseType: 'blob',
  });
};

export const getExportProductPrices = async ({ requestConfig, fileFilter }) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await HTTP.post(exportProductPricesUrl, formData, {
      ...requestConfig,
      headers: {
        'Origin-Method': HttpMethod.GET,
        ...requestConfig?.headers,
      },
    });
  } else {
    return await HTTP.get(exportProductPricesUrl, requestConfig);
  }
};

/**
 * PATCH "/product-prices/{priceId}"
 * @param {*} { priceId, requestConfig }
 * @returns
 */

export const patchProductPrice = ({ priceId, requestBody, requestConfig }) => {
  return HTTP.patch(patchProductPriceUrl, requestBody, {
    urlParams: {
      priceId,
    },
    ...requestConfig,
  });
};
