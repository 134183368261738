import { patchProductCollectionWidgetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { HTTP } from '../../client';

export function usePatchProductCollectionWidgetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, widgetId }) =>
      HTTP.patch(patchProductCollectionWidgetUrl, requestBody, {
        urlParams: { widgetId },
      }),
    ...mutationOptions,
  });

  return {
    patchProductCollectionWidget: mutate,
    isPatchingProductCollectionWidget: isLoading,
  };
}
